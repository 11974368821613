import React, { Fragment } from "react";
import { Text, StyleSheet, View } from "@react-pdf/renderer";

let decimal = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const TableRows = ({ items, country, discount }) => {
  // Render rows
  const rows = items.map((item, i) => (
    <View style={styles.row} key={item.id + i} wrap={false}>
      <View style={styles.td}>
        <Text style={[styles.cell]}>{item.building}</Text>
      </View>
      <View style={styles.td}>
        <Text style={[styles.cell]}>{item.location_on_site}</Text>
      </View>
      <View style={styles.td}>
        <Text style={[styles.cell]}>{item.name}</Text>
      </View>

      <View style={styles.td}>
        <Text style={[styles.cell]}>{item.size}</Text>
      </View>

      <View style={styles.td}>
        <Text style={[styles.cell]}>
          {country === "australia"
            ? item.lps?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : item.cfm?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </View>
      {/* <View style={styles.td}>
        <Text style={[styles.cell]}>
          {item?.calculated_price
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </Text>
      </View> */}
      <View style={styles.td}>
        <Text style={[styles.cell]}>
          {decimal
            .format(
              Number(item?.calculated_price) -
                Number(item?.calculated_price) * discount
            )
            .replace("$", "")}
        </Text>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

const styles = StyleSheet.create({
  row: {
    fontFamily: "Helvetica Neue",
    flexDirection: "row",
    fontSize: 10,
    textAlign: "center",
    borderWidth: 0.5,
    borderColor: "#c2c2c2",
    // borderBottomColor:''
  },
  cell: {
    padding: 6,
    margin: "auto",
    lineHeight: 1,
    width: "100%",
  },
  td: {
    borderColor: "#c2c2c2",
    width: "16.65%",
    borderWidth: 0.5,
  },
});

export default TableRows;
