import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import {
  Card,
  CardFilter,
  CardEmpty,
  DataTableColumn,
  PageHeader,
  DataTable,
  PageHeaderControl,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  ButtonStateful,
  Button,
  ButtonGroup,
  Dropdown,
  BrandBand,
  Input,
  InputIcon,
} from "@salesforce/design-system-react";

export function ContactUpdate(props) {
  const UPDATE_CONTACT = gql`
    mutation UpdateContact($id: ID!, $input: ContactUpdate!) {
      updateContact(where: { id: $id }, data: $input) {
        contact_name
        phone_number
        email
        company_title
      }
    }
  `;

  const [updateContact, { contactData }] = useMutation(UPDATE_CONTACT);

  const { contact } = props;

  const { register, handleSubmit, reset, watch, control, setValue } = useForm();

  const onSubmit = (data) => {
    updateContact({
      variables: { id: parseInt(contact.id), input: data },
    }).then(() => props.refetch());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={
            <Input
              type="text"
              placeholder="name"
              label="Name"
              name="contact_name"
            />
          }
          control={control}
          name="contact_name"
          defaultValue={contact.contact_name}
        />

        <Controller
          as={
            <Input
              type="text"
              placeholder="phone number"
              label="Phone No."
              name="phone_number"
            />
          }
          control={control}
          name="phone_number"
          defaultValue={contact.phone_number}
        />
        <Controller
          as={
            <Input
              type="text"
              placeholder="email"
              label="Email address"
              name="email"
            />
          }
          control={control}
          name="email"
          defaultValue={contact.email}
        />
        <Controller
          as={
            <Input
              type="text"
              placeholder="name"
              label="Title"
              name="company_title"
            />
          }
          control={control}
          name="company_title"
          defaultValue={contact.company_title}
        />
        {/* <Input
                    iconRight={
                      <InputIcon
                        assistiveText={{
                          icon: "Edit",
                        }}
                        name="Edit"
                        category="utility"
                      />
                    }
                    type="text"
                    defaultValue={data.contact.contact_name}
                    placeholder="Contact name"
                    name="contact_name"
                    label="Contact name"
                    ref={register}
                  /> */}
        <Button type="submit">Save</Button>
      </form>
    </>
  );
}
