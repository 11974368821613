import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { Input } from "@salesforce/design-system-react";
import { CompanyCombobox } from "../../features/CompanyCombobox";

const ADD_CONTACT = gql`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      contact_name
      phone_number
      email
      company_title
    }
  }
`;

export function AddContactForm(props) {

  const { handleModal } = React.useContext(ModalContext);
  const [addContact] = useMutation(ADD_CONTACT);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(props.id || '');

  useEffect(() => {
    if (props && props.companies) {
      setCompanies(props.companies);
    }
  }, [props]);

  const { register, handleSubmit, control, errors } = useForm();

  const select = (item) => setCompany(item);
  const onSubmit = (data) => {
    console.log(data)
    data.company_id = parseInt(company.id);

    addContact({ variables: { input: data } }).then(() => props.refetch());
    handleModal();
  };


  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <label htmlFor="firstName">
            Full name *
            <Input name="contact_name" type="text" ref={register} required={true} />
          </label>
        }
        control={control}
        name="contact_name"
        defaultValue={""}
        rules={{required: true}}
      />
      {errors?.contact_name?.type  === "required" && <p style={{fontSize: '11px', color: 'red'}}>Your input is required</p>}
      <br />
      <section className="slds-grid slds-gutters">
        <div  className="slds-col slds-size_1-of-2">
        <Controller
          as={
            <label htmlFor="emailAddress">
              Email Address *
              <Input name="email" type="email" ref={register} required={true} />
            </label>
          }
         
          control={control}
          name="email"
          defaultValue={""}
          rules={{required: true}}
        />
  
        {errors?.email?.type  === "required" && <p style={{fontSize: '11px', color: 'red'}}>Your input is required</p>}

        </div>
        
        <div  className="slds-col slds-size_1-of-2">
        <Controller
          as={
            <label htmlFor="phone_number">
              Phone Number
              <Input name="phone_number" type="tel" ref={register} required={true}  />
            </label>
          }
          
          control={control}
          name="phone_number"
          defaultValue={""}
        />
        </div>
        
      </section>
      <br />
      {companies.length > 0 &&
        <label htmlFor="combobox">
          Company *
          <CompanyCombobox options={companies} select={select} />
        </label>
      }
      <br />
      <Controller
        as={
          <label htmlFor="companyTitle">
            Title
            <Input name="company_title" type="text" ref={register} />
          </label>
        }
        control={control}
        name="company_title"
        defaultValue={""}
      />
      <br />
      <Input type="submit" value="Save" />
    </form>
  );
}
