import React from "react";
import "../index.css";
import "../App.css";

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { ModalProvider } from "./ModalContext";

import store from "../redux/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import { setContext } from "@apollo/client/link/context";
import { IconSettings } from "@salesforce/design-system-react";
import { Header } from "./Header";
import { NavBar } from "./Navbar";

// View imports
import LocationDetail from "../components/locations/LocationDetail";
import { CompanyDetail } from "../components/companies/CompanyDetail";
import { CompaniesList } from "../components/companies/CompaniesList";
import { ContactsList } from "../components/contacts/ContactsList";
import { ContactDetail } from "../components/contacts/ContactDetail";
import { ProjectsList } from "../components/projects/ProjectsList";
import { ProjectDetail } from "../components/projects/ProjectDetail";
import { OpportunitiesList } from "../components/opportunities/OpportunitiesList";
import { OpportunityDetail } from "../components/opportunities/OpportunityDetail";
import { UsersList } from "../components/users/UsersList";
import { ProposalDetail } from "../components/proposals/ProposalDetail";
import EquipmentDetail from "./equipment/EquipmentDetail";
import useCookie from "../hooks/useCookie";
import { UserSettingsContext, userSettingsInitState } from "../contexts";
import ProjectReport from "./report/ProjectReport";
import Login from "./auth/Login";
import Photos from "./projects/Photos";
import Feedback from "./Feedback";
import Notes from "./projects/Notes";

// const wsLink = new WebSocketLink({
//   // uri: `wss://bba-server.herokuapp.com/graphql`,
//   uri: `ws://127.0.0.1:4000/graphql`,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authorization: "ADMIN",
//     },
//   },
// });

const httpLink = createHttpLink({
  uri: "https://api.blueboxair.com/graphql",
  // uri: "http://127.0.0.1:4000/graphql",
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: false,
  },
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
});

const token = localStorage.getItem("accessToken");
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const logoutLink = onError(({ networkError, graphQLErrors }) => {
  console.log({ networkError, graphQLErrors });

  if (graphQLErrors) {
    const gErrors = graphQLErrors.filter(
      (err) => err.extensions.code === "UNAUTHENTICATED"
    );
    if (
      gErrors.length > 0 &&
      (!window.location.href.endsWith("/login") ||
        !window.location.href.endsWith("/photos"))
    ) {
      window.location.href = "/login";
    }

    console.log(graphQLErrors.map((err) => err.message).join(", "));
  }
  // if (networkError.statusCode === 401) {
  //   alert(401);
  // }
});

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);

//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   logoutLink.concat(authLink.concat(httpLink))
// );

const client = new ApolloClient({
  link: from([authLink, logoutLink, retryLink, httpLink]),
  cache: new InMemoryCache(),
  name: "web",
  version: "1.0.0",
});

// console.log(window.caches);
function Root() {
  const [cookie, updateCookie] = useCookie(
    "user_settings",
    userSettingsInitState
  );

  return (
    <Router>
      <IconSettings iconPath="/icons">
        <UserSettingsContext.Provider value={{ ...cookie, updateCookie }}>
          <Switch>
            <Route exact path="/google/feedback">
              <Feedback />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route
              exact
              path="/projects/:projectId/photos"
              component={Photos}
            />
            <Route exact path="/projects/:projectId/notes" component={Notes} />

            <ApolloProvider client={client}>
              <Provider store={store}>
                <ModalProvider>
                  <IconSettings iconPath="/icons">
                    <div className="App">
                      <Header />

                      <NavBar />

                      {/* <Switch> */}
                      <Route exact path="/" component={OpportunitiesList} />

                      <Route
                        exact
                        path="/companies"
                        component={CompaniesList}
                      />

                      <Route
                        exact
                        path="/companies/:companyId"
                        component={CompanyDetail}
                      />

                      <Route
                        exact
                        path="/companies/:companyId/locations/:locationId"
                        component={LocationDetail}
                      />

                      <Route exact path="/contacts" component={ContactsList} />

                      <Route
                        exact
                        path="/contacts/:contactId"
                        component={ContactDetail}
                      />

                      <Route
                        exact
                        path="/opportunities"
                        component={OpportunitiesList}
                      />

                      <Route
                        exact
                        path="/opportunities/:opportunityId"
                        component={OpportunityDetail}
                      />

                      <Route
                        exact
                        path="/companies/:companyId/opportunities/:opportunityId"
                        component={OpportunityDetail}
                      />

                      <Route exact path="/projects" component={ProjectsList} />

                      <Route
                        exact
                        path="/projects/:projectId"
                        component={ProjectDetail}
                      />

                      <Route
                        exact
                        path="/opportunities/:opportunityId/proposal/:proposalId"
                        component={ProposalDetail}
                      />

                      <Route
                        exact
                        path="/projects/:projectId/proposal/:proposalId"
                        component={ProposalDetail}
                      />

                      <Route
                        exact
                        path="/equipment/:equipmentId"
                        component={EquipmentDetail}
                      />

                      <Route exact path="/users" component={UsersList} />

                      <Route exact path="/users/:id" component={UsersList} />

                      <Route
                        exact
                        path="/projects/:projectId/report"
                        component={ProjectReport}
                      />
                      {/* </Switch> */}
                    </div>
                  </IconSettings>
                </ModalProvider>
              </Provider>
            </ApolloProvider>
          </Switch>
        </UserSettingsContext.Provider>
      </IconSettings>
    </Router>
  );
}
export default Root;
