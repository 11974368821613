import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTable, usePagination } from "react-table";

import { useQuery, gql, useMutation } from "@apollo/client";

import {
  Checkbox,
  BrandBand,
  PageHeader,
  Icon,
  PageHeaderControl,
  Input as UIInput,
  ButtonGroup,
  Button,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import OPPORTUNITIES from "../../graphql/queries/projects";
import Moment from "react-moment";

const stages = [
  "Ready to be Scheduled",
  "Scheduled",
  "In Progress",
  "Blocked",
  "Ready to Invoice",
];

const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity($where: ProjectWhere!, $data: ProjectUpdate!) {
    updateProject(where: $where, data: $data) {
      id
    }
  }
`;

const sortable = [
  "id",
  "project_name",
  "created_at",
  "start_date",
  "kickoff_call",
  "estimated_duration",
  "total_amount",
];

export function ProjectsList() {
  const { loading, error, data, fetchMore } = useQuery(OPPORTUNITIES, {
    variables: {
      skip: 0,
      limit: 0,
    },
    orderBy: { by: "created_at", type: "desc" },
  });
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);

  const dataColumns = React.useMemo(
    () => [
      {
        Header: "Date created",
        id: "created_at",
        accessor: (rowData, i) => (
          <Moment format="DD-MMM-YYYY">{rowData.created_at}</Moment>
        ),
      },
      {
        Header: "Project name",
        accessor: (rowData, i) => (
          <Link to={`/projects/${rowData?.id}`}>{rowData?.project_name}</Link>
        ),
        id: "project_name",
      },
      {
        Header: "Address",
        id: "address",
        accessor: (rowData, i) => rowData?.location?.address,
      },
      {
        Header: "Company",
        id: "company_name",
        accessor: (rowData, i) => rowData?.location?.company?.company_name,
      },
      {
        Header: "Location",
        id: "location",
        accessor: (rowData, i) => rowData?.location?.location_name,
      },
      {
        Header: "Equipment",
        id: "equipments",
        accessor: (rowData, i) => rowData.equipments.length,
      },
      {
        Header: "Region",
        id: "region",
        accessor: (rowData, i) => rowData?.location?.state,
      },
      {
        Header: "Estimated duration",
        id: "estimated_duration",
        accessor: (rowData, i) => rowData?.estimated_duration,
      },
      {
        Header: "Currency",
        id: "currency",
        accessor: "currency",
      },
      {
        Header: "Total amount",
        id: "total_amount",
        accessor: (row, i) => row?.total_amount,
      },
      // {
      //   Header: "Vizient Member",
      //   id: "vizient_member",
      //   accessor: (rowData, i) => (
      //     <Checkbox
      //       assistiveText={{
      //         label: "Default",
      //       }}
      //       id="checkbox-example"
      //       onChange={(e) => {
      //         console.log("onChange ", e.target);
      //       }}
      //     />
      //   ),
      // },
      // {
      //   Header: "Stage",
      //   id: "stage",
      //   accessor: (row, i) => (
      //     <select value={row.stage} onChange={onFieldChange(row.id, "stage")}>
      //       {stages.map((stage, i) => {
      //         return (
      //           <option value={stage} key={i}>
      //             {stage}
      //           </option>
      //         );
      //       })}
      //     </select>
      //   ),
      // },
      // {
      //   Header: "Email",
      //   id: "email",
      //   accessor: (row, i) =>
      //     row?.contacts?.find((contact) => contact.is_primary)?.email ||
      //     row?.contacts[0]?.email,
      // },
      // {
      //   Header: "Phone",
      //   id: "phone",
      //   accessor: (row, i) =>
      //     row?.contacts?.find((contact) => contact.is_primary)?.phone_number ||
      //     row?.contacts[0]?.phone_number,
      // },
      {
        Header: "Created by",
        id: "created_by",
        accessor: (row, i) =>
          row?.created_by?.first_name !== ""
            ? `${row?.created_by?.first_name} ${row?.created_by?.last_name}`
            : row?.created_by?.user_name,
      },
      // {
      //   Header: "Start date",
      //   id: "start_date",
      //   accessor: (row, i) => (
      //     <Moment format="DD-MMM-YYYY">{row.start_date}</Moment>
      //   ),
      // },
      // {
      //   Header: "Kick-off call",
      //   id: "kickoff_call",
      //   accessor: (row, i) => (
      //     <Moment format="DD-MMM-YYYY">{row.kickoff_call}</Moment>
      //   ),
      // },
    ],
    []
  );

  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ by: "created_at", type: "desc" });
  const itemsData = useMemo(
    () => rows?.map((item) => ({ ...item })) || [],
    [rows]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: dataColumns,
      data: itemsData,
      initialState: { pageIndex: data?.projectsBy?.pagination?.skip || 0 },
      manualPagination: true,
      pageCount: data?.projectsBy?.pagination?.total
        ? Math.ceil(data?.projectsBy?.pagination?.total / 10)
        : 0,
    },
    usePagination
  );

  useEffect(() => {
    if (data) {
      setRows(data.projectsBy?.projects);
    }
  }, [data]);

  useEffect(() => {
    const variables = {
      where: {
        search: search,
      },
      skip: pageIndex * pageSize,
      limit: pageSize,
      sort: sort,
    };

    if (!search || search === "") {
      delete variables.where.search;
    }

    if (!sort) {
      delete variables.sort;
    }

    fetchMore({
      variables,
    }).then((res) => {
      setRows(res?.data.projectsBy.projects);
    });
  }, [pageIndex, pageSize, sort]);

  const searchProject = useCallback(
    (e) => {
      e.preventDefault();
      const variables = {
        where: {
          search: search,
        },
        // skip: pageIndex * pageSize,
        // limit: pageSize,
        // sort: sort,
      };

      if (!search || search === "") {
        delete variables.where.search;
      }

      if (!sort) {
        delete variables.sort;
      }

      fetchMore({
        variables,
      }).then((res) => {
        setRows(res?.data.projectsBy.projects);
      });
    },
    [search]
  );

  const clickHandler = (column) => {
    let type, by;

    if (sortable.indexOf(column) > -1) {
      if (!sort || (sort && sort.by === "id") || sort.by !== column) {
        type = "desc";
        by = column;
      } else if (sort.type === "desc") {
        type = "asc";
        by = column;
      } else {
        type = "desc";
        by = "id";
      }

      setSort({ by, type });
    }
  };

  const onFieldChange = (rowId, field) => (e) => {
    let obj = {};
    obj[field] = e.target.value;
    updateOpportunity({
      variables: {
        where: { id: Number(rowId) },
        data: obj,
      },
      refetchQueries: [{ query: OPPORTUNITIES }],
    });
  };

  const actions = () => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <form onSubmit={searchProject}>
          <UIInput
            placeholder="Search projects"
            type="text"
            onInput={(e) => setSearch(e.target.value)}
          />
        </form>
      </ButtonGroup>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <Button
          variant="brand"
          disabled={loading}
          label={loading ? "Loading..." : "Search"}
          onClick={searchProject}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <BrandBand
      id="brand-band-lightning-blue"
      className="slds-p-around_small"
      theme="lightning-blue"
    >
      <PageHeader
        icon={
          <Icon
            assistiveText={{ label: "User" }}
            category="standard"
            name="opportunity"
          />
        }
        label="PROJECT"
        title={`Projects`}
        variant="object-home"
        info={`${rows?.length} projects`}
        onRenderActions={actions}
        // onRenderControls={controls}
      />

      <div
        style={{
          backgroundColor: "#fff",
          overflowX: "auto",
        }}
      >
        <table {...getTableProps()} className="bba-data-table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      onClick={() => clickHandler(column.id)}
                      style={
                        sortable.indexOf(column.id) > -1
                          ? { cursor: "pointer" }
                          : {}
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          maxWidth: "100px",
                          minWidth: "50px",
                        }}
                      >
                        <div style={{ flex: 1 }}>{column.render("Header")}</div>
                        {sort && (
                          <div style={{ width: "24px", margin: "auto" }}>
                            {sort.by === column.id ? (
                              sort.type === "desc" ? (
                                <img
                                  src="icons/custom/chevron-down.svg"
                                  width="16"
                                />
                              ) : sort.type === "asc" ? (
                                <img
                                  src="icons/custom/chevron-up.svg"
                                  width="16"
                                />
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))}
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              page.length > 0 ? (
                page?.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <span>No Data Found</span>
                </tr>
              )
            }
            <tr>
              {loading ? (
                // Use our custom loading state to show a loading indicator
                <td colSpan="10000">Loading...</td>
              ) : (
                <td colSpan="10000">
                  Showing {page.length} of ~
                  {data?.companiesBy?.pagination?.total} results
                </td>
              )}
            </tr>
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions?.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </BrandBand>
  );
}
