import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  IconSettings,
  Checkbox,
  Input,
} from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import { gql, useMutation } from "@apollo/client";
import { Auth } from "aws-amplify";

const CREATE_PROPOSAL = gql`
  mutation CreateProposal($input: ProposalWithItemsInput!) {
    createProposalWithItems(input: $input) {
      id
    }
  }
`;

const EquipmentTypeTableCell = ({ children, item, ...props }) => {
  return (
    <DataTableCell {...props}>
      <p>
        <p>{children?.type}</p>
      </p>
    </DataTableCell>
  );
};

EquipmentTypeTableCell.displayName = DataTableCell.displayName;

export function AddProposal(props) {
  const [equipment, setEquipment] = useState([]);
  const [selection, setSelection] = useState([]);
  const [total, setTotal] = useState(0);
  const [step, setStep] = useState(1);
  const [covid, setCovid] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [weekend, setWeekend] = useState(false);
  const [userId, setUserId] = useState(1);
  const [otherCosts, setOtherCosts] = useState(null);
  const [otherCostsLabel, setOtherCostsLabel] = useState("");

  // const [currency, setCurrency] = useState(null);

  const { handleModal } = React.useContext(ModalContext);
  const handleSelection = (e, data) => {
    setSelection(data.selection);
  };

  const [createProposal, { loading }] = useMutation(CREATE_PROPOSAL);

  useEffect(() => {
    if (props.equipment) {
      setEquipment(props.equipment);
    }
  }, [props.equipment]);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      console.log(user?.attributes["custom:userId"]);
      if (user?.attributes["custom:userId"]) {
        setUserId(Number(user?.attributes["custom:userId"]));
      }
    });
  }, []);

  if (props.equipment.length == 0)
    return (
      <div>Please add equipment to this location to generate a proposal</div>
    );

  return (
    <IconSettings iconPath="/icons">
      {step === 1 && (
        <div
          style={{
            overflow: "scroll",
          }}
        >
          <DataTable
            items={equipment}
            selection={selection}
            onRowChange={handleSelection}
            selectRows="checkbox"
          >
            <DataTableColumn label="Unit Name" property="name" />
            <DataTableColumn label="Building" property="building" />
            {/* <DataTableColumn label="Building code" property="building_code" /> */}

            <DataTableColumn
              label={props?.currency === "AUD" ? "LPS" : "CFM"}
              property={props?.currency === "AUD" ? "lps" : "cfm"}
            />
            <DataTableColumn
              label="Price"
              property="calculated_price"
            ></DataTableColumn>
            {/* <DataTableColumn label="draw_push" property="draw_push" /> */}

            {/* <DataTableColumn label="name" property="name" /> */}
            <DataTableColumn
              label="Location on site"
              property="location_on_site"
            />
            {/* <DataTableColumn label="cfm_tonnage" property="cfm_tonnage" /> */}
            {/* <DataTableColumn label="type" property="type" /> */}
            {/* <DataTableColumn label="water" property="water" />
            <DataTableColumn label="electricity" property="electricity" /> */}

            <DataTableColumn label="Equipment Type" property="equipment_type">
              <EquipmentTypeTableCell />
            </DataTableColumn>
          </DataTable>
          <Button
            onClick={() => {
              if (selection) {
                const propTotal = selection.reduce(
                  (total, item) => total + item.calculated_price,
                  0
                );
                setTotal(propTotal);
              }
              setStep(2);
            }}
          >
            Continue
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <DataTable fixedLayout items={selection} id="selection">
            <DataTableColumn label="ID" property="id" />
            <DataTableColumn label="Unit Name" property="name" />
            {/* <DataTableColumn label="Building code" property="building_code" /> */}

            <DataTableColumn label="Building" property="building" />
            <DataTableColumn
              label={props?.currency === "AUD" ? "LPS" : "CFM"}
              property={props?.currency === "AUD" ? "lps" : "cfm"}
            />
            <DataTableColumn
              label="Price"
              property="calculated_price"
            ></DataTableColumn>
          </DataTable>

          <br />
          <br />
          <div>
            <Checkbox
              assistiveText={{
                label: "Is After Hours Pricing?",
              }}
              id="weekend"
              checked={weekend}
              labels={{
                label: "Is After Hours Pricing?",
              }}
              onChange={(e) => {
                setWeekend(!weekend);
              }}
            />
          </div>
          <div>
            <Checkbox
              assistiveText={{
                label: "Is COVID Pricing?",
              }}
              id="covid"
              checked={covid}
              labels={{
                label: "Is COVID Pricing?",
              }}
              onChange={(e) => {
                setCovid(!covid);
              }}
            />
          </div>
          <div>
            <Input
              styleContainer={{
                width: "250px",
              }}
              id="discount"
              fixedTextRight="%"
              type="number"
              label="Discount"
              placeholder="Enter discount as a percent"
              value={discount}
              onChange={({ target }) => {
                setDiscount(target.value);
              }}
            />
          </div>
          <div>
            <Input
              styleContainer={{
                width: "250px",
              }}
              id="other_costs_label"
              type="text"
              label="Other Costs Label"
              placeholder="Enter other costs as a text, like Travel, Fuel ...etc"
              value={otherCostsLabel}
              onChange={({ target }) => {
                setOtherCostsLabel(target.value);
              }}
            />
          </div>
          <div>
            <Input
              styleContainer={{
                width: "250px",
              }}
              id="other_costs"
              type="number"
              label="Other Costs Amount ($)"
              placeholder="Enter other costs amount"
              value={otherCosts}
              onChange={({ target }) => {
                setOtherCosts(target.value);
              }}
            />
          </div>

          {/* <div>
          <label htmlFor="currency">
             Currency *
              <select name="currency" onChange={({target}) => setCurrency(target.value)}>
                <option value='USD' selected={props?.company?.country === 'united states'}>USD</option>
                <option value='AUD' selected={props?.company?.country === 'australia'}>AUD</option>
              </select>
            </label>
          </div> */}
          {/* <br />
          {
            weekend &&
            <p>Weekend additional: $750</p>
          }
          {
            covid &&
            <p>COVID additional: 18%</p>
          }
          {
            discount &&
            <p>Additional discount: </p>
          } */}
          <br />

          <h2>
            Currency: {props?.currency}
            <br />
            Proposal subtotal:{total}
            <br />
            Discount: {discount}%
            <br />
            {otherCostsLabel && `${otherCostsLabel}:`}
            {otherCosts && `${otherCosts}`}
            <br />
            Proposal Total:
            {Math.round(
              ((Number(otherCosts) || 0) + total - (total * discount) / 100) *
                100
            ) / 100}
          </h2>
          <br />
          <br />

          <Button onClick={() => setStep(1)}>Back</Button>
          <Button
            variant="brand"
            onClick={() => {
              const id =
                props?.isProject === true ? "project_id" : "opportunity_id";
              createProposal({
                variables: {
                  input: {
                    [id]: props?.opportunityId,
                    created_by: userId,
                    proposal_items: selection.map((item) => {
                      const {
                        building,
                        // building_code,
                        // system_name,
                        // draw_push,
                        cfm,
                        // water,
                        // electricity,
                        id,
                        // type,
                        name,
                        location_on_site,
                        // cfm_tonnage,
                        equipment_type,
                        calculated_price,
                      } = item;

                      const proposalItem = {
                        building,
                        // building_code,
                        // system_name,
                        // draw_push,
                        cfm: Number(cfm),
                        // water: false,
                        // electricity: false,
                        // calculated_price:
                        //   getPrice(item) -
                        //   (getPrice(item) * Number(discount)) / 100,
                        equipment_id: id,
                        calculated_price,
                        // type,
                        name,
                        location_on_site,
                        // cfm_tonnage,
                        equipment_type_id: equipment_type?.id || "Other",
                      };
                      if (!proposalItem?.equipment_type_id)
                        delete proposalItem?.equipment_type_id;

                      return proposalItem;
                    }),
                    discount: Number(discount) / 100,
                    is_covid_pricing: covid,
                    is_after_hours_pricing: weekend,
                    currency: props?.currency || "USD",
                    other_costs_label: otherCostsLabel || "",
                    other_costs: Number(otherCosts) || 0,
                  },
                },
              }).then((res) => {
                handleModal();
                return props?.refetch();
              });
            }}
            disabled={loading}
          >
            Create proposal
          </Button>
        </div>
      )}
    </IconSettings>
  );
}
