import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { AddContactForm } from "../contacts/ModalAddContact";
import { ModalContext } from "../ModalContext";
import GET_CONTACT from "../../graphql/queries/contact";

const RecordLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/contacts/${item.id}`}>{children}</Link>
  </DataTableCell>
);

const CompanyCell = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <p>{item && item.company ? item.company.company_name : ""}</p>
  </DataTableCell>
);

RecordLink.displayName = DataTableCell.displayName;
CompanyCell.displayName = DataTableCell.displayName;

export function ContactsList(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [contactList, setContactList] = useState(undefined);
  const [realListData, setRealListData] = useState(undefined);
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_CONTACT, {
    variables: { id: parseInt(props.company.id) },
  });

  useEffect(() => {
    if (data) {
      setContactList(data.company.contacts);
      setRealListData(data.company.contacts);
    }
  }, [loading, data]);

  const triggerModal = () =>
    handleModal(
      <AddContactForm refetch={refetch} id={props.company} />,
      "Add Contact"
    );

  const filterTable = (input) => {
    setContactList(
      realListData.filter((item) =>
        item["contact_name"].toLowerCase().includes(input.toLowerCase())
      )
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="ContactCard"
        headerActions={
          <Button label="Add Contact" onClick={() => triggerModal()} />
        }
        filter={<CardFilter onChange={(e) => filterTable(e.target.value)} />}
        heading={`Contacts (${contactList ? contactList.length : 0})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          !contactList ||
          (contactList.length == 0 && (
            <CardEmpty heading="No Contacts">
              <Button label="Add Contact" onClick={() => triggerModal()} />
            </CardEmpty>
          ))
        }
      >
        <DataTable
          fixedLayout
          items={contactList && contactList}
          id="getContacts"
        >
          <DataTableColumn label="Id" property="id"></DataTableColumn>
          <DataTableColumn label="Contact Name" property="contact_name">
            <RecordLink />
          </DataTableColumn>
          <DataTableColumn label="Email" property="email" />
          <DataTableColumn label="Phone" property="phone_number" />
          <DataTableColumn label="Title" property="company_title" />
        </DataTable>
      </Card>
    </IconSettings>
  );
}
