import React, { useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  PageHeader,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  BrandBand,
  DataTable,
  DataTableColumn,
  DataTableCell,
  File,
  Files,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import EquipmentData from "./EquipmentData";
import { Auth, Storage } from "aws-amplify";
import moment from "moment";

async function getFile(name) {
  const file = await Storage.get(`${name}`);
  return file;
}

const SINGLE = gql`
  query Equipment($id: ID!) {
    equipment(id: $id) {
      id
      name
      building
      cfm
      lps
      location_on_site
      equipment_type {
        id
        type
      }
      time_tracking {
        id
        start_time
        end_time
        technician {
          id
          user_name
        }
      }
      attachments {
        id
        name
        notes
      }
      notes {
        id
        message
        created_by {
          id
          user_name
        }
        labels
        category
        created_at
      }
      project {
        id
        location {
          id
          location_name
          address
          city
          state
          company {
            id
            company_name
            country
          }
        }
      }
      location {
        id
        location_name
        address
        city
        state
        company {
          id
          company_name
          country
        }
      }
      data {
        id
        created_by {
          id
          user_name
        }
        created_at
        notes
      }
      answers {
        id
        text
        created_at
        question {
          id
          text
        }
        user {
          id
          user_name
        }
      }
    }
  }
`;
const CREATE_FILE = gql`
  mutation CreateFile($fileName: String!, $equipmentId: ID!) {
    createFile(
      input: {
        name: $fileName
        equipment_id: $equipmentId
        notes: ""
        path: ""
      }
    ) {
      id
    }
  }
`;

const UserCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item?.created_by?.user_name || ""}</p>
  </DataTableCell>
);
UserCell.displayName = DataTableCell.displayName;

const TechnicianCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item?.technician?.user_name || ""}</p>
  </DataTableCell>
);
TechnicianCell.displayName = DataTableCell.displayName;

const TotalCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>
      {moment(item?.start_time)?.isValid() && moment(item?.end_time)?.isValid()
        ? moment
            .utc(
              moment
                .duration(moment(item?.end_time).diff(moment(item?.start_time)))
                ?.asMilliseconds()
            )
            .format("HH:mm:ss")
        : "00:00:00"}
    </p>
  </DataTableCell>
);
TotalCell.displayName = DataTableCell.displayName;

const FilesCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item?.files?.length || 0}</p>
  </DataTableCell>
);
FilesCell.displayName = DataTableCell.displayName;

const DateCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    {moment(children).isValid() ? (
      <Moment format="DD-MMM-YYYY hh:mm A">{children}</Moment>
    ) : moment(item?.start_time)?.isValid() &&
      !moment(item?.end_time)?.isValid() ? (
      "In progress"
    ) : (
      "Invalid Date"
    )}
  </DataTableCell>
);
DateCell.displayName = DataTableCell.displayName;

function EquipmentDetail({ match }) {
  const [selection, setSelection] = useState([]);

  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: {
      id: match.params.equipmentId,
    },
  });

  const [createFile, { loading: loadingFile }] = useMutation(CREATE_FILE);
  const [uploadingFile, setUploadingFile] = useState(false);

  const [files, setFiles] = useState([]);

  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setAuthUser(user));
  }, []);

  useEffect(() => {
    (async () => {
      await data?.equipment?.attachments.forEach(async (file) => {
        const awsFile = await getFile(file?.name);
        setFiles((files) => [...files, { ...file, url: awsFile }]);
      });
    })();
  }, [data?.equipment?.attachments]);

  const onChange = (e) => {
    const file = e.target.files[0];
    const ext = file.name.split(".").pop();
    const fileName = `attachment_${data?.equipment?.id}_${Math.floor(
      Math.random() * 10000
    )}.${ext}`;
    setUploadingFile(true);
    Storage.put(fileName, file, {
      contentType: file.type,
    })
      .then(() => {
        setUploadingFile(false);
        createFile({
          variables: {
            fileName,
            equipmentId: data?.equipment?.id,
          },
        }).then(() => refetch());
      })
      .catch((err) => {
        setUploadingFile(false);
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const location =
    data?.equipment?.project?.location || data?.equipment?.location;
  const details = [
    {
      label: "Company",
      content: (
        <Link to={`/companies/${location?.company?.id}`}>
          {location?.company?.company_name}
        </Link>
      ),
    },
    {
      label: "Location",
      content: (
        <Link
          to={`/companies/${location?.company?.id}/locations/${location?.id}`}
        >
          {location?.location_name}
        </Link>
      ),
    },
    {
      label: location?.company?.country === "australia" ? "LPS" : "CFM",
      content: (
        <p>
          {location?.company?.country === "australia"
            ? data.equipment.cfm
            : data.equipment.cfm}
        </p>
      ),
    },
    {
      label: "Unit Name",
      content: <p>{data.equipment.name}</p>,
    },
    {
      label: "Location on site",
      content: <p>{data.equipment.location_on_site}</p>,
    },
    {
      label: "Building",
      content: <p>{data.equipment.building}</p>,
    },
  ];

  //   const total = data?.equipment?.time_tracking?.reduce((acc, nextItem, i, items) => {
  //   const item = i > 0 ? items[i-1] : nextItem
  //   if (
  //     moment(nextItem?.end_time)?.isValid() &&
  //     moment(nextItem?.start_time)?.isValid() &&
  //     moment(item?.end_time)?.isValid() &&
  //     moment(item?.start_time)?.isValid() ) {

  //     const diff1 = moment.duration(moment(nextItem?.end_time).diff(moment(item?.start_time)))?.asMilliseconds()
  //     const diff2 = moment.duration(moment(nextItem?.start_time).diff(moment(item?.end_time)))?.asMilliseconds()
  //     return acc + (diff2 > 0 ? diff1 - diff2 : diff1)
  //   } else {
  //     return  moment(nextItem?.end_time)?.isValid() &&
  //     moment(nextItem?.start_time)?.isValid() ? moment.duration(moment(nextItem?.end_time).diff(moment(nextItem?.start_time)))?.asMilliseconds() : 0
  //   }

  // }, 0)

  const total = data?.equipment?.time_tracking
    ?.filter(
      (item) =>
        moment(item?.end_time)?.isValid() && moment(item?.start_time)?.isValid()
    )
    ?.reduce((acc, nextItem, i, items) => {
      if (i > 0) {
        const item = items[i - 1];
        const diff1 = moment
          .duration(moment(nextItem?.end_time).diff(moment(item?.start_time)))
          ?.asMilliseconds();
        const diff2 = moment
          .duration(moment(nextItem?.start_time).diff(moment(item?.end_time)))
          ?.asMilliseconds();
        return acc + (diff2 > 0 ? diff1 - diff2 : diff1);
      } else {
        return items.length > 1
          ? 0
          : moment
              .duration(
                moment(nextItem?.end_time).diff(moment(nextItem?.start_time))
              )
              ?.asMilliseconds();
      }
    }, 0);

  const feedback = data?.equipment?.answers
    ?.map((answer) => ({
      question: answer?.question,
      answer: {
        id: answer?.id,
        text: answer?.text,
        by: answer?.user?.user_name,
        at: answer?.created_at,
      },
    }))
    .reduce((result, item) => {
      const index = result?.findIndex(
        (x) => x?.question?.id === item?.question?.id
      );

      if (index > -1) {
        result[index] = {
          ...result[index],
          answers: [...result[index]?.answers, item?.answer],
        };
        return result;
      } else {
        result.push({
          question: item?.question,
          answers: [item?.answer],
        });

        return result;
      }
    }, []);

  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          details={details}
          icon={
            <Icon
              assistiveText={{ label: "Equipment" }}
              category="standard"
              name="account"
            />
          }
          label="Equipment:"
          title={data.equipment.name}
          variant="record-home"
          info={"whatever"}
        />

        <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
          <div className="slds-col slds-col slds-size_1-of-2">
            <Tabs id="left" className="slds-var-p-around_small">
              <TabsPanel label="Data Reads">
                <div style={{ overflow: "scroll", height: "340px" }}>
                  <DataTable
                    items={data?.equipment?.data}
                    id="getCompanies"
                    selectRows="radio"
                    onRowChange={(e, data) => setSelection(data?.selection)}
                    selection={selection}
                  >
                    <DataTableColumn
                      primaryColumn
                      label="Id"
                      key="id"
                      property="id"
                    />

                    <DataTableColumn
                      primaryColumn
                      label="Created By"
                      key="created_by"
                      property="created_by"
                    >
                      <UserCell />
                    </DataTableColumn>
                    <DataTableColumn
                      primaryColumn
                      label="Date"
                      key="created_at"
                      property="created_at"
                    >
                      <DateCell />
                    </DataTableColumn>
                    <DataTableColumn
                      primaryColumn
                      label="Notes"
                      key="notes"
                      property="notes"
                    />
                    <DataTableColumn
                      primaryColumn
                      label="Files"
                      key="files"
                      property="files"
                    >
                      <FilesCell />
                    </DataTableColumn>
                  </DataTable>
                </div>
              </TabsPanel>
              <TabsPanel label="Notes/Change Requests">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {data?.equipment?.notes?.map((note) => (
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        margin: "4px",
                        padding: "8px 8px 4px 8px",
                        border: "1px solid grey",
                        borderRadius: "4px",
                        flexDirection: "column",
                      }}
                      key={note?.id}
                    >
                      <p>{note?.message}</p>
                      <div
                        style={{ display: "flex", flex: 1, marginTop: "4px" }}
                      >
                        {note?.labels &&
                          note?.labels?.map((label, i) => (
                            <span
                              key={i}
                              style={{
                                border: "1px solid",
                                backgroundColor:
                                  label === "CHANGE_REQUEST" ? "red" : "grey",
                                color: "white",
                                fontSize: "10px",
                                fontWeight: 600,
                                height: "18px",
                                borderRadius: "9px",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                              }}
                            >
                              {label}
                            </span>
                          ))}
                        <span
                          style={{
                            marginLeft: "auto",
                            marginTop: "auto",
                            fontSize: "10px",
                          }}
                        >
                          created by{" "}
                          <strong>{note?.created_by?.user_name}</strong>,{" "}
                          <Moment format="DD-MMM-YYYY HH:mm A">
                            {note?.created_at}
                          </Moment>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </TabsPanel>

              <TabsPanel label="Attachments">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <input
                        type="file"
                        accept="image/*, .pdf, video/*"
                        onChange={(evt) => onChange(evt)}
                      />
                    </div>
                    <div>
                      {loadingFile && <div>Loading file...</div>}
                      {uploadingFile && <div>Uploading file...</div>}
                    </div>
                  </div>

                  {/* <Files id="files-default-example">
                      {
                        files?.map((file, i) => <File
                          key={`file-${i}`}
                          id={`file-${i}`}
                          labels={{
                            title: file?.name,
                          }}
                          assistiveText={{
                            image: 'Placeholder image',
                          }}
                          icon={<Icon category="doctype" name="pdf" />}
                          image={file}
                        />)
                      }
                    </Files> */}

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {files?.map((file) => (
                      <div
                        key={file}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          margin: "16px",
                        }}
                      >
                        <a href={file?.url} target="_blank">
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "4px",
                              backgroundImage: `url(${file?.url}), url(/file.png)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              border: "1px solid grey",
                              height: "220px",
                              width: "180px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                marginTop: "auto",
                                backgroundColor: "#000000aa",
                                padding: "8px",
                                color: "#fff",
                                textDecoration: "none",
                              }}
                            >
                              {file?.notes || file?.name}
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </TabsPanel>

              <TabsPanel label="Time">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                    width: "220px",
                    border: "1px solid grey",
                    borderRadius: "4px",
                  }}
                >
                  <div style={{ fontSize: "18px", fontWeight: 900 }}>{`${moment
                    .utc(total)
                    .format("HH:mm:ss")}`}</div>
                  <span style={{ textAlign: "center" }}>
                    The total time spent on this equipment
                  </span>
                </div>
                <div
                  style={{
                    overflow: "scroll",
                    height: "340px",
                    marginTop: "8px",
                  }}
                >
                  <DataTable
                    items={data?.equipment?.time_tracking}
                    id="time_tracking"
                  >
                    <DataTableColumn
                      primaryColumn
                      label="Technician"
                      key="technician"
                      property="technician"
                    >
                      <TechnicianCell />
                    </DataTableColumn>
                    <DataTableColumn
                      label="Start time"
                      key="start_time"
                      property="start_time"
                    >
                      <DateCell />
                    </DataTableColumn>
                    <DataTableColumn
                      label="End time"
                      key="end_time"
                      property="end_time"
                    >
                      <DateCell />
                    </DataTableColumn>
                    <DataTableColumn label="Total" key="total" property="total">
                      <TotalCell />
                    </DataTableColumn>
                  </DataTable>
                </div>
              </TabsPanel>

              <TabsPanel label="Technicians Feedback">
                {feedback?.map((item) => (
                  <div key={item?.question?.id}>
                    <div style={{ fontWeight: 900 }}>
                      {item?.question?.text}?
                    </div>
                    {item?.answers?.map((answer) => (
                      <div
                        key={answer?.id}
                        style={{
                          marginTop: "4px",
                          borderBottom: "1px solid #dddbda",
                          display: "flex",
                        }}
                      >
                        <div>{answer?.text}</div>
                        <div
                          style={{
                            fontSize: "10px",
                            display: "flex",
                            marginLeft: "auto",
                          }}
                        >
                          <span style={{ marginRight: "8px" }}>
                            by <strong>{answer?.by}</strong>
                          </span>
                          <span>
                            created at{" "}
                            <Moment format="DD-MMM-YYYY">{answer?.at}</Moment>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </TabsPanel>
            </Tabs>
          </div>
          <div className="slds-col slds-col slds-size_1-of-2">
            {selection?.length > 0 && (
              <EquipmentData dataId={selection[0]?.id} />
            )}
          </div>
        </div>
      </BrandBand>
    </IconSettings>
  );
}

export default EquipmentDetail;
