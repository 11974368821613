import React, { useState, useEffect } from "react";
import {
  ButtonGroup,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import OpportunityForm from "../opportunities/ModalAddOpportunity";
import Moment from "react-moment";
import { Link, BrowserRouter as Router } from "react-router-dom";
 
function OpportunitiesList(props) {
  const [opportunities, setOpportunities] = useState([]);
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    let arr = [];
    let locArr = [];
    if (props.locations) {
      props.locations.map((location) => {
        locArr.push(location.location);
        location.location.hasOwnProperty('opportunities') && location.location.opportunities.map((project) => {
          arr.push({
            company: props.company,
            name: project.name,
            location_id: location.location.id,
            opportunity_id: project.id,
            location_name: location.location.location_name,
            state: location.state,
            stage: project.stage,
            value: project.total_amount,
          });
        });
      });
      setOpportunities(arr);
      setLocations(locArr);
    }
  }, [props]);

  const { handleModal } = React.useContext(ModalContext);

  const triggerModal = (content, heading) => {
    handleModal(
      <OpportunityForm
        locations={locations}
        addOpportunity={props.addOpportunity}
      />,
      "Add Opportunity"
    );
  };

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="OpportunityCard"
        headerActions={
          <Button label="Add Opportunity" onClick={() => triggerModal()} />
        }
        filter={
          opportunities.length > 0 && (
            <CardFilter
              onChange={() => {
                console.log("Change");
              }}
            />
          )
        }
        heading={`Opportunities (${opportunities.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          opportunities.length === 0 ? (
            <CardEmpty heading="No Opportunities">
              <Button label="Add Opportunity" onClick={() => triggerModal()} />
            </CardEmpty>
          ) : null
        }
      >
        <ul>
          {opportunities.map((opportunity) => {
            return (
              <li style={{ padding: "20px", marginBottom: "20px" }}>
                <Link
                  to={`/companies/${props.company}/opportunities/${opportunity.opportunity_id}`}
                >
                  <h2 style={{ fontSize: "20px", color: "#000000" }}>
                    {opportunity.name}{" "}
                  </h2>
                  <p>Location: {opportunity.location_name} </p>
                  <p>
                    Expected start date:{" "}
                    <Moment
                      date={opportunity.start_date}
                      format={"DD-MMM-YYYY"}
                    />{" "}
                  </p>
                  <p>Current stage: {opportunity.stage}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </Card>
    </IconSettings>
  );
}

export default OpportunitiesList;
