import React, {useState, useEffect} from 'react';
import {
  DataTable,
	DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  DataTableRowActions,
  Dropdown,
  CardFilter,
} from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import ModalAddContactToOpportunity from './ModalAddContactToOpportunity'
import { Link } from "react-router-dom";

const RecordLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/contacts/${item.id}`}>{children}</Link>
  </DataTableCell>
);
RecordLink.displayName = DataTableCell.displayName;

export function ContactsTab(props) {
	const [contacts, setContacts] = useState([]);

	const { handleModal } = React.useContext(ModalContext);

	useEffect(() => {
		if (props.contacts) {
			setContacts(props.contacts)
		}
	},[props])
	const triggerModal = () => {
		  
    handleModal(
      <ModalAddContactToOpportunity id={props?.id} refetch={props.refetch} />,
      "Add Contacts"
    );

	}
	const handleAction = (e,d) => {
		console.log(e,d)
	}
	return (
		<Card
	        id="ContactCard"
	        headerActions={
	          <Button label="Add Contacts" onClick={triggerModal}/>
	        }
	        filter={
	          contacts.length > 0 && (
	            <CardFilter
	              onChange={() => {
	                console.log("Change");
	              }}
	            />
	          )
	        }
	        heading={`Contacts (${contacts.length})`}
	        icon={<Icon category="standard" name="contact" size="small" />}
	        empty={
	          contacts.length === 0 ? (
	            <CardEmpty heading="No Contacts">
	              <Button
							label="Add Contacts"
							onClick={triggerModal}
	              />
	            </CardEmpty>
	          ) : null
	        }
	      >
	        <DataTable
	          fixedLayout
	          items={contacts}
	          id="contacts"
	          on
	        >
	           <DataTableColumn label="Id" property="id"></DataTableColumn>
          <DataTableColumn label="Contact Name" property="contact_name"><RecordLink /></DataTableColumn>
          <DataTableColumn label="Email" property="email" />
          <DataTableColumn label="Phone" property="phone_number" />
          <DataTableColumn label="Title" property="company_title" />
	          	
	          <DataTableRowActions
				options={[
					{
						id: 0,
						label: 'Send Proposal',
						value: '1',
					},
					{
						id: 1,
						label: 'Email',
						value: '2',
					},
					{
						id: 2,
						label: 'Remove',
						value: '3',
					}				]}
					dropdown={<Dropdown length="3" />}
					onAction={handleAction}
				/>
	        </DataTable>
	      </Card>
	)
}