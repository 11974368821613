import { gql } from "@apollo/client";

const SINGLE = gql`
  query GetCompanyByID($id: ID!) {
    company(id: $id) {
      id
      company_name
      country
      state
      city
      category
      website
      created_at
      updated_at
      locations {
        id
        location_name
        equipments {
          id
          cfm
        }
        projects {
          id
          project_name
          stage
          total_amount
        }
        opportunities {
          id
          name
          stage
          total_amount
          proposals {
            id
            is_approved
          }
        }
      }
    }
  }
`;

export default SINGLE;
