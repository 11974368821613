import React, { useState, useEffect } from "react";
import {
  GlobalNavigationBar,
  GlobalNavigationBarRegion,
  GlobalNavigationBarLink,
  AppLauncher,
  AppLauncherExpandableSection,
  AppLauncherTile,
  Button,
  Icon,
} from "@salesforce/design-system-react";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";

export function NavBar() {
  const history = useHistory();
  const [currentLocation, setCurrentLocation] = useState("");
  let location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
  }, [location]);

  function handleNav(path) {
    history.push(path);
  }

  async function signOut() {
    try {
      await Auth.signOut();
      history.push("/");
      window.location.reload(false);
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  }

  return (
    <GlobalNavigationBar>
      <GlobalNavigationBarRegion region="secondary" navigation>
        {/* <GlobalNavigationBarLink
          active={
            currentLocation.includes("companies") &&
            !currentLocation.includes("opportunities")
          }
          onClick={(path) => handleNav("/companies")}
          label="Companies"
          id="home-link"
        />
        <GlobalNavigationBarLink
          active={currentLocation.includes("contacts")}
          onClick={(path) => handleNav("/contacts")}
          label="Contacts"
        /> */}
        <GlobalNavigationBarLink
          active={currentLocation.includes("opportunities")}
          onClick={(path) => handleNav("/opportunities")}
          label="Opportunities"
        />
        <GlobalNavigationBarLink
          active={currentLocation.includes("projects")}
          onClick={(path) => handleNav("/projects")}
          label="Projects"
        />
        {/* <GlobalNavigationBarLink
          active={currentLocation.includes("users")}
          onClick={(path) => handleNav("/users")}
          label="Users"
        /> */}
      </GlobalNavigationBarRegion>
    </GlobalNavigationBar>
  );
}
