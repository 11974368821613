import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { useQuery, gql, useMutation } from "@apollo/client";
import {
  PageHeader,
  PageHeaderControl,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  Button,
  ButtonGroup,
  BrandBand,
} from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import { Link } from "react-router-dom";
import { NotesTab } from "./NotesTab";
import { AddContactToLocation } from "../contacts/ModalAddContactToLocation";
import { ContactUpdate } from "./ContactUpdate";
import { ContactLocationsList } from "./ContactLocationsList";
import OpportunitiesList from "./OpportunitiesList";
import { ProposalTab } from "./../opportunities/ProposalTab";
import { ProjectsList } from "./ProjectsList";
import _ from "lodash";
import ConfirmDelete from "../modals/ConfirmDelete";

const SINGLE = gql`
  query contactByID($id: ID!) {
    contact(id: $id) {
      id
      contact_name
      phone_number
      email
      company_title
      created_at
      company {
        id
        company_name
      }
      notes {
        id
        message
        created_at
        created_by {
          id
          user_name
        }
      }
      locations {
        id
        location {
          id
          location_name
          address
          city
          state
          location_code
          projects {
            id
            project_name
            is_opportunity
            created_at
            start_date
            stage
            proposals {
              id
              is_approved
              created_at
              proposal_items {
                id
                calculated_price
              }
            }
          }
        }
      }
      opportunity{
        id
        name
        stage
        total_amount
        currency
        updated_at
      }
    }
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteContact(where: { id: $id }) {
      id
    }
  }
`;

export function ContactDetail({ match, props }) {
  const { handleModal } = React.useContext(ModalContext);

  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: { id: Number(match.params.contactId) },
  });

  

  const [isOpen, setOpen] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(DELETE);

  const triggerModal = (content, heading) => {
    handleModal(
      <AddContactToLocation
        refetch={refetch}
        contact={match.params.contactId}
        id={data.contact.company.id}
      />,
      "Assign Location"
    );
  };

  const [projectList, setProjectsList] = useState([]);

  useEffect(() => {
    if (data) {
      if (data.contact.locations) {
        data.contact.locations.map((location) => {
          let projects = location.location.projects;
          if (projects.length > 0) {
            setProjectsList(projects);
          }
        });
      }
    }
  }, [loading]);

  const actions = () => (
    <React.Fragment>
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button label="Assign Location" onClick={() => triggerModal()} />
          <Button
            iconCategory="utility"
            iconName="delete"
            iconPosition="left"
            label="Delete"
            onClick={() => setOpen(true)}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </React.Fragment>
  );

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div style={{ padding: "16px" }}>
        <div style={{ fontSize: "18px" }}>Error!</div>
        <div>{error?.message}</div>
      </div>
    );
  const details = [
    {
      label: "Company",
      content: (
        <Link to={`/companies/${data.contact?.company?.id}`}>
          {data.contact?.company?.company_name}
        </Link>
      ),
      truncate: true,
    },
    {
      label: "Created at",
      content: <Moment format="DD-MMM-YYYY">{data.contact.created_at}</Moment>,
    },
    {
      label: "Title",
      content: data.contact?.company_title,
    },
    {
      label: "Email Address",
      content: data.contact?.email,
      truncate: true,
    },
  ];

  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          details={details}
          icon={
            <Icon
              assistiveText={{ label: "User" }}
              category="standard"
              name="account"
            />
          }
          label="Name:"
          onRenderActions={actions}
          title={data.contact.contact_name}
          variant="record-home"
        />

        <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
          <div className="slds-col slds-col slds-size_2-of-3">
            <Tabs id="left" className="slds-var-p-around_small">
              <TabsPanel label="Details">
                <ContactUpdate refetch={refetch} contact={data.contact} />
              </TabsPanel>
              <TabsPanel label="Locations">
                <ContactLocationsList
                  company={data.contact?.company?.id}
                  contact={data.contact?.id}
                /> 
              </TabsPanel>

              <TabsPanel label="Proposals">
                {/* <ProposalTab projects={projectList} refetch={refetch} /> */}
                <ProposalTab
                    proposals={projectList.proposals}
                    opportunityId={projectList?.id}
                    currency={projectList?.currency}
                    refetch={refetch}
                    equipment={
                      projectList?.location
                        ? projectList?.location.equipments
                        : []
                    }
                  />
              </TabsPanel>
              <TabsPanel label="Projects">
                <ProjectsList projectList={projectList} refetch={refetch} />
              </TabsPanel>
            </Tabs>
          </div>
          <div className="slds-col slds-col slds-size_1-of-3">
            <Tabs id="right" className="slds-var-p-around_small">
              <TabsPanel label="Opportunities">
                <OpportunitiesList
                  company={data.contact?.company?.id}
                  locations={data.contact?.locations}
                />
              </TabsPanel>
              <TabsPanel label="Notes">
                <NotesTab
                  notes={data.contact?.notes}
                  contact={data.contact?.id}
                  refetch={refetch}
                />
              </TabsPanel>
            </Tabs>
          </div>
        </div>
        <ConfirmDelete
          isOpen={isOpen}
          toggleOpen={setOpen}
          action={deleteMutation}
          variables={{ id: Number(data?.contact?.id) }}
          loading={deleting}
          url={`/contacts`}
          message={`${data?.contact?.contact_name} Contact will be permanently deleted and you won't be able to recover it. `}
        />
      </BrandBand>
    </IconSettings>
  );
}
