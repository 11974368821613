import { getCfm } from "../../utilities/functions";

export const getReductionPressureDrop = (unit) => {
  if (
    unit?.pre_reads?.coil_differential_pressure &&
    unit?.post_reads?.coil_differential_pressure
  )
    return (
      Math.round(
        Math.abs(
          Number(unit?.pre_reads?.coil_differential_pressure) -
            Number(unit?.post_reads?.coil_differential_pressure)
        ) * 100
      ) / 100
    );
  return 0;
};

export const formatNumbers = (number) =>
  Intl.NumberFormat("en-US", {
    style: "decimal",
  }).format(number);

export const getReductionPressureDropPercent = (unit) => {
  if (
    Number(unit?.pre_reads?.coil_differential_pressure) &&
    Number(unit?.post_reads?.coil_differential_pressure)
  ) {
    const x =
      (Number(unit?.pre_reads?.coil_differential_pressure) -
        Number(unit?.post_reads?.coil_differential_pressure)) /
      Number(unit?.pre_reads?.coil_differential_pressure);

    return Math.round(x * 100 * 100) / 100;
  }
  return 0;
};

export const getAvgPressureDropByBuilding = (building) => {
  const sums = building.units.reduce(
    (sums, unit) => ({
      pre:
        sums.pre + (Number(unit?.pre_reads?.coil_differential_pressure) || 0),
      post:
        sums.post + (Number(unit?.post_reads?.coil_differential_pressure) || 0),
    }),
    { pre: 0, post: 0 }
  );

  const result =
    sums.post !== 0
      ? Math.round(((sums.pre - sums.post) / sums.pre) * 100 * 100) / 100
      : 0;

  return result;
};

export const getAvgPressureDrop = (equipments) => {
  const readings = equipments.map((unit) => {
    return {
      pre_reads:
        unit.equipment_readings &&
        unit.equipment_readings.length > 0 &&
        unit.equipment_readings
          .filter((x) => x.reading_type === "PRE_READ")
          .reduce((last, reading) => {
            return new Date(reading?.date_time).getTime() >
              (last?.date_time ? new Date(last?.date_time).getTime() : 0)
              ? reading
              : last;
          }, null),

      post_reads:
        unit.equipment_readings &&
        unit.equipment_readings.length > 0 &&
        unit.equipment_readings
          .filter((x) => x.reading_type === "POST_READ")
          .reduce((last, reading) => {
            return new Date(reading?.date_time).getTime() >
              (last?.date_time ? new Date(last?.date_time).getTime() : 0)
              ? reading
              : last;
          }, null),
    };
  });
  const sums = readings.reduce(
    (sums, unit) => ({
      pre:
        sums.pre + (Number(unit?.pre_reads?.coil_differential_pressure) || 0),
      post:
        sums.post + (Number(unit?.post_reads?.coil_differential_pressure) || 0),
    }),
    { pre: 0, post: 0 }
  );

  return sums.post !== 0
    ? (Math.round(((sums.pre - sums.post) / sums.pre) * 100) / 100) * 100
    : 0;
};

export const getAvgCFMByBuilding = (building) => {
  const units = building.units?.filter(
    (unit) => getReductionPressureDrop(unit) > 0
  );

  return (
    units.reduce(
      (total, unit) => total + (Number(unit.cfm) || getCfm(unit.lps)),
      0
    ) / units.length || 0
  );
};

export const getAvgCFM = (buildings) => {
  const avg = buildings.reduce(
    (total, building) => total + getAvgCFMByBuilding(building),
    0
  );
  return Math.round(avg * 100) / 100;
};

export const getCoolingSaving = (
  unit,
  recirculatedAir,
  operating_days_week,
  operating_hours_day,
  cop
) => {
  // if (
  //   unit?.pre_reads?.outside_air_temperature &&
  //   unit?.post_reads?.outside_air_temperature
  // ) {
  //   const outside_air_temperature = Math.abs(
  //     Number(unit?.pre_reads?.outside_air_temperature) -
  //       Number(unit?.post_reads?.outside_air_temperature)
  //   );

  //   let cop1 = (outside_air_temperature + recirculatedAir) / 3;
  //   let cop2 = (outside_air_temperature + recirculatedAir) / 2.75;
  //   let hourly = cop / (4 * 4 * 24 * 7);
  //   let coolingSaving =
  //     ((cop * operating_days_week * operating_hours_day * 4 * 4) / 1000) *
  //     (Number(unit.cfm) || getCfm(unit.lps));

  //   return Math.round(coolingSaving * 100) / 100;
  // }

  // return 0;

  const coolingSaving =
    ((cop * operating_days_week * operating_hours_day * 4 * 4) / 1000) *
    (Number(unit.cfm) || getCfm(unit.lps));

  return Math.round(coolingSaving * 100) / 100;
};

export const getTotalCoolingSavingByBuilding = (
  building,
  recirculatedAir,
  operating_days_week,
  operating_hours_day,
  cop
) => {
  const savings = building.units
    ?.map((unit) =>
      getCoolingSaving(
        unit,
        recirculatedAir,
        operating_days_week,
        operating_hours_day,
        cop
      )
    )
    ?.filter((saving) => saving > 0);

  return savings.reduce((total, saving) => total + saving, 0);
};

export const getTotalCoolingSaving = (
  buildings,
  recirculatedAir,
  operating_days_week,
  operating_hours_day,
  cop
) => {
  return buildings.reduce(
    (total, building) =>
      total +
      getTotalCoolingSavingByBuilding(
        building,
        recirculatedAir,
        operating_days_week,
        operating_hours_day,
        cop
      ),
    0
  );
};

export const getFanEnergySaving = (unit, motorEfficiency) => {
  if (
    Number(unit?.pre_reads?.coil_differential_pressure) &&
    Number(unit?.post_reads?.coil_differential_pressure)
  ) {
    const pressure = Math.abs(
      Number(unit?.pre_reads?.coil_differential_pressure) -
        Number(unit?.post_reads?.coil_differential_pressure)
    );

    const cfm = Number(unit.cfm) || getCfm(unit.lps);
    return (((cfm * pressure) / 6356 / 0.65) * 0.746) / motorEfficiency;
  }
  return 0;
};

export const getFanEnergySavingByBuilding = (building, motorEfficiency) => {
  return building.units?.reduce(
    (total, unit) => total + getFanEnergySaving(unit, motorEfficiency),
    0
  );
};

export const getTotalFanEnergySaving = (buildings, motorEfficiency) => {
  return buildings?.reduce(
    (total, building) =>
      total + getFanEnergySavingByBuilding(building, motorEfficiency),
    0
  );
};
