import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  BrandBand,
  PageHeaderControl,
  PageHeader,
  Input,
  Button,
  ButtonGroup,
  Icon,
  IconSettings,
} from "@salesforce/design-system-react";
import { useQuery } from "@apollo/client";
import { CompanyForm } from "./ModalAddCompanyForm";
import { Link } from "react-router-dom";
import { ModalContext } from "../ModalContext";
import { useSelector } from "react-redux";
import COMPANIES, { COMPANY_USERS } from "../../graphql/queries/companies";
import Moment from "react-moment";
import { useTable, usePagination } from "react-table";
import _ from "lodash";
import { UserSettingsContext } from "../../contexts";

const sortable = [
  "id",
  "company_name",
  "created_at",
  "website",
  "created_by",
  "country",
];

export function CompaniesList(props) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: (rowData, i) => (
          <Link to={`/companies/${rowData?.id}`}>{rowData?.company_name}</Link>
        ),
        id: "company_name",
      },
      {
        Header: "Created by",
        accessor: (rowData, i) =>
          rowData?.created_by?.first_name
            ? `${rowData?.created_by?.first_name} ${rowData?.created_by?.last_name}`
            : rowData?.created_by?.user_name,
        id: "created_by",
      },
      {
        Header: "Country",
        accessor: (rowData, i) => (
          <p style={{ textTransform: "capitalize" }}>{rowData?.country}</p>
        ),
        id: "country",
      },
      {
        Header: "Locations",
        id: "locations",
        accessor: (rowData, i) => rowData?.locations?.length,
      },
      {
        Header: "Equipment",
        id: "equipments",
        accessor: (rowData, i) =>
          rowData.locations.reduce((total, loc) => {
            return (total = total + loc?.equipments?.length);
          }, 0),
      },
      {
        Header: "Contacts",
        id: "contacts",
        accessor: (rowData, i) => rowData?.contacts?.length,
      },
      {
        Header: "Opportunities",
        id: "opportunities",
        accessor: (rowData, i) =>
          rowData?.locations?.reduce((total, loc) => {
            loc.projects.map((proj) => {
              if (proj.is_opportunity) {
                total = total + 1;
              }
            });
            return total;
          }, 0),
      },
      {
        Header: "Projects",
        id: "projects",
        accessor: (rowData, i) =>
          rowData.locations.reduce((total, loc) => {
            loc.projects.map((proj) => {
              if (!proj.is_opportunity) {
                total = total + 1;
              }
            });
            return total;
          }, 0),
      },
      {
        Header: "Create At",
        id: "created_at",
        accessor: (rowData, i) => (
          <Moment format="DD-MMM-YYYY">{rowData.created_at}</Moment>
        ),
      },
      {
        Header: "Website",
        accessor: "website",
      },
    ],
    []
  );

  const [items, setItems] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ by: "created_at", type: "desc" });
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);

  const userSettings = useContext(UserSettingsContext);

  const [filterByCountry, setCountryFilter] = useState(
    userSettings?.filters?.country || "ALL"
  );
  const [filterByUser, setUserFilter] = useState(
    userSettings?.filters?.created_by || 0
  );

  const { handleModal } = React.useContext(ModalContext);
  const user = useSelector((state) => state.user);

  const { loading, error, data, fetchMore, refetch } = useQuery(COMPANIES, {
    variables: {
      skip,
      limit: limit || 20,
      where: {},
      orderBy: { by: "created_at", type: "desc" },
    },
  });

  const { data: allUsersData, refetch: refetchUsers } = useQuery(COMPANY_USERS);

  const itemsData = useMemo(() => items.map((item) => ({ ...item })), [items]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: itemsData,
      initialState: { pageIndex: skip, pageSize: limit },
      manualPagination: true,
      pageCount: total ? Math.ceil(total / limit) : 0,
    },
    usePagination
  );

  useEffect(() => {
    if (data) {
      setItems(data?.companiesBy?.companies);
      setSkip(data?.companiesBy?.pagination?.skip);
      setTotal(data?.companiesBy?.pagination?.total);
      setLimit(data?.companiesBy?.pagination?.limit);
    }
  }, []);

  const triggerModal = () =>
    handleModal(
      <CompanyForm
        auth={user}
        doRefresh={setRefresh}
        refresh={refresh}
        refetch={refetch}
        refetchUsers={refetchUsers}
      />,
      "Add Company"
    );
  const filterTable = (input) => setSearch(input);

  const actions = () => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button
            variant="brand"
            label="Add Company"
            onClick={() => triggerModal()}
          />
        </ButtonGroup>
      </ButtonGroup>
    </PageHeaderControl>
  );

  const controls = () => (
    <React.Fragment>
      <PageHeaderControl>
        <Input
          placeholder="Search companies"
          type="text"
          onInput={(e) => filterTable(e.target.value)}
        />
      </PageHeaderControl>
    </React.Fragment>
  );

  const clickHandler = (column) => {
    let type, by;

    if (sortable.indexOf(column) > -1) {
      if (!sort || (sort && sort.by === "id") || sort.by !== column) {
        type = "desc";
        by = column;
      } else if (sort.type === "desc") {
        type = "asc";
        by = column;
      } else {
        type = "desc";
        by = "id";
      }

      setSort({ by, type });
    }
  };

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const variables = {
      where: {
        company_name: search,
        country: filterByCountry,
        created_by: Number(filterByUser),
      },
      skip: pageIndex * pageSize,
      limit: pageSize,
      orderBy: sort,
    };

    if (!search || search === "") {
      delete variables.where.company_name;
    }

    if (variables.where.country === "ALL") {
      delete variables.where.country;
    } else {
      variables.skip = 0;
    }

    if (variables.where.created_by === 0) {
      delete variables.where.created_by;
    } else {
      variables.skip = 0;
    }

    if (!sort) {
      delete variables.orderBy;
    }

    fetchMore({
      variables,
    }).then((res) => {
      setItems(res?.data.companiesBy.companies);
      setSkip(res?.data?.companiesBy?.pagination?.skip);
      setTotal(res?.data?.companiesBy?.pagination?.total);
      setLimit(res?.data?.companiesBy?.pagination?.limit);
    });
  }, [
    pageIndex,
    pageSize,
    search,
    sort,
    filterByCountry,
    filterByUser,
    refresh,
  ]);

  const users = allUsersData?.companies?.map((co) => co.created_by);
  const createdByData = new Set(users);
  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          icon={
            <Icon
              assistiveText={{ label: "User" }}
              category="standard"
              name="opportunity"
            />
          }
          label="COMPANY"
          title={`Companies`}
          variant="object-home"
          info={`${total} companies`}
          onRenderActions={actions}
          onRenderControls={controls}
        />

        <div
          style={{
            backgroundColor: "#fff",
          }}
        >
          <table {...getTableProps()} className="bba-data-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        style={
                          sortable.indexOf(column.id) > -1
                            ? { cursor: "pointer" }
                            : {}
                        }
                      >
                        <div onClick={() => clickHandler(column.id)}>
                          <span>{column.render("Header")}</span>
                          {sort && (
                            <span>
                              {sort.by === column.id ? (
                                sort.type === "desc" ? (
                                  <img
                                    src="icons/custom/chevron-down.svg"
                                    width="16"
                                  />
                                ) : sort.type === "asc" ? (
                                  <img
                                    src="icons/custom/chevron-up.svg"
                                    width="16"
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          )}
                        </div>

                        {column.id === "created_by" && (
                          <div style={{ marginTop: "4px" }}>
                            <select
                              name="filter-created-by"
                              value={filterByUser}
                              onChange={({ target }) => {
                                setUserFilter(target.value);
                                return userSettings?.updateCookie({
                                  ...userSettings,
                                  filters: {
                                    ...userSettings?.filters,
                                    created_by: Number(target.value),
                                  },
                                });
                              }}
                            >
                              <option value={0}>all</option>
                              {Array.from(createdByData)?.map((user) => (
                                <option key={user?.id} value={user?.id}>
                                  {user?.first_name
                                    ? `${user?.first_name} ${user?.last_name}`
                                    : user?.user_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        {column.id === "country" && (
                          <div style={{ marginTop: "4px" }}>
                            <select
                              value={filterByCountry}
                              onChange={({ target }) => {
                                setCountryFilter(target.value);
                                return userSettings?.updateCookie({
                                  ...userSettings,
                                  filters: {
                                    ...userSettings?.filters,
                                    country: target.value,
                                  },
                                });
                              }}
                            >
                              <option value={"ALL"}>all</option>
                              <option value="united states">
                                United States
                              </option>
                              <option value="australia">Australia</option>
                            </select>
                          </div>
                        )}
                      </th>
                    ))
                  }
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page?.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000">Loading...</td>
                ) : (
                  <td colSpan="10000">
                    Showing {page.length} of ~{total} results
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {Math.ceil(total / pageSize)}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              // value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option
                  key={size}
                  value={size}
                  selected={pageSize === size ? "selected" : null}
                >
                  Show {size}
                </option>
              ))}
            </select>
          </div>
        </div>
      </BrandBand>
    </IconSettings>
  );
}
