import { gql } from "@apollo/client";

const ADD_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
      first_name
      last_name
      user_name
      email
      department
      hiring_date
      date_of_birth
      status
      phone_number
      address
      gender
      city
      state
      postal_code
    }
  }
`;

export default ADD_USER;