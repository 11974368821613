import { gql } from '@apollo/client';

const USERS = gql`
  query GetUsers($where: UserFilter, $skip: Int, $limit: Int, $orderBy: Sort) {
    usersBy(where: $where, skip: $skip, limit: $limit, orderBy: $orderBy) {
      users {
        id
        first_name
        last_name
        user_name
        email
        department
        hiring_date
        date_of_birth
        status
        phone_number
        address
        role
        gender
        city
        state
        country
        postal_code
      }
      pagination {
        total
        skip
        limit
      }
    }
  }
`;

export default USERS;
