import { gql } from "@apollo/client";

const DELETE_EQUIPMENT = gql`
  mutation DeleteEquipment($id: ID!) {
    deleteEquipment(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_EQUIPMENT;
