import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation UpdateUser($where: UserWhere!,$data: UserUpdate!) {
    updateUser(where: $where, data: $data) {
      id
    }
  }
`;

export default UPDATE_USER;