import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { Input } from "@salesforce/design-system-react";
import { CompanyCombobox } from "../../features/CompanyCombobox";
import { Auth } from "aws-amplify";

const ADD_CONTACT = gql`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      contact_name
      phone_number
      email
      company_title
    }
  }
`;

const USER_QUERY = gql`
query User($id: Int!){
  user(id: $id) {
    id
    companies {
      id
      company_name
      locations {
        id
        location_name
      }
    }
  }
}
`
const COMPANIES_QUERY = gql`
query Companies {
  companies {
      id
      company_name
      locations {
        id
        location_name
      }
    }
}
`

export function AddContactForm(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [addContact] = useMutation(ADD_CONTACT);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(props.id || '');
  const [userId, setUserId] = useState(1)

  const { data: companiesData, loading, error } = useQuery(COMPANIES_QUERY)
  const { data: userData, loading: userLoading } = useQuery(USER_QUERY, {
    variables: {
      id: userId
    }
  })
  
  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser()
      const id = user?.attributes['custom:userId'] ? Number(user.attributes['custom:userId']) : 1
      setUserId(id)
    }
  )()
  }, []);

  useEffect(() => {
    setCompanies(props?.companies || []);
  }, [props])

  const { register, handleSubmit, control } = useForm();

  const select = (item) => setCompany(item);
  const onSubmit = (data) => {
  
    data.company_id = parseInt(company.id);
    data.locations = [Number(props.location)];
    addContact({ variables: { input: data } }).then(() => props.refetch())
    handleModal();
    
  };

  if(loading || userLoading) return <div>Loading ...</div>
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <label htmlFor="firstName">
            Full name
            <Input name="contact_name" type="text" ref={register} />
          </label>
        }
        control={control}
        name="contact_name"
        defaultValue={""}
      />
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="emailAddress">
              Email Address
              <Input name="email" type="email" ref={register} />
            </label>
          }
          className="slds-col slds-size_1-of-2"
          control={control}
          name="email"
          defaultValue={""}
        />
        <br />
        <Controller
          as={
            <label htmlFor="phone_number">
              Phone Number
              <Input name="phone_number" type="tel" ref={register} />
            </label>
          }
          className="slds-col slds-size_1-of-2"
          control={control}
          name="phone_number"
          defaultValue={""}
        />
      </section>
      <br />
      {(companies.length > 0 || companiesData?.companies?.length > 0 || userData?.user?.companies?.length > 0) &&
        <label htmlFor="combobox">
          Company
          <CompanyCombobox options={userData?.user?.companies || companiesData?.companies || companies} select={select} />
        </label>
      }
      <br />
      <Controller
        as={
          <label htmlFor="companyTitle">
            Title
            <Input name="company_title" type="text" ref={register} />
          </label>
        }
        control={control}
        name="company_title"
        defaultValue={""}
      />
      <br />
      <Input type="submit" value="Save" />
    </form>
  );
}
