import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Input,
  Combobox,
  Textarea,
  Button,
  comboboxFilterAndLimit,
} from "@salesforce/design-system-react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { Auth } from "aws-amplify";
import {
  getCalculatedPrice,
  getUnitSize,
  getCfm,
} from "../../utilities/functions";

const HelperText = ({ children }) => (
  <div style={{ fontSize: "10px", fontWeight: 600 }}>{children}</div>
);

const ErrorText = ({ children }) => (
  <div style={{ fontSize: "10px", fontWeight: 600, color: "red" }}>
    {children}
  </div>
);

const EQUIPMENT_TYPES_QUERY = gql`
  query equipmentTypesQuery {
    equipmentTypes {
      id
      type
    }
  }
`;

const ADD_EQUIPMENT = gql`
  mutation addEquipment($input: EquipmentInput!) {
    createEquipment(input: $input) {
      id
    }
  }
`;

const UPDATE_EQUIPMENT = gql`
  mutation updateEquipment($where: EquipmentWhere!, $data: EquipmentUpdate!) {
    updateEquipment(where: $where, data: $data) {
      id
    }
  }
`;

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput!) {
    createNote(input: $input) {
      id
    }
  }
`;

export default function AddEquipment(props) {
  const { register, handleSubmit, control, errors } = useForm();
  const [addEquipment] = useMutation(ADD_EQUIPMENT);
  const [updateEquipment] = useMutation(UPDATE_EQUIPMENT);
  const [addNote] = useMutation(ADD_NOTE);
  const [cfm, setCfm] = useState(props?.equipment?.cfm || null);
  const [lps, setLps] = useState(props?.equipment?.lps || null);
  const [inputValue, setInputValue] = useState("");

  const [unitType, setUnitType] = useState(
    props?.equipment?.equipment_type
      ? [
          {
            id: props?.equipment?.equipment_type?.id,
            label: props?.equipment?.equipment_type?.type,
          },
        ]
      : []
  );

  const [submitted, setSubmitted] = useState(false);

  const saveEquipment =
    props?.action === "update" ? updateEquipment : addEquipment;

  const { data, loading, error } = useQuery(EQUIPMENT_TYPES_QUERY);
  const { handleModal } = React.useContext(ModalContext);

  if (loading) return <div> Loading...</div>;
  if (error) return <div> Error!! </div>;

  return (
    <form
      onSubmit={handleSubmit((dd, e) => {
        setSubmitted(true);
        const { note, size, calculated_price, ...d } = dd;

        const input = {
          ...d,
          cfm: Number(d.cfm) || null,
          lps: Number(d.lps) || null,
          project_id: Number(props?.projectId) || null,
          location_id: Number(props?.locationId) || null,
          equipment_type_id: Number(unitType[0]?.id),
        };

        if (input.location_id === null) delete input.location_id;
        if (input.project_id === null) delete input.project_id;

        const variables =
          props?.action === "update"
            ? { data: input, where: { id: Number(props?.equipment?.id) } }
            : { input };

        if (variables?.input?.cfm == null) delete variables?.input?.cfm;
        if (variables?.input?.lps == null) delete variables?.input?.lps;

        saveEquipment({
          variables,
        }).then((res) => {
          if (note) {
            Auth.currentAuthenticatedUser().then((user) => {
              addNote({
                variables: {
                  input: {
                    message: note,
                    created_by: Number(user?.attributes["custom:userId"]) || 1,
                    equipment: Number(
                      res.data[
                        props?.action === "update"
                          ? "updateEquipment"
                          : "createEquipment"
                      ].id
                    ),
                  },
                },
              }).then(() => {
                handleModal();
                return props?.refetch();
              });
            });
          } else {
            handleModal();
            return props?.refetch();
          }
        });
      })}
    >
      <Controller
        as={
          <label htmlFor="name">
            Unit Name *<HelperText>Name of unit (eg. AHU01)</HelperText>
            <Input
              name="name"
              type="text"
              ref={register}
              defaultValue={props?.equipment?.name || ""}
            />
          </label>
        }
        control={control}
        name="name"
        defaultValue={props?.equipment?.name || ""}
        rules={{ required: true }}
      />
      {errors?.name?.type === "required" && (
        <ErrorText>This field is required.</ErrorText>
      )}
      <div style={{ height: "8px" }} />
      <Controller
        as={
          <label htmlFor="building">
            Building Name
            <Input
              name="building"
              type="text"
              ref={register}
              defaultValue={props?.equipment?.building || ""}
            />
          </label>
        }
        control={control}
        name="building"
        defaultValue={props?.equipment?.building || ""}
      />
      <div style={{ height: "8px" }} />
      <Controller
        as={
          <label htmlFor="location_on_site">
            Location On Site
            <HelperText>
              where the equipment is located (eg. Level 2 or roof)
            </HelperText>
            <Input
              name="location_on_site"
              type="text"
              ref={register}
              defaultValue={props?.equipment?.location_on_site || ""}
            />
          </label>
        }
        control={control}
        name="location_on_site"
        defaultValue={props?.equipment?.location_on_site || ""}
      />

      <div style={{ height: "8px" }} />

      <Controller
        as={
          <label htmlFor="equipment_type_id">
            <Combobox
              id="equipment_types"
              variant="inline-listbox"
              name="combobox"
              events={{
                onChange: (event, { value }) => {
                  setInputValue(value);
                  if (value.startsWith("a") && value.length === 1) {
                    setUnitType(
                      data.equipmentTypes
                        .map((x) => ({
                          id: x.id,
                          label: x.type,
                        }))
                        .filter((x) => x.label.startsWith("A"))
                    );
                  } else if (value.startsWith("c") && value.length === 1) {
                    setUnitType(
                      data.equipmentTypes
                        .map((x) => ({
                          id: x.id,
                          label: x.type,
                        }))
                        .filter((x) => x.label.startsWith("C"))
                    );
                  } else if (value.startsWith("o") && value.length === 1) {
                    setUnitType(
                      data.equipmentTypes
                        .map((x) => ({
                          id: x.id,
                          label: x.type,
                        }))
                        .filter((x) => x.label.startsWith("O"))
                    );
                  } else if (value.startsWith("a") && value.length === 1) {
                    setUnitType(
                      data.equipmentTypes
                        .map((x) => ({
                          id: x.id,
                          label: x.type,
                        }))
                        .filter((x) => x.label.startsWith("A"))
                    );
                  }
                },
                onRequestRemoveSelectedOption: (event, data) => {
                  setUnitType(data.selection);
                },
                onSelect: (event, data) => {
                  setUnitType(data.selection);
                },
              }}
              labels={{
                label: "Unit Type *",
                placeholder: "select unit type",
              }}
              // options={data.equipmentTypes.map((x) => ({
              //   id: x.id,
              //   label: x.type,
              // }))}
              options={comboboxFilterAndLimit({
                inputValue,
                selection: unitType,
                options: data.equipmentTypes.map((x) => ({
                  id: x.id,
                  label: x.type,
                })),
              })}
              selection={unitType}
            />
          </label>
        }
        control={control}
        name="equipment_type_id"
      />
      {unitType.length === 0 && submitted && (
        <ErrorText>This field is required.</ErrorText>
      )}
      <div style={{ height: "8px" }} />

      <Controller
        as={
          <label htmlFor={props?.country === "australia" ? "lps" : "cfm"}>
            Volumetric Flow Size
            {props?.country === "australia" ? " (LPS)" : " (CFM)"}
            <HelperText>
              size in {props?.country === "australia" ? " LPS " : " CFM "}
              (eg. 10,000)
            </HelperText>
            <Input
              name={props?.country === "australia" ? "lps" : "cfm"}
              id={props?.country === "australia" ? "lps" : "cfm"}
              type="number"
              ref={register}
              value={props?.country === "australia" ? lps : cfm}
              onChange={({ target }) =>
                props?.country === "australia"
                  ? setLps(target.value)
                  : setCfm(target.value)
              }
            />
          </label>
        }
        control={control}
        name={props?.country === "australia" ? "lps" : "cfm"}
        defaultValue={props?.country === "australia" ? lps : cfm}
      />
      <div style={{ height: "8px" }} />

      <Controller
        as={
          <label htmlFor="size">
            Unit Size
            <HelperText>calculated based on CFM/LPS value</HelperText>
            <Input
              name="size"
              value={
                props?.country === "australia"
                  ? getUnitSize(getCfm(lps))
                  : getUnitSize(cfm)
              }
              type="text"
              ref={register}
              disabled
            />
          </label>
        }
        control={control}
        name="size"
        defaultValue={""}
      />
      <div style={{ height: "8px" }} />

      <Controller
        as={
          <label htmlFor="calculated_price">
            Calculated Price
            <HelperText>calculated based on CFM/LPS value</HelperText>
            <Input
              name="size"
              value={
                props?.country === "australia"
                  ? getCalculatedPrice(getCfm(lps))
                  : getCalculatedPrice(cfm)
              }
              type="text"
              ref={register}
              disabled
            />
          </label>
        }
        control={control}
        name="calculated_price"
        defaultValue={""}
      />
      <div style={{ height: "8px" }} />

      <Controller
        as={
          <label htmlFor="note">
            Note
            <Textarea name="note" ref={register}></Textarea>
          </label>
        }
        control={control}
        name="note"
        defaultValue={""}
      />

      <div style={{ height: "16px" }} />
      <div style={{ width: "100%", display: "flex" }}>
        <Button
          type="submit"
          variant="brand"
          label="Save"
          style={{ flex: 1 }}
        />
      </div>
    </form>
  );
}
