import { gql } from '@apollo/client';

const CREATE_NOTE = gql`
  mutation CreateNote($data: NoteInput!) {
    createNote(input: $data) {
      id
    }
  }
`;

export default CREATE_NOTE;