import React from "react";
import { Page, View, Text, Image, StyleSheet } from "@react-pdf/renderer";
import { BackPageFooter } from "./BackPageFooter";
import { ProposalPageHeader } from "./ProposalPageHeader";
import water from "./images/WATER.png";
import electricity from "./images/ELECTRICITY.png";
import escort from "./images/ESCORT.png";
import support from "./images/TECH_SUPPORT.png";
import moment from "moment";

export function BackPage({ data, pageSize }) {
  return (
    <Page size={pageSize}>
      <ProposalPageHeader
        project={data}
        date={moment(data.start_date).format("DD-MMM-YYYY")}
      />
      <View
        style={{
          flexDirection: "row",
          paddingHorizontal: 12,
          flex: 1,
        }}
      >
        <View style={styles.left}>
          <View style={styles.content_container}>
            <Text style={styles.title}>ERRORS, OMISSIONS, ETC</Text>
            <Text style={styles.content}>
              To preserve the life of the metal fins and coils, we use pH
              neutral enzymes and do not scrub or power wash the face of the
              coils. We allow the enzymes to self rinse after an enzyme
              treatment. Blue Box will not deliver a shiny or polished
              appearance to the fins and coils. If you require a cosmetically
              enhanced appearance of the coil face, we can brush the coils with
              heavy foam for an extra cost at the client’s requests but we are
              not responsible for any damage that occurs due to brushing
            </Text>
            <Text style={styles.content}>
              If proper filtration is not in place, the coils can get dusty and
              appear dirty after a cleaning. Blue Box is not responsible for
              re-cleaning an AHU if there is air bypass due to improper
              filtration.
            </Text>
            <Text style={styles.content}>
              Blue Box maintains standard commercial insurance coverage for
              projects of this type. It is not responsible, nor liable for the
              existing condition of Client's HVAC systems. Blue Box is low
              pressure, pH neutral, non-odorous and non-reactive to metal
              surfaces. If the client has systems that are old, have a tendency
              to leak, and/or are very delicate, it is the responsibility of the
              client to notify Blue Box of its concerns prior to the execution
              of a project, to which Blue Box’s Technicians will give their best
              efforts to clean the target system. By contracting with Blue Box
              to service its AHU’s, the client fully indemnifies Blue Box from
              any liability associated with the client’s current condition of
              its industrial equipment.
            </Text>
            <Text style={styles.content}>
              Blue Box Air, LLC can not guarantee Differential Pressure (DP)
              drops due to the many variables. It is helpful if on site
              engineers can guarantee all variables are constant on “pre” and
              “post” pressure readings, such as damper position, VAV boxes
              dampers open, Hertz set at the same #, confirm weather temperature
              is the same & humidity levels. Design of AHU can all affect a DP
              reading.
            </Text>
            <Text style={styles.content}>
              Measurement of success is the penetration of the coil by our
              enzymes through the backside of the coil. If we can not penetrate
              a portion of your coil, we will share that with you and recommend
              a follow up treatment at a discounted price if the unit is
              extremely fouled or plugged. Plugged AHUs occur after years of
              power washing from both sides of the coils while the unit was off.
              It pushes the dirt, grime, and debris to the middle of the coil
              and impacts it, restricting airflow and cooling.
            </Text>
          </View>
        </View>
        <View style={styles.right}>
          <View style={[styles.content_container, { paddingLeft: 4 }]}>
            <Text style={styles.title}>Facilities Support</Text>

            <View style={{ paddingHorizontal: 20 }}>
              <View style={styles.feature}>
                <Image style={styles.feature_image} src={water} />
                <Text style={styles.feature_title}>Water</Text>
                <Text style={styles.feature_content}>
                  Will require access to a clean water source.
                </Text>
              </View>
              <View style={styles.feature}>
                <Image style={styles.feature_image} src={electricity} />
                <Text style={styles.feature_title}>Electricity</Text>
                <Text style={styles.feature_content}>
                  Will require 110V power outlet access to plug in our low
                  decibel compressor and blue box.
                </Text>
              </View>
              <View style={styles.feature}>
                <Image style={styles.feature_image} src={escort} />
                <Text style={styles.feature_title}>Escort</Text>
                <Text style={styles.feature_content}>
                  Will require escorts throughout the property to ensure Blue
                  Box teams can quickly move from one AHU to another after
                  completion of enzyme treatment. We require the cooperation
                  with onsite engineers or facility employees during treatments.
                </Text>
              </View>
              <View style={styles.feature}>
                <Image style={styles.feature_image} src={support} />
                <Text style={styles.feature_title}>Tech Support</Text>
                <Text style={styles.feature_content}>
                  If client choses, Blue Box Technicians can collaborate and
                  work jointly with clients onsite operations technicians on
                  executing the AHU cleanings. Training and collaboration must
                  be established prior to the start of a project.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <BackPageFooter />
    </Page>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica Neue",
    position: "relative",
    flexDirection: "column",
  },
  feature: {
    textAlign: "center",
    marginBottom: 20,
  },
  feature_title: {
    textTransform: "uppercase",
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    color: "#454545",
  },
  feature_content: {
    textAlign: "center",
    fontSize: 10,
    color: "#4c4c4c",
    fontFamily: "Helvetica Neue",
  },
  feature_image: {
    height: "auto",
    width: 34,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 10,
  },
  title: {
    textTransform: "uppercase",
    color: "#189DD8",
    margin: "auto",
    marginBottom: 8,
    fontSize: 20,
  },
  list_item: {
    marginBottom: 12,
  },
  project_description: {
    flexDirection: "row",
    marginLeft: 60,
    marginRight: 60,
    fontSize: 14,
  },
  project_left: {
    flex: 1,
  },
  project_right: {
    flex: 1,
    marginTop: 30,
  },
  content_title: {
    textTransform: "uppercase",
    fontSize: 24,
    color: "#189DD8",
    marginTop: 30,
    marginBottom: 10,
  },
  content: {
    fontSize: 10,
    lineHeight: 1.2,
    marginBottom: 20,
    color: "#4c4c4c",
    fontWeight: "light",
  },
  content_container: {
    flexDirection: "column",
    paddingRight: 20,
    fontFamily: "Helvetica Neue",
    marginVertical: 40,
  },
  left: {
    flex: 1,
    width: "50%",
    display: "flex",
    alignItems: "center",
  },
  right: {
    flex: 1,
    width: "50%",
    backgroundColor: "#f2f2f2",
    display: "flex",
    alignItems: "center",
  },
});
