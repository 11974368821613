import { gql } from '@apollo/client'

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_PROJECT;
