import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input, Datepicker } from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import { useMutation } from "@apollo/client";
import UPDATE_USER from "../../graphql/mutations/updateUser";
import DELETE_USER from "../../graphql/mutations/deleteUser";
import moment from "moment";
import { countries } from "../../utilities/states-list";
import ConfirmDelete from "../modals/ConfirmDelete";

const roles = ["Technician", "User", "Project Manager"];
const departments = ["Admins", "Sales", "Technical", "Projects Management"];

export function EditUserForm(props) {
  const user = props.user;

  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  useEffect(() => {
    setSelectedStates(countries[user?.country] || []);
  }, [user]);

  const { handleModal } = React.useContext(ModalContext);
  const [updateUser, { data, error }] = useMutation(UPDATE_USER);
  //const [deleteUser] = useMutation(DELETE_USER);
  const { register, handleSubmit, control } = useForm();

  const [hiringDate, setHiringDate] = useState(user.hiring_date);
  const [dob, setDob] = useState(user.date_of_birth);

  const [isOpen, setOpen] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(DELETE_USER);

  useEffect(() => {
    if (props.user) {
      setHiringDate(props.user.hiring_date);
      setDob(props.user.date_of_birth);
    }
  }, [props]);

  const onSubmit = (data) => {
    // transform default form data into format expected by server
    data.date_of_birth = new Date(dob).toISOString();
    data.hiring_date = new Date(hiringDate).toISOString();
    data.role = data?.role?.replace(" ", "_").toUpperCase();
    data.department = data?.department?.replace(" ", "_").toUpperCase();
    updateUser({
      variables: { where: { id: parseInt(props.user.id) }, data: data },
    }).then(() => {
      props.refetch();
      handleModal();
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label htmlFor="first_name">
                First Name
                <Input
                  name="first_name"
                  type="text"
                  defaultValue={user.first_name}
                  ref={register}
                  required
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="first_name"
            defaultValue={user.first_name}
          />
          <Controller
            as={
              <label htmlFor="last_name">
                Last Name
                <Input
                  name="last_name"
                  type="text"
                  defaultValue={user.last_name}
                  ref={register}
                  required
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="last_name"
            defaultValue={user.last_name}
          />
        </section>
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label htmlFor="user_name">
                Username
                <Input
                  name="user_name"
                  type="text"
                  defaultValue={user.user_name}
                  ref={register}
                  required
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="user_name"
            defaultValue={user.user_name}
          />
          <Controller
            as={
              <label htmlFor="date_of_birth">
                Date of Birth
                <Datepicker
                  name={"date_of_birth"}
                  onChange={(event, data) => {
                    setDob(data.date);
                  }}
                  onCalendarFocus={(event, data) => {}}
                  formatter={(date) => {
                    return date ? moment(date).format("DD-MMM-YYYY") : "";
                  }}
                  parser={(dateString) => {
                    return moment(dateString, "MM/DD/YYYY").toDate();
                  }}
                  defaultValue={dob}
                  value={dob}
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="date_of_birth"
            defaultValue={dob}
          />
        </section>
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label htmlFor="phone_number">
                Phone Number
                <Input
                  defaultValue={user.phone_number}
                  name="phone_number"
                  type="tel"
                  ref={register}
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="phone_number"
            defaultValue={user.phone_number}
          />
          <Controller
            as={
              <label htmlFor="email">
                Email
                <Input
                  disabled
                  name="email"
                  type="email"
                  defaultValue={user.email}
                  ref={register}
                  required
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="email"
            defaultValue={user.email}
          />
        </section>
        <Controller
          as={
            <label htmlFor="address">
              Address
              <Input
                name="address"
                defaultValue={user.address}
                type="text"
                ref={register}
              />
            </label>
          }
          control={control}
          name="address"
          defaultValue={user.address}
        />
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label htmlFor="city">
                City
                <Input
                  name="city"
                  defaultValue={user.city}
                  type="text"
                  ref={register}
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="city"
            defaultValue={user.city}
          />
          <Controller
            as={
              <label htmlFor="postal_code">
                Postal Code
                <Input
                  name="postal_code"
                  defaultValue={user.postal_code}
                  type="text"
                  ref={register}
                />
              </label>
            }
            className="slds-col slds-size_1-of-2"
            control={control}
            name="postal_code"
            defaultValue={user.postal_code}
          />
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label htmlFor="country">
                Country
                <br />
                <select
                  name="country"
                  onChange={({ target }) =>
                    setSelectedStates(countries[target.value])
                  }
                >
                  <option
                    value={"united states"}
                    selected={user.country === "united states"}
                  >
                    United states
                  </option>
                  <option
                    value={"australia"}
                    selected={user.country === "australia"}
                  >
                    Australia
                  </option>
                </select>
              </label>
            }
            control={control}
            name="country"
            defaultValue={user.country}
          />

          <Controller
            as={
              <label htmlFor="state">
                State
                <br />
                <select name="state">
                  {selectedStates.map((state) => {
                    return (
                      <option
                        key={state.State}
                        value={state.State}
                        selected={user.state === state.State}
                      >
                        {state.State}
                      </option>
                    );
                  })}
                </select>
              </label>
            }
            control={control}
            name="state"
            defaultValue={user.state}
          />
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <div className="slds-col">
            <Controller
              as={
                <label>
                  Department
                  <select
                    style={{ marginLeft: "18px" }}
                    defaultValue={user.department}
                    name="department"
                    ref={register}
                  >
                    {departments.map((department, i) => {
                      return (
                        <option className="slds-col" key={i} value={department}>
                          {department}
                        </option>
                      );
                    })}
                  </select>
                </label>
              }
              control={control}
              name="department"
              defaultValue={user.department}
            />
          </div>
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <div className="slds-col">
            <Controller
              as={
                <label>
                  Role
                  <select
                    style={{ marginLeft: "18px" }}
                    defaultValue={user.role}
                    name="role"
                    ref={register}
                  >
                    {roles.map((role, i) => {
                      return (
                        <option key={i} value={role}>
                          {role}
                        </option>
                      );
                    })}
                  </select>
                </label>
              }
              control={control}
              name="role"
              defaultValue={user.role}
            />
          </div>
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <div>
                <label>Gender:</label>
                <select
                  style={{ marginLeft: "18px" }}
                  defaultValue={user.gender}
                  name="gender"
                  ref={register}
                >
                  <option key={1} value={"MALE"}>
                    Male
                  </option>
                  <option key={2} value={"FEMALE"}>
                    Female
                  </option>
                </select>
              </div>
            }
            className="slds-col"
            control={control}
            name="gender"
            defaultValue={user.gender}
          />
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <div>
                <label>Status:</label>
                <select
                  style={{ marginLeft: "18px" }}
                  defaultValue={user.status}
                  name="status"
                  ref={register}
                >
                  <option key={1} value={"active"}>
                    Active
                  </option>
                  <option key={2} value={"inactive"}>
                    Inactive
                  </option>
                </select>
              </div>
            }
            className="slds-col"
            control={control}
            name="status"
            defaultValue={user.status}
          />
        </section>
        <br />
        <section className="slds-grid slds-gutters">
          <Controller
            as={
              <label
                htmlFor="hiring_date"
                defaultValue={moment(hiringDate).format("DD-MMM-YYYY")}
              >
                Hiring Date
                <Datepicker
                  name={"hiring_date"}
                  onChange={(event, data) => {
                    setHiringDate(data.date);
                  }}
                  onCalendarFocus={(event, data) => {}}
                  formatter={(date) => {
                    return date ? moment(date).format("DD-MMM-YYYY") : "";
                  }}
                  parser={(dateString) => {
                    return moment(dateString, "MM/DD/YYYY").toDate();
                  }}
                  defaultValue={hiringDate}
                  value={hiringDate}
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="hiring_date"
            defaultValue={hiringDate}
          />
        </section>
        <br />
        <Input type="submit" value="Save" />
      </form>
      {/* <button onClick={() => setOpen(true)}>Remove User</button>
      <ConfirmDelete
        isOpen={isOpen}
        toggleOpen={setOpen}
        action={deleteMutation}
        variables={{ id: Number(user?.id) }}
        loading={deleting}
        url={`/users`}
        message={`${
          user?.first_name
            ? `${user.first_name} ${user.last_name}`
            : user?.user_name
        } and all related Companies, Locations, Projects, Opportunities, Proposals, Equipment and Data will be permanently deleted and you won't be able to recover them.  `}
      /> */}
    </div>
  );
}
