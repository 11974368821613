import { ADD_OPPORTUNITY } from "./opportunityTypes";

const initialState = {
  opportunities: [],
};

const opportunityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_OPPORTUNITY:
      const opportunities = [...state.opportunities, action.payload];
      return {
        ...state,
        opportunities,
      };
    default:
      return state;
  }
};

export default opportunityReducer;
