import React from "react";
import { View, Text, Image, StyleSheet, Link } from "@react-pdf/renderer";
import logo from "./images/logo-sqaure.png";

export function ProposalPageHeader({ project, date }) {
  return (
    <View style={styles.header}>
      <View style={{ flex: 1, alignItems: "flex-start", marginRight: 4 }}>
        <Text
          style={[styles.headerText, { fontWeight: "bold", color: "#454545" }]}
        >
          BLUE BOX AIR™
        </Text>
        <View style={styles.headerLine}></View>
        <Text
          style={[styles.headerText, { fontWeight: "light", color: "#6c6c6c" }]}
        >
          Proposal
        </Text>
      </View>
      <View>
        <Link src="https://blueboxair.com">
          <Image style={styles.logo} src={logo} />
        </Link>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    fontFamily: "Helvetica Neue",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    paddingHorizontal: 10,
    paddingVertical: 12,
    alignContent: "center",
  },
  headerText: {
    fontSize: 10,
    marginBottom: 5,
    textTransform: "uppercase",
  },
  logo: {
    width: 42,
    marginTop: -2,
  },
  headerLine: {
    height: "3px",
    width: "100%",
    backgroundColor: "#189DD8",
    marginBottom: 5,
  },
  superText: {
    fontSize: 8,
    marginTop: -4,
  },
});
