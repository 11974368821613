import { ADD_LOCATION } from "./locationTypes";

const initialState = {
  locations: [],
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LOCATION:
      const locations = [...state.locations, action.payload];

      console.log(locations);
      return {
        ...state,
        locations,
      };
    default:
      return state;
  }
};

export default locationReducer;
