import React from "react";
import moment from "moment";
import { Page, View, Text, StyleSheet, Font } from "@react-pdf/renderer";
// import { ProposalFooter } from "./ProposalFooter";
import { ProposalPageHeader } from "./ProposalPageHeader";

export function InfoPage({ data, pageNumber, pageSize }) {
  return (
    <Page size={pageSize}>
      <ProposalPageHeader
        project={data}
        date={moment(data.start_date).format("DD-MMM-YYYY")}
      />
      <View style={styles.content_container}>
        <Text style={styles.title}>Service Summary</Text>
        <Text style={styles.content}>
          Blue Box will service Air Handler Units (AHU) utilizing its
          proprietary coil cleaning process. The coil cleaning process will
          involve a deep enzyme treatment and cleaning of the AHU’s cooling
          coils utilizing Blue Box’s BioFoam formulation for breaking down
          biofilms as well as chalk grease, dirt, and grime that may be found
          deep inside the coils. The objective of the enzyme treatment is to
          restore the AHU’s air flow, heat transfer, improve the overall
          efficiency of the facilities HVAC system, and reduce energy operating
          expenses. Blue Box will provide all proprietary equipment,
          formulations, and trained technicians to complete this scope of work
          as a full service provider.
        </Text>
      </View>
      <View style={styles.content_container}>
        <Text style={styles.title}>Project Process</Text>
        <Text style={styles.content}>
          <Text>
            Blue Box’s Technical team will execute the following procedure for
            each unit we service
          </Text>
        </Text>
        <View style={styles.content}>
          <View style={{ paddingHorizontal: 20, paddingTop: 12 }}>
            <Text style={styles.list_item}>
              1. If possible, take pressure drop readings with a manometers
              before treatment if AHU has pre-drilled holes for manometer tubes{" "}
            </Text>
            <Text style={styles.list_item}>
              2. Begin enzyme cleaning AHUs according to the following time
              estimates below{" "}
            </Text>
            <Text style={styles.list_item}>
              3. Complete cleaning procedure, and then remove any excess
              materials as well as equipment. Take post reads if possible 48 hrs
              to 1-2 weeks post treatment
            </Text>
          </View>
        </View>
        <View style={{ flexDirection: "row", justifyContent: "center" }}>
          <View style={{ fontSize: 11, color: "#454545", fontWeight: "light" }}>
            <Text style={styles.list_item}>
              {"SMALL AHU - (1-10,000 CFM) > 2 hour maximum"}
            </Text>
            <Text style={styles.list_item}>
              {"MEDIUM AHU - (10,001-25,000 CFM) > 3 hour maximum"}
            </Text>
            <Text style={styles.list_item}>
              {"LARGE AHU - (25,001-50,000 CFM) > 4 hour maximum"}
            </Text>
            <Text style={styles.list_item}>
              {"EXTRA LARGE AHU - (50,000-75,000 CFM) > 5 hour maximum"}
            </Text>
            <Text style={styles.list_item}>
              {"II EXTRA LARGE AHU - (75,001-100,000 CFM) > 6 hour maximum"}
            </Text>
            <Text style={styles.list_item}>
              {"FAN COILS - 45 minutes maximum"}
            </Text>
          </View>
        </View>
      </View>
      <View
        style={[
          styles.content_container,
          { borderBottomWidth: 0.6, paddingBottom: 29.5 },
        ]}
      >
        <Text style={styles.title}>Project Completion</Text>
        <Text style={styles.content}>
          Blue Box guarantees that each AHU will receive a complete and thorough
          deep enzyme clean. This includes the guarantee that Blue Box will be
          able to penetrate enzymes through the coils regardless of depth size
          of air gaps between double coils. Due to the design, configuration,
          and operational nature of coils, Blue Box does not guarantee specific
          range pressure drops or the extent the internal surfaces are cleaned
          due to the inability to have direct access deep inside the HVAC
          systems coils. Each AHU is assigned a maximum clean time based on its
          physical size. The total amount of time a Blue Box Technician spends
          on an individual AHU will depend on several factors, such as how the
          enzyme is flowing through the coils, and other visual cues. It is not
          uncommon for a system to be determined satisfactorily cleaned that is
          under the “maximum” enzyme time allowed. Blue Box will record its
          total “enzyme time” for each project. In the unlikely event that the
          client is not satisfied with cleaning of a AHU, Blue Box will review
          the clean time executed. If it is found that, for example, a
          technician spent 2 hours enzyme cleaning a large AHU, but then stopped
          because he/she felt the system was satisfactorily cleaned, Blue Box
          will complete any remaining time at the clients request, until the
          maximum cleaning time has been met, at which point the project will be
          deemed to be fully completed.
        </Text>
      </View>
      <Text style={styles.pageNumber}>{pageNumber}</Text>
    </Page>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica Neue",
    position: "relative",
    flexDirection: "column",
  },
  logo: {
    width: 64,
    height: 64,
  },
  title: {
    textTransform: "uppercase",
    color: "#189DD8",
    marginBottom: 8,
    fontSize: 18,
  },
  list_item: {
    marginBottom: 8,
    color: "#6c6c6c",
    fontFamily: "Helvetica Neue",
    fontWeight: "light",
  },
  project_description: {
    flexDirection: "row",
    marginLeft: 60,
    marginRight: 60,
    fontSize: 14,
  },
  project_left: {
    flex: 1,
  },
  project_right: {
    flex: 1,
    marginTop: 30,
  },
  content_title: {
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: 24,
    color: "#189DD8",
    marginTop: 30,
    marginBottom: 10,
  },
  content: {
    fontSize: 11,
    lineHeight: 1.5,
    color: "#6c6c6c",
    fontFamily: "Helvetica Neue",
    fontWeight: "light",
  },
  content_container: {
    flexDirection: "column",
    marginHorizontal: 12,
    marginBottom: 20,
  },
  pageNumber: {
    position: "absolute",
    right: 15,
    bottom: 15,
    fontSize: 10,
    backgroundColor: "#ffffff",
    color: "#6c6c6c",
  },
});
