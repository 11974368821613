import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  DataTable,
  DataTableColumn

} from "@salesforce/design-system-react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import _ from 'lodash'
// Get company locations from the companyId
const LOCATIONS = gql`
  query companyById($id: ID!, $contactId: Int!) {
    company(id: $id) {
      id
      company_name
      locations {
        id
        location_name
        city
        state
      }
    },
    locationContactByContactId(contact_id: $contactId) {
      id
      location {
          id
          location_name
          city
          state
      }
    }
  }
`
const AddLocationContact = gql`
  mutation AddLocationContact($input: LocationContactInput!){
    createLocationContact(input: $input),{
      id
    }
  }
`;

const DeleteLocationContact = gql`
  mutation DeleteLocationContact($input: LocationContactWhere!){
    deleteLocationContact(where: $input),{
      id
    }
  }
`;

export function AddContactToLocation(props) {
  const { handleModal } = React.useContext(ModalContext);
  const {loading, error, data } = useQuery(LOCATIONS, {
    variables: {id: props.id, contactId: parseInt(props.contact) }
  })

  const { register, handleSubmit, reset, watch, control, setValue } = useForm();
  const [items, setItems] = useState([])
  const [selection, setSelection] = useState([]);
  const [original, setOriginal] = useState([]);
  const [remove, setRemove] = useState([]);
  const [addContact] = useMutation(AddLocationContact);
  const [deleteContact] = useMutation(DeleteLocationContact);
  
  const handleSelection = (e, data) => {
    let diff = _.differenceBy(original, data.selection, 'id')
    if (diff) {
      setRemove(diff);
    }
    setSelection(data.selection);
  };

  useEffect(() => {
    if (data) {
      setItems(data.company.locations)
      setSelection(data.locationContactByContactId)
      
      // Compare current selected locations to other locations
      let arr = []
      data.locationContactByContactId.map(item => {
        let exists = _.find(data.company.locations, function(o) { return o.id == item.location.id; });
        arr.push(exists)
      })

      setSelection(arr)
      setOriginal(arr);
    }
  },[data])
  const onSubmit = async (data) => {
    let arr = [];
    let removeArr = []
    // If locations removed, run delete
    let diff = _.differenceBy(selection, original, 'id')
    if (diff) {
      diff.map(item => {
        if (!original.includes(item)) {
          arr.push({
            location_id: parseInt(item.id),
            contact_id: parseInt(props.contact),
            role: 'Role',
            is_primary: false
          })
        }
      })

      await Promise.all(
        arr.map(
          async (input) =>
            await addContact({
              variables: { input },
            })
        )
      ).then((res) => props?.refetch());
    }

    if (remove) {
      remove.map(item => {
        removeArr.push({
          location_id: parseInt(item.id),
          contact_id: parseInt(props.contact)
        })
      })

      await Promise.all(
        removeArr.map(
          async (input) =>
            await deleteContact({
              variables: { input },
            })
        )
      ).then((res) => props?.refetch());
    }

    


    setOriginal(selection)
    handleModal();
  };

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error :(</p>

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* List of locations associated with the company */}
      <section className="slds-grid slds-gutters">

      <h3>{data.company.company_name} locations</h3>
        <DataTable
          fixedLayout
          items={items}
          selection={selection}
          onRowChange={handleSelection}
          selectRows="checkbox"
        >
          <DataTableColumn label="Id" property="id" />
          <DataTableColumn label="Location name" property="location_name" />
          <DataTableColumn label="City" property="city" />
          <DataTableColumn label="State" property="state" />
        </DataTable>
      </section>
      <Button type="submit">Assign</Button>
    </form>
  );
}
