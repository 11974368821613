import React, { useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";
import {
  PageHeader,
  PageHeaderControl,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  Button,
  ButtonGroup,
  BrandBand,
} from "@salesforce/design-system-react";
import { ProposalTab } from "./ProposalTab";
import { ContactsTab } from "./ContactsTab";
import { JourneyStages } from "./JourneyStages";
import { Link } from "react-router-dom";
import { AddProposal } from "./ModalAddProposal";
import { ModalContext } from "../ModalContext";
import { NotesTab } from "./NotesTab";
import DELETE_PROJECT from "../../graphql/mutations/deleteOpportunity";
import OpportunityForm from "../Forms/OpportunityForm";
import ConfirmDelete from "../modals/ConfirmDelete";

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteOpportunity(where: { id: $id }) {
      id
    }
  }
`;

const SINGLE = gql`
  query OpportunityById($id: ID!) {
    opportunity(id: $id) {
      id
      name
      stage
      total_amount
      created_at
      updated_at
      description
      currency
      created_by {
        id
        user_name
        first_name
        last_name
      }
      notes {
        id
        content
        created_at
        created_by {
          id
          email
        }
      }
      proposals {
        id
        created_at
        proposal_items {
          calculated_price
          id
        }
        is_approved
        discount
        other_costs
        other_costs_label
      }
      location {
        id
        location_name
        state
        company {
          id
          company_name
          country
          contacts {
            contact_name
            phone_number
            email
            is_primary
          }
        }
        equipments {
          id
          cfm
          building
          calculated_price

          name
          location_on_site
          equipment_type {
            id
            type
          }
        }
      }
      contacts {
        id
        contact_name
        company_title
        email
        phone_number
      }

      project {
        id
      }
    }
  }
`;

const UPDATE_PROJECT = gql`
  mutation UpdateOpportunity(
    $id: ID!
    $name: String
    $total_amount: Float
    $description: String
  ) {
    updateProject(
      where: { id: $id }
      data: {
        name: $name
        total_amount: $total_amount
        description: $description
      }
    ) {
      id
    }
  }
`;

export function OpportunityDetail({ match, ...props }) {
  const { handleModal } = React.useContext(ModalContext);
  const history = useHistory();

  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: { id: match.params.opportunityId },
  });

  const [isOpen, setOpen] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(DELETE);

  const [deleteOpportunity] = useMutation(DELETE_PROJECT);

  const triggerModal = () => {
    handleModal(
      <AddProposal
        equipment={opportunity?.location?.equipments}
        opportunityId={opportunity?.id}
        currency={opportunity?.currency}
        refetch={refetch}
      />,
      "Add Proposal"
    );
  };

  const [opportunity, setOpportunity] = useState({});

  useEffect(() => {
    if (data) {
      setOpportunity(data.opportunity);
    }
  }, [data]);

  const actions = () => (
    <React.Fragment>
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button onClick={() => triggerModal()} label="New Proposal" />
          {/* <Button
            iconCategory="utility"
            iconName="delete"
            iconPosition="left"
            label="Delete"
            onClick={() => setOpen(true)}
          /> */}
        </ButtonGroup>
      </PageHeaderControl>
    </React.Fragment>
  );

  const details = [
    {
      label: "Location",
      content: opportunity?.location ? (
        <Link
          to={`/companies/${opportunity?.location.company.id}/locations/${opportunity?.location.id}`}
        >
          {opportunity?.location.location_name}
        </Link>
      ) : (
        "None"
      ),
      truncate: true,
    },
    {
      label: "Company",
      content: opportunity?.location ? (
        <Link to={`/companies/${opportunity?.location?.company.id}`}>
          {opportunity?.location.company.company_name}
        </Link>
      ) : (
        "None"
      ),
      truncate: true,
    },
    {
      label: "State",
      content: opportunity?.location ? (
        <p>{opportunity?.location.state}</p>
      ) : (
        ""
      ),
      truncate: true,
    },
    {
      label: "Created at",
      content: moment(opportunity?.created_at).format("DD-MMM-YYYY"),
    },
    {
      label: "Owned by",
      content: opportunity?.location ? (
        <p>
          {opportunity.created_by.first_name
            ? `${opportunity.created_by.first_name} ${opportunity.created_by.last_name}`
            : opportunity.created_by.user_name}{" "}
          {}
        </p>
      ) : (
        ""
      ),
    },
  ];

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (opportunity)
    return (
      <IconSettings iconPath="/icons">
        <BrandBand
          id="brand-band-lightning-blue"
          className="slds-p-around_small"
          theme="lightning-blue"
        >
          <PageHeader
            details={details}
            icon={
              <Icon
                assistiveText={{ label: "User" }}
                category="standard"
                name="account"
              />
            }
            label="Opportunity"
            onRenderActions={actions}
            title={opportunity.name}
            variant="record-home"
          />

          <JourneyStages opportunity={opportunity} refetch={refetch} />

          <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
            <div className="slds-col slds-col">
              <Tabs id="left" className="slds-var-p-around_small">
                {/* <TabsPanel label="Details">
                  <OpportunityForm
                    project={opportunity}
                    refetch={refetch}
                    isOpportunity={true}
                  />
                </TabsPanel> */}
                <TabsPanel label="Proposals">
                  <ProposalTab
                    proposals={opportunity.proposals}
                    opportunityId={opportunity?.id}
                    currency={
                      opportunity?.location?.company?.country === "australia"
                        ? "AUD"
                        : "USD"
                    }
                    refetch={refetch}
                    equipment={
                      opportunity?.location
                        ? opportunity?.location.equipments
                        : []
                    }
                  />
                </TabsPanel>
                {/* <TabsPanel label="Contacts">
                  <ContactsTab
                    contacts={opportunity.contacts}
                    refetch={refetch}
                    id={Number(match.params.opportunityId)}
                  />
                </TabsPanel> */}
              </Tabs>
            </div>
            {/* <div className="slds-col slds-col slds-size_1-of-3">
              <Tabs id="right" className="slds-var-p-around_small">
                <TabsPanel label="Notes">
                  <NotesTab
                    notes={opportunity.notes}
                    opportunity={opportunity.id}
                    refetch={refetch}
                  />
                </TabsPanel>
              </Tabs>
            </div> */}
          </div>
          {/* <ConfirmDelete
            isOpen={isOpen}
            toggleOpen={setOpen}
            action={deleteMutation}
            variables={{ id: Number(opportunity?.id) }}
            loading={deleting}
            url={`/opportunities`}
            message={`${opportunity?.name} Opportunity and all related Proposals, Equipment and Data will be permanently deleted and you won't be able to recover them. `}
          /> */}
        </BrandBand>
      </IconSettings>
    );
  else return <div></div>;
}
