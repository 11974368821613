import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import {
  PageHeader,
  PageHeaderControl,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  Button,
  ButtonGroup,
  BrandBand,
  Input,
  InputIcon,
} from "@salesforce/design-system-react";
import Moment from "react-moment";
import { ContactsList } from "./ContactsList";
import { LocationsList } from "./LocationsList";
import OpportunitiesList from "./OpportunitiesList";
import { ProjectsList } from "./ProjectsList";
import { AddContactForm } from "../contacts/ModalAddContact";
import OpportunityForm from "../opportunities/ModalAddOpportunity";
import { AddLocation } from "../locations/ModalAddLocation";
import SINGLE from "../../graphql/queries/single";
import { ModalContext } from "../ModalContext";
import { countries } from "../../utilities/states-list";
import ConfirmDelete from "../modals/ConfirmDelete";

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $input: CompanyUpdate!) {
    updateCompany(where: { id: $id }, data: $input) {
      company_name
      country
      city
      state
      website
    }
  }
`;

const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Int!) {
    deleteCompany(where: { id: $id }) {
      id
    }
  }
`;

export function CompanyDetail({ match, props }) {
  const { handleModal } = React.useContext(ModalContext);
  const id = Number(match.params.companyId);

  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: { id: parseInt(id) },
  });

  const [isOpen, setOpen] = useState(false);
  const [deleteCompany, { loading: deleting }] = useMutation(DELETE_COMPANY);

  const [updateCompany, { data: companyData }] = useMutation(UPDATE_COMPANY);
  const { handleSubmit, errors, control } = useForm();
  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  useEffect(() => {
    setSelectedStates(countries[data?.company?.country] || []);
  }, [data]);

  const onSubmit = (data) => {
    updateCompany({
      variables: { id: Number(match.params.companyId), input: data },
    }).then(() => refetch());
  };

  const triggerContactModal = () =>
    handleModal(
      <AddContactForm refetch={refetch} id={data?.company} />,
      "Add Contact"
    );
  const triggerLocationModal = () =>
    handleModal(
      <AddLocation refetch={refetch} id={Number(match.params.companyId)} />,
      "Add Location"
    );
  const triggerOpportunityModal = () =>
    handleModal(
      <OpportunityForm
        company={data?.company}
        locations={data?.company?.locations}
        refetch={refetch}
      />,
      "Add Opportunity"
    );
  const addhttp = (url) => {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = "http://" + url;
    }
    return url;
  };

  const actions = () => (
    <React.Fragment>
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button label="Add Contact" onClick={triggerContactModal} />
          <Button label="Add Location" onClick={() => triggerLocationModal()} />
          <Button
            label="Add Opportunity"
            onClick={() => triggerOpportunityModal()}
          />
          <Button
            iconCategory="utility"
            iconName="delete"
            iconPosition="left"
            label="Delete"
            onClick={() => setOpen(true)}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </React.Fragment>
  );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const details = [
    {
      label: "Website",
      content: (
        <a href={addhttp(data?.company?.website)} target="_blank">
          {data?.company?.website || "No website"}
        </a>
      ),
      truncate: true,
    },
    {
      label: "Category",
      content: <p>{data?.company?.category}</p>,
    },
    {
      label: "Created at",
      content: <Moment format="DD-MMM-YYYY">{data?.company.created_at}</Moment>,
    },
    {
      label: "Updated at",
      content: <Moment format="DD-MMM-YYYY">{data?.company.updated_at}</Moment>,
    },
    // {
    //   label: "Field 4",
    //   content: "Description (2-line truncatencation)",
    //   content: <a href="javascript:void(0);">Hyperlink</a>,
    //   truncate: true,
    // },
  ];

  return (
    <IconSettings iconPath="/icons">
      <ConfirmDelete
        isOpen={isOpen}
        toggleOpen={setOpen}
        action={deleteCompany}
        variables={{ id: Number(data?.company?.id) }}
        loading={deleting}
        url="/companies"
        message={`${data?.company?.company_name} Company and all related Locations, Projects, Opportunities and Equipment will be permanently deleted and you won't be able to recover them. `}
      />
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          details={details}
          icon={
            <Icon
              assistiveText={{ label: "User" }}
              category="standard"
              name="account"
            />
          }
          label="Company"
          onRenderActions={actions}
          title={data?.company.company_name}
          variant="record-home"
        />

        <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
          <div className="slds-col slds-col slds-size_3-of-5">
            <Tabs id="left" className="slds-var-p-around_small">
              <TabsPanel label="Details">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    as={
                      <Input
                        iconRight={
                          <InputIcon
                            assistiveText={{
                              icon: "Edit",
                            }}
                            name="Edit"
                            category="utility"
                          />
                        }
                        type="text"
                        placeholder="Company name"
                        label="Company name"
                      />
                    }
                    control={control}
                    name="company_name"
                    defaultValue={data?.company.company_name}
                  />

                  <Controller
                    as={
                      <label htmlFor="country">
                        Country
                        <br />
                        <select
                          name="country"
                          onChange={({ target }) =>
                            setSelectedStates(countries[target.value])
                          }
                        >
                          <option
                            value={"united states"}
                            selected={data?.company.country === "united states"}
                          >
                            United states
                          </option>
                          <option
                            value={"australia"}
                            selected={data?.company.country === "australia"}
                          >
                            Australia
                          </option>
                        </select>
                      </label>
                    }
                    control={control}
                    name="country"
                    defaultValue={data?.company.country || "united states"}
                  />
                  <br />
                  <Controller
                    as={
                      <label htmlFor="state">
                        State
                        <br />
                        <select name="state">
                          {selectedStates.map((state) => {
                            return (
                              <option
                                key={state.State}
                                value={state.State}
                                selected={data?.company.state === state.State}
                              >
                                {state.State}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    }
                    control={control}
                    name="state"
                    defaultValue={data?.company.state || "Texas"}
                  />
                  <Controller
                    as={
                      <Input
                        type="text"
                        placeholder="Name"
                        label="City"
                        name="city"
                      />
                    }
                    control={control}
                    name="city"
                    defaultValue={data?.company.city}
                  />

                  <Controller
                    as={
                      <Input
                        type="url"
                        placeholder="Enter website domain"
                        label="Website"
                        name="website"
                      />
                    }
                    control={control}
                    name="website"
                    defaultValue={data?.company.website}
                  />
                  <br />
                  <Button type="submit">Save</Button>
                </form>
              </TabsPanel>
              <TabsPanel label="Contacts">
                <ContactsList company={data?.company} />
              </TabsPanel>
              <TabsPanel label="Locations">
                <LocationsList company={data?.company.id} />
              </TabsPanel>
              <TabsPanel label="Projects">
                <ProjectsList company={data?.company.id} />
              </TabsPanel>
            </Tabs>
          </div>
          <div className="slds-col slds-col slds-size_2-of-5">
            <Tabs id="right" className="slds-var-p-around_small">
              <TabsPanel label="Opportunities">
                <OpportunitiesList
                  company={data?.company}
                  locations={data?.company?.locations}
                />
              </TabsPanel>
            </Tabs>
          </div>
        </div>
      </BrandBand>
    </IconSettings>
  );
}
