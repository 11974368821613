import React from "react";
import { Document, Page, PDFViewer, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import Overview from "./Overview";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router";
import EquipmentList from "./EquipmentList";
import Highlights from "./Highlights";
import _ from "lodash";
import "./style.css";
import {
  getAvgPressureDrop,
  getAvgCFM,
  getTotalCoolingSaving,
  getTotalFanEnergySaving,
} from "./functions";

const PROJECT_REPORT_QUERY = gql`
  query GetProjectReport($projectId: ID!) {
    project(id: $projectId) {
      id
      project_name
      start_date
      close_date
      description
      location {
        id
        location_name
        address
        city
        state
        country
        operating_days_week
        operating_hours_day
        recirculated_air
        electricity_cost
        company {
          id
          company_name
          country
        }
      }

      contacts {
        contact_name
        phone_number
        email
        is_primary
      }

      equipments {
        id
        building
        location_on_site
        status
        size
        cfm
        lps
        calculated_price
        type

        name
        equipment_readings(projectId: $projectId) {
          id
          outside_air_humidity
          outside_air_temperature
          date_time
          coil_differential_pressure
          airflow
          reading_type
          hertz
          pressure_unit
          airflow_unit
        }
      }
    }
  }
`;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    // backgroundColor: "#0074B6",
    backgroundColor: "#ffffff",
  },
});

export default function ProjectReport() {
  const { projectId } = useParams();
  const { data, loading, error } = useQuery(PROJECT_REPORT_QUERY, {
    variables: {
      projectId,
    },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error?.message}</div>;

  const { equipments, location, contacts, ...project } = data.project;

  const pressureUnit =
    location?.company?.country === "australia" ? "Pa" : "In-Wg";

  const dates = [project.start_date, project.close_date]
    .filter((x) => x !== null)
    .map((date) => moment(date).format("DD/MMM/YYYY"));
  // equipments
  // .map((x) => x?.equipment_readings?.map((r) => r.date_time))
  // ?.reduce((all, y) => {
  //   return y ? [...all, ...y] : all;
  // }, [])
  // .map((z) => new Date(z).getTime())
  // .sort((a, b) => a - b);

  const serviceDates = dates?.join(" - ");
  const total = equipments
    ? equipments
        .map((unit) => unit.calculated_price)
        .reduce((total, x) => total + x, 0)
    : 0;

  let cop = 0.21;

  if (
    [
      "IL",
      "Illinois",
      "IN",
      "Indiana",
      "WI",
      "Wisconsin",
      "MA",
      "Massachusetts",
      "NH",
      "New Hampshire",
      "MI",
      "Michigan",
    ].indexOf(location.state) > -1
  ) {
    cop = 0.19;
  } else if (
    [
      "NY",
      "New York",
      "NJ",
      "New Jersey",
      "PA",
      "Pennsylvania",
      "DE",
      "Delaware",
      "TAS",
      "Tasmania",
    ].indexOf(location.state) > -1
  ) {
    cop = 0.2;
  } else if (
    ["GA", "Georgia", "NSW", "New South Wales"].indexOf(location.state) > -1
  ) {
    cop = 0.23;
  } else if (["TX", "Texas"].indexOf(location.state) > -1) {
    cop = 0.25;
  } else if (["FL", "Florida"].indexOf(location.state) > -1) {
    cop = 0.29;
  } else if (
    ["QLD", "Queens land", "NT", "North Territory"].indexOf(location.state) > -1
  ) {
    cop = 0.26;
  }

  const buildingsObj = _.mapValues(_.groupBy(equipments, "building"), (list) =>
    list.map((unit) => ({
      ...unit,
      pre_reads:
        unit.equipment_readings &&
        unit.equipment_readings.length > 0 &&
        unit.equipment_readings
          .filter((x) => x.reading_type === "PRE_READ")
          .reduce((last, reading) => {
            return new Date(reading?.date_time).getTime() >
              (last?.date_time ? new Date(last?.date_time).getTime() : 0)
              ? reading
              : last;
          }, null),

      post_reads:
        unit.equipment_readings &&
        unit.equipment_readings.length > 0 &&
        unit.equipment_readings
          .filter((x) => x.reading_type === "POST_READ")
          .reduce((last, reading) => {
            return new Date(reading?.date_time).getTime() >
              (last?.date_time ? new Date(last?.date_time).getTime() : 0)
              ? reading
              : last;
          }, null),
    }))
  );

  const buildings = Object.keys(buildingsObj).map((key) => {
    return {
      name: key,
      units: buildingsObj[key],
    };
  });

  const ahu = location?.company?.country === "australia" ? "lps" : "cfm";
  const currency = location?.company?.country === "australia" ? "AUD" : "USD";

  const pressureAvg = getAvgPressureDrop(equipments);

  const motorEfficiency =
    location?.company?.country === "australia" ? 0.825 * 248.84 : 0.825;

  let fanEnergySaving = getTotalFanEnergySaving(buildings, motorEfficiency);

  const {
    operating_days_week,
    operating_hours_day,
    recirculated_air,
    electricity_cost,
  } = location;

  let totalFanEnergySaving =
    fanEnergySaving *
    (Number(operating_days_week) || 0) *
    (Number(operating_hours_day) || 0) *
    52;

  totalFanEnergySaving = Math.round(totalFanEnergySaving * 100) / 100;

  const recirculatedAir = Number(recirculated_air) || 0;

  let totalCoolingSaving = getTotalCoolingSaving(
    buildings,
    recirculatedAir,
    Number(operating_days_week) || 0,
    Number(operating_hours_day) || 0,
    cop
  );

  totalCoolingSaving = Math.round(totalCoolingSaving * 100) / 100;

  let co2Factor = 0.0007;
  if (location?.company?.country === "australia") {
    if (["NSW", "New South Wales"].indexOf(location.state) > -1) {
      co2Factor = 0.0009;
    } else if (["QLD", "Queensland"].indexOf(location.state) > -1) {
      co2Factor = 0.00093;
    } else if (["TAS", "Tasmania"].indexOf(location.state) > -1) {
      co2Factor = 0.00017;
    } else if (["VIC", "Victoria"].indexOf(location.state) > -1) {
      co2Factor = 0.00112;
    } else if (["WA", "Western Australia"].indexOf(location.state) > -1) {
      co2Factor = 0.00074;
    } else if (["SA", "South Australia"].indexOf(location.state) > -1) {
      co2Factor = 0.00053;
    } else if (["NT", "North Territory"].indexOf(location.state) > -1) {
      co2Factor = 0.00071;
    } else {
      co2Factor = 0.00088;
    }
  }
  const co2 =
    Math.round(co2Factor * (totalFanEnergySaving + totalCoolingSaving) * 100) /
    100;

  const totalAnnualSaving =
    Math.round((totalFanEnergySaving + totalCoolingSaving) * 100) / 100;

  const pageSize =
    location?.company?.country?.toLowerCase() !== "australia" ? "LETTER" : "A4";
  return (
    <PDFViewer width="100%" height="1000">
      <Document>
        <Page size={pageSize} style={styles.page}>
          <Overview
            project={project}
            total={total}
            location={location}
            serviceDates={serviceDates}
            currency={currency}
          />
        </Page>
        <Page size={pageSize} style={styles.page}>
          <EquipmentList
            buildings={buildings}
            ahu={ahu}
            fanEnergySaving={parseInt(totalFanEnergySaving)}
            pressureAvg={pressureAvg}
            pressureUnit={pressureUnit}
          />
        </Page>
        <Page size={pageSize} style={styles.page}>
          <Highlights
            co2={co2}
            totalFanEnergySaving={totalFanEnergySaving}
            totalCoolingSaving={totalCoolingSaving}
            totalAnnualSaving={totalAnnualSaving}
            project={project}
            electricityCost={Number(electricity_cost) || 0}
            currency={currency}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}
