import React from "react";
import { View, Text, Image, StyleSheet, Link } from "@react-pdf/renderer";
import moment from "moment";

export function Header1({ date }) {
  return (
    <View style={styles.header}>
      <Link src="https://blueboxair.com">
        <Image
          source="/bba-logo-light-2.png"
          cache={true}
          style={{
            height: 24,
            margin: "auto",
          }}
        />
      </Link>
      <View style={{ ...styles.col, height: 48 }}>
        <View style={styles.row}>
          <Text
            style={{
              ...styles.right,
              fontSize: 10,
              color: "#595959",
              fontFamily: "HelveticaNeueBold",
            }}
          >
            BLUE BOX AIR, LLC
          </Text>
        </View>
        <View style={styles.blueSpacer}>
          <Text> </Text>
        </View>
        <View style={styles.row}>
          <Text
            style={{
              ...styles.right,
              fontSize: 10,
              color: "grey",
              fontFamily: "HelveticaNeue",
            }}
          >
            REPORT DATE: {moment().format("DD-MMM-YYYY")}
          </Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    height: 48,
    flexDirection: "row",
    display: "flex",
    margin: "8px 16px",
  },
  logo: {
    height: "100%",
    width: 162,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#ffffff",
  },
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginVertical: 16,
  },
  white: {
    width: "100%",
    backgroundColor: "#ffffff",
    display: "flex",
    flex: 1,
    marginVertical: 8,
  },
  title: {
    fontSize: 32,
    color: "#189dd8",
    margin: "auto",
  },
  col: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  blueSpacer: {
    backgroundColor: "#189dd8",
    width: "100%",
    height: 4,
  },
  left: {
    marginRight: "auto",
    marginVertical: "auto",
  },
  right: {
    marginLeft: "auto",
    marginVertical: "auto",
  },
});
