import React, { useState, useEffect } from "react";
import {
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import OpportunityForm from "../opportunities/ModalAddOpportunity";
import Moment from "react-moment";
import { Link, BrowserRouter as Router } from "react-router-dom";

function OpportunitiesList(props) {
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    let arr = [];
    if (props) {
      props.locations.map((location) => {
        location.opportunities.map((project) => {
          arr.push({
            company: props.company.id,
            name: project.name,
            location_id: location.id,
            opportunity_id: project.id,
            location_name: location.location_name,
            state: location.state,
            stage: project.stage,
            value: project.total_amount,
            start_date: project.start_date,
          });
        });
      });
      setOpportunities(arr);
    }
  }, [props]);

  const { handleModal } = React.useContext(ModalContext);

  const triggerModal = (content, heading) => {
    handleModal(
      <OpportunityForm
        locations={props.locations}
        addOpportunity={props.addOpportunity}
        company={props?.company}
      />,
      "Add Opportunity"
    );
  };

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="OpportunityCard"
        headerActions={
          // <Button label="Add Opportunity" onClick={() => triggerModal()} />
          <CardFilter
            onChange={() => {
              console.log("Change");
            }}
          />
        }
        // filter={
        //   opportunities.length > 0 && (
        //     <div style={{display: 'flex'}}>
        //       <CardFilter
        //       onChange={() => {
        //         console.log("Change");
        //       }}
        //     />
        //     </div>
        //   )
        // }
        heading={`Opportunities (${opportunities.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          opportunities.length === 0 ? (
            <CardEmpty heading="No Opportunities">
              {/* <Button label="Add Opportunity" onClick={() => triggerModal()} /> */}
            </CardEmpty>
          ) : null
        }
      >
        <ul>
          {opportunities.map((opportunity) => {
            return (
              <li style={{ padding: "20px", marginBottom: "20px" }}>
                <Link
                  to={`/companies/${props.company.id}/opportunities/${opportunity.opportunity_id}`}
                >
                  <h2 style={{ fontSize: "20px", color: "#000000" }}>
                    {opportunity.name}{" "}
                  </h2>
                  {/* <p>
                    Expected start date:{" "}
                    <Moment
                      date={opportunity.start_date}
                      format={"DD-MMM-YYYY"}
                    />{" "}
                  </p> */}
                  <p>Current stage: {opportunity.stage}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </Card>
    </IconSettings>
  );
}

export default OpportunitiesList;
