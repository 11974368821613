import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { gql, useMutation, useQuery } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { Input } from "@salesforce/design-system-react";
import { CompanyCombobox } from "../../features/CompanyCombobox";
import COMPANIES from "../../graphql/queries/companies";

const ADD_CONTACT = gql`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      contact_name
      phone_number
      email
      company_title
    }
  }
`;

const ADD_CONTACT_FOR_PROJECT = gql`
  mutation CreateContact($input: ContactInput!) {
    createContact(input: $input) {
      contact_name
      phone_number
      email
      company_title
    }
  }
`;

export default function ModalAddContactToOpportunity(props) {
  const { handleModal } = React.useContext(ModalContext);
  const { loading, error, data } = useQuery(COMPANIES);

  const [addContact] = useMutation(ADD_CONTACT);
  const [addContactForProject] = useMutation(ADD_CONTACT_FOR_PROJECT);

  const [company, setCompany] = useState(null);

  const { register, handleSubmit, control } = useForm();

  const select = (item) => setCompany(item);

  const onSubmit = (data) => {
    if (company) {
      data.company_id = parseInt(company.id);
    }

    if (props.isProject) {
      addContactForProject({
        variables: {
          input: {
            ...data,
            project_id: Number(props.id),
          },
        },
      }).then(() => props.refetch());
    } else {
      addContact({
        variables: {
          input: {
            ...data,
            opportunity_id: Number(props.id),
          },
        },
      }).then(() => props.refetch());
    }

    handleModal();
  };

  let companies = [];
  if (loading) return <div>Loading ...</div>;
  if (data && data?.companies) companies = data?.companies;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <label htmlFor="firstName">
            Full name *
            <Input
              name="contact_name"
              type="text"
              ref={register}
              required={true}
            />
          </label>
        }
        control={control}
        name="contact_name"
        defaultValue={""}
      />
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="emailAddress">
              Email Address *
              <Input name="email" type="email" ref={register} required={true} />
            </label>
          }
          className="slds-col slds-size_1-of-2"
          control={control}
          name="email"
          defaultValue={""}
        />
        <br />
        <Controller
          as={
            <label htmlFor="phone_number">
              Phone Number *
              <Input
                name="phone_number"
                type="tel"
                ref={register}
                required={true}
              />
            </label>
          }
          className="slds-col slds-size_1-of-2"
          control={control}
          name="phone_number"
          defaultValue={""}
        />
      </section>
      <br />
      {companies.length > 0 && (
        <label htmlFor="combobox">
          Company *
          <CompanyCombobox
            options={companies}
            select={select}
            required={true}
          />
        </label>
      )}
      <br />
      <Controller
        as={
          <label htmlFor="companyTitle">
            Title
            <Input name="company_title" type="text" ref={register} />
          </label>
        }
        control={control}
        name="company_title"
        defaultValue={""}
      />
      <br />
      <Input type="submit" value="Save" />
    </form>
  );
}
