import { GET_COMPANIES } from "./companiesTypes";

const initialState = {
  companies: [],
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      const companies = [...state.companies, action.payload];
      return {
        ...state,
        companies,
      };
    default:
      return state;
  }
};

export default companyReducer;
