import React, { useState, useEffect, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import {
  BrandBand,
  PageHeaderControl,
  PageHeader,
  Input,
  Button,
  ButtonGroup,
  Icon,
  IconSettings,
} from "@salesforce/design-system-react";
import { useQuery } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { AddUserForm } from "./ModalAddUsers";
import USERS from "../../graphql/queries/users";
import { EditUserForm } from "./ModalEditUsers";
import _ from "lodash";
import { useParams } from "react-router-dom";

const sortable = [
  "id",
  "first_name",
  "user_name",
  "phone_number",
  "email",
  "city",
  "state",
  "department",
  "gender",
  "role",
  "country",
];

export function UsersList() {
  const { handleModal } = React.useContext(ModalContext);
  const { loading, error, data, refetch, fetchMore } = useQuery(USERS, {
    variables: {
      skip: 0,
      limit: 20,
    },
    orderBy: { by: "first_name", type: "asc" },
  });

  const { id } = useParams();

  const dataColumns = React.useMemo(
    () => [
      {
        Header: "ID",
        id: "id",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: (rowData, i) => (
          <a href="javascript://" onClick={() => editUserModal(rowData)}>
            {rowData?.first_name
              ? `${rowData?.first_name} ${rowData.last_name}`
              : rowData.user_name}
          </a>
        ),
        id: "first_name",
      },
      {
        Header: "User name",
        id: "user_name",
        accessor: "user_name",
      },
      {
        Header: "Email",
        id: "email",
        accessor: "email",
      },
      {
        Header: "Phone",
        id: "phone_number",
        accessor: "phone_number",
      },
      {
        Header: "Department",
        id: "department",
        accessor: "department",
      },
      {
        Header: "Gender",
        id: "gender",
        accessor: "gender",
      },
      {
        Header: "Role",
        id: "role",
        accessor: "role",
      },
      {
        Header: "City",
        id: "city",
        accessor: "city",
      },
      {
        Header: "State",
        id: "state",
        accessor: "state",
      },
      {
        Header: "Country",
        id: "country",
        accessor: "country",
      },
    ],
    []
  );
  const [rows, setRows] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ by: "first_name", type: "asc" });
  const itemsData = useMemo(() => rows.map((item) => ({ ...item })), [rows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: dataColumns,
      data: itemsData,
      initialState: {
        pageIndex: data?.usersBy?.pagination?.skip || 0,
        pageSize: data?.usersBy?.pagination?.limit || 20,
      },
      manualPagination: true,
      pageCount: data?.usersBy?.pagination?.total
        ? Math.ceil(
            data?.usersBy?.pagination?.total / data?.usersBy?.pagination?.limit
          )
        : 0,
    },
    usePagination
  );

  useEffect(() => {
    if (data) {
      setRows(data.usersBy?.users);
    }
  }, [data]);

  useEffect(() => {
    const variables = {
      where: {
        name: search,
      },
      skip: pageIndex * pageSize,
      limit: pageSize,
      orderBy: sort,
    };

    if (!search || search === "") {
      delete variables.where.name;
    }

    if (!sort) {
      delete variables.orderBy;
    }

    fetchMore({
      variables,
    }).then((res) => {
      setRows(res?.data.usersBy.users);
    });
  }, [pageIndex, pageSize, search, sort]);

  const clickHandler = (column) => {
    let type, by;

    if (sortable.indexOf(column) > -1) {
      if (!sort || (sort && sort.by === "id") || sort.by !== column) {
        type = "desc";
        by = column;
      } else if (sort.type === "desc") {
        type = "asc";
        by = column;
      } else {
        type = "desc";
        by = "id";
      }

      if (by === "first_name") {
        by = "first_name";
      }

      setSort({ by, type });
    }
  };

  const editUserModal = (e) =>
    handleModal(<EditUserForm refetch={refetch} user={e} />, "Edit user");
  const triggerModal = () =>
    handleModal(<AddUserForm refetch={refetch} />, "Add user");
  const filterTable = (input) => setSearch(input);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const actions = () => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <Button
            variant="brand"
            label="Add User"
            onClick={() => triggerModal()}
          />
        </ButtonGroup>
      </ButtonGroup>
    </PageHeaderControl>
  );

  const controls = () => (
    <React.Fragment>
      <PageHeaderControl>
        <Input
          placeholder="Search users"
          type="text"
          onInput={(e) => filterTable(e.target.value)}
        />
      </PageHeaderControl>
    </React.Fragment>
  );
  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          icon={
            <Icon
              assistiveText={{ label: "User" }}
              category="standard"
              name="opportunity"
            />
          }
          label="USERS"
          title={`Company users`}
          variant="object-home"
          info={`${data?.usersBy?.pagination?.total} users`}
          onRenderActions={actions}
          onRenderControls={controls}
        />
        <div
          style={{
            backgroundColor: "#fff",
            overflowX: "auto",
          }}
        >
          <table {...getTableProps()} className="bba-data-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        onClick={() => clickHandler(column.id)}
                        style={
                          sortable.indexOf(column.id) > -1
                            ? { cursor: "pointer" }
                            : {}
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            maxWidth: "100px",
                            minWidth: "50px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            {column.render("Header")}
                          </div>
                          {sort && (
                            <div style={{ width: "24px", margin: "auto" }}>
                              {sort.by === column.id ? (
                                sort.type === "desc" ? (
                                  <img
                                    src="icons/custom/chevron-down.svg"
                                    width="16"
                                  />
                                ) : sort.type === "asc" ? (
                                  <img
                                    src="icons/custom/chevron-up.svg"
                                    width="16"
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </th>
                    ))
                  }
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page?.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000">Loading...</td>
                ) : (
                  <td colSpan="10000">
                    Showing {page.length} of ~
                    {data?.companiesBy?.pagination?.total} results
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions?.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </BrandBand>
    </IconSettings>
  );
}
