import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { useQuery, gql } from "@apollo/client";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";

export function ProjectsList(props) {
  let company_id = props.company;

  const LocationLink = ({ children, item, ...props }) => (
    <DataTableCell title={children} {...props}>
      <Link to={`/companies/${company_id}/locations/${item.location_id}`}>
        {children}
      </Link>
    </DataTableCell>
  );

  LocationLink.displayName = DataTableCell.displayName;

  const ProjectLink = ({ children, item, ...props }) => (
    <DataTableCell title={children} {...props}>
      <Link to={`/projects/${item.project_id}`}>{children}</Link>
    </DataTableCell>
  );

  ProjectLink.displayName = DataTableCell.displayName;

  const Value = ({ children, item, ...props }) => (
    <DataTableCell title={children} {...props}>
      {children}
    </DataTableCell>
  );

  Value.displayName = DataTableCell.displayName;

  const [realProjectArray, setRealProjectArray] = useState([]);
  const [projects, setProjects] = useState([]);

  const SINGLE = gql`
    query GetCompanyByID($id: ID!) {
      company(id: $id) {
        locations {
          id
          location_name
          state
          address
          projects {
            id
            project_name
            stage
            total_amount
            created_by {
              user_name
            }
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(SINGLE, {
    variables: { id: Number(props.company) },
  });

  // TODO!! update this to fit projects
  // this maps over locations rather than links

  const filterTable = (input) => {
    const res = realProjectArray.filter(
      (item) =>
        item["project_name"].toLowerCase().includes(input.toLowerCase()) ||
        item["location_name"].toLowerCase().includes(input.toLowerCase())
    );
    if (res.length > 0) {
      setProjects(res);
    } else {
      setProjects(realProjectArray);
    }
  };

  useEffect(() => {
    let arr = [];
    if (data) {
      if (data.company.hasOwnProperty("locations")) {
        data.company.locations.map((location) => {
          location.projects.map((project) => {
            arr.push({
              project_name: project.project_name,
              location_id: location.id,
              project_id: project.id,
              location_name: location.location_name,
              state: location.state,
              stage: project.stage,
              value: project.total_amount,
            });
          });
        });
        setProjects(arr);
        setRealProjectArray(arr);
      }
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="ProjectsCard"
        filter={
          data.company.locations.length > 0 && (
            <CardFilter onChange={(e) => filterTable(e.target.value)} />
          )
        }
        heading={`Projects (${projects.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          projects.length === 0 ? (
            <CardEmpty heading="No Projects">
              <p>
                Create a project by first creating an opportunity and setting
                amounts.
              </p>
            </CardEmpty>
          ) : null
        }
      >
        <DataTable fixedLayout items={projects} id="projects" on>
          <DataTableColumn label="Id" property="project_id"></DataTableColumn>
          <DataTableColumn label="Project name" property="project_name">
            <ProjectLink />
          </DataTableColumn>
          <DataTableColumn label="Value" property="value">
            <Value />
          </DataTableColumn>
          <DataTableColumn label="Location" property="location_name">
            <LocationLink />
          </DataTableColumn>
          <DataTableColumn label="State" property="state"></DataTableColumn>
        </DataTable>
      </Card>
    </IconSettings>
  );
}
