import { gql } from '@apollo/client'

const DELETE_USER = gql`
  mutation DeleteUser($id: Int!) {
    deleteUser(where: { id: $id }) {
      id
    }
  }
`;

export default DELETE_USER;
