import React from "react";
import useModal from "./useModal";
import ModalContainer from "./modal";

let ModalContext;

let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let {
    modal,
    handleModal,
    modalContent,
    modalHeading,
    modalForm,
  } = useModal();
  return (
    <Provider
      value={{ modal, handleModal, modalContent, modalHeading, modalForm }}
    >
      <ModalContainer />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
