/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-2",
  aws_cognito_identity_pool_id:
    "us-east-2:74127d0d-6dcc-4bb7-9728-344b48ec409e",
  aws_cognito_region: "us-east-2",
  aws_user_pools_id: "us-east-2_1YGjNdOFn",
  aws_user_pools_web_client_id: "34lsseoseu4asbckatdi2follu",
  oauth: {},
  aws_user_files_s3_bucket: "files202549-staging",
  aws_user_files_s3_bucket_region: "us-east-2",
};

export default awsmobile;
