import React, {useState,useEffect} from "react";

export default () => {
  let [modal, setModal] = useState(false);
  let [modalForm, setModalForm] = useState("");
  let [modalContent, setModalContent] = useState({});	
  let [modalHeading, setModalHeading] = useState(false)

  let handleModal = (content = false, heading) => {
  	setModalHeading(heading)
    if (content) {	
      setModalContent(content);
    }
    setModal(!modal)

  };

  return { modal, modalHeading, handleModal, modalContent };
};
