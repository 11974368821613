import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { Input, Combobox, Button } from "@salesforce/design-system-react";
import _ from "lodash";

const opportunityStages = [
  "Qualification",
  "Need Analysis",
  "Proposal",
  "Negotiation",
  "Closed Won",
  "Closed Lost",
];

const currencies = [
  { id: "USD", label: "USD" },
  { id: "AUD", label: "AUD" },
];

const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity(
    $id: ID!
    $name: String
    $total_amount: Float
    $description: String
    $currency: Currency
    $stage: String
  ) {
    updateOpportunity(
      where: { id: $id }
      data: {
        name: $name
        total_amount: $total_amount
        description: $description
        currency: $currency
        stage: $stage
      }
    ) {
      id
    }
  }
`;

export default function OpportunityForm(props) {
  const [selection, setSelection] = useState([]);
  const [stageSelection, setStageSelection] = useState([]);
  const [currency, setCurrency] = useState([]);

  const [project, setProject] = useState(null);
  const [stages, setStages] = useState([]);

  const [updateOpportunity, { loading: updateLoading }] =
    useMutation(UPDATE_OPPORTUNITY);

  useEffect(() => {
    setStageSelection(
      stages
        .map((stage) => ({ id: stage, label: stage }))
        .filter((stage) => stage.id === project?.stage)
    );
    setCurrency(
      project?.currency
        ? currencies.filter((x) => x.id === project?.currency)
        : props?.company?.country === "australia"
        ? [{ id: "AUD", label: "AUD" }]
        : [{ id: "USD", label: "USD" }]
    );
  }, [project, props]);

  const { register, handleSubmit, control, setValue } = useForm();

  useEffect(() => {
    if (props?.project) {
      setProject(props?.project);
      setStages(opportunityStages);
    }
  }, [props]);

  const onSubmit = () => {
    updateOpportunity({
      variables: {
        id: project?.id,
        name: project?.name,
        total_amount: project?.total_amount,
        currency: project?.currency,
        stage: project?.stage,
        // description: project?.description || ''
      },
    }).then(() => props.refetch());
  };

  const opportunity = props?.project;

  if (!opportunity || updateLoading)
    return (
      <div style={{ display: "flex", width: "100%", height: "300px" }}>
        <div style={{ margin: "auto" }}>Loading...</div>
      </div>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <br />
      <Controller
        as={
          <label htmlFor="name">
            Opportunity name
            <Input
              name="name"
              defaultValue={opportunity?.name}
              type="text"
              ref={register}
              value={project?.name}
              onChange={({ target }) =>
                setProject({ ...project, name: target.value })
              }
              readOnly={true}
            />
          </label>
        }
        control={control}
        name="name"
        defaultValue={opportunity?.name}
      />
      <br />
      <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
        <div className="slds-col_padded">
          <Controller
            as={
              <Combobox
                id="project-stages-list"
                events={{
                  onRequestRemoveSelectedOption: (event, data) => {
                    setStageSelection(data.selection);
                  },
                  onSelect: (event, data) => {
                    setProject({ ...project, stage: data?.selection[0]?.id });
                    setStageSelection(data.selection);
                  },
                }}
                labels={{
                  label: "Stage",
                  placeholder: "select a stage",
                }}
                options={stages.map((stage) => ({ id: stage, label: stage }))}
                selection={stageSelection}
                variant="readonly"
                readOnly={true}
              />
            }
            control={control}
            name="stage"
          />
        </div>
      </div>
      <br />

      <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
        <div className="slds-col_padded">
          <Controller
            as={
              <Combobox
                id="project-currency"
                events={{
                  // onRequestRemoveSelectedOption: (event, data) => {
                  //   setCurrency(data.selection)
                  // },
                  onSelect: (event, data) => {
                    setProject({
                      ...project,
                      currency: data?.selection[0]?.id,
                    });
                    setCurrency(data.selection);
                  },
                }}
                labels={{
                  label: "Currency",
                  placeholder: "select currency",
                }}
                options={currencies}
                selection={currency}
                variant="readonly"
                readOnly={true}
              />
            }
            control={control}
            name="currency"

            //defaultValue={'WALKTHROUGH'}
          />
        </div>

        <div className="slds-col_padded">
          <Controller
            as={
              <label htmlFor="total_amount">
                Total amount
                <Input
                  name="total_amount"
                  type="number"
                  defaultValue={Number(opportunity?.total_amount)}
                  ref={register}
                  value={project?.total_amount}
                  onChange={({ target }) =>
                    setProject({
                      ...project,
                      total_amount: Number(target.value),
                    })
                  }
                  readOnly={true}
                />
              </label>
            }
            control={control}
            name="total_amount"
            defaultValue={Number(opportunity?.total_amount)}
          />
        </div>
      </div>

      <br />
      {/* <div style={{ display: "flex", width: "100%" }}>
        <Button
          type="submit"
          label="Save"
          variant="brand"
          style={{ flex: 1 }}
        />
      </div> */}
    </form>
  );
}
