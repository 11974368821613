import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import Gallery from "react-grid-gallery";
import { Header } from "../Header";
import { FixedSizeGrid as Grid } from "react-window";
import moment from "moment";
import { saveAs } from "file-saver";

const WORDS = {
  ASC: "Ascending",
  DESC: "Descending",
  CreatedDate: "Date",
  "Equipment__r.Name": "Name",
  "Equipment__r.Location_on_Site__c": "Location",
};

export default function Photos() {
  const { projectId } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");

  const [sort, setSort] = useState("CreatedDate");
  const [sortBy, setSortBy] = useState("DESC");

  const [active, setActive] = useState(-1);
  const [columnCount, setColumnCount] = useState(4);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setColumnCount(Math.floor(window.innerWidth / 300));
    });
    setColumnCount(Math.floor(window.innerWidth / 300));
    return () => window.removeEventListener("resize");
  }, []);
  function getExt(url) {
    return url.split(".").pop();
  }
  useEffect(() => {
    setLoading(true);
    fetch(
      // `https://blueboxserver.herokuapp.com/public/project/${projectId}/photos`
      `https://api.blueboxair.com/public/project/${projectId}/photos?search=${search}&sort=${sort}&by=${sortBy}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res);
        // setPageCount(Math.ceil(res?.photos?.length / pageSize));
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [projectId, search, sort, sortBy]);

  // useEffect(() => {
  //   //setTotal(data?.attachments?.length);
  //   if (data?.project?.attachments)
  //     setPageCount(Math.ceil(data?.project?.attachments?.length / pageSize));
  // }, [data]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error!!</div>;
  const cdn = "https://bba.imgix.net"; //https://blueboxair.com/cdn-cgi/image/format=jpeg,width=900
  const IMAGES = data
    ? data?.files?.records
        // ?.filter((_, i) => i >= (page - 1) * pageSize && i < page * pageSize)
        .map((file) => ({
          src: file?.Path__c?.toLowerCase().endsWith(".mp4")
            ? file?.Path__c
            : `${file?.Path__c.replace(
                "https://files113647-prod.s3.us-east-2.amazonaws.com",
                cdn
              )}?w=900&h=550&auto=format,enhance&q=75`,
          thumbnail: file?.Path__c?.toLowerCase().endsWith(".mp4")
            ? file.Path__c.replace(
                `https://files113647-prod.s3.us-east-2.amazonaws.com/public`,
                "https://cdn.blueboxair.com"
              )
            : `${file?.Path__c.replace(
                "https://files113647-prod.s3.us-east-2.amazonaws.com",
                cdn
              )}?w=300&h=180&fit=crop&crop=entropy&auto=format,enhance&q=70`,
          thumbnailWidth: 300,
          thumbnailHeight: 180,
          isSelected: false,
          caption: [
            file?.Equipment__r?.Name,
            file?.Equipment__r?.Location_on_Site__c,
            moment(file?.CreatedDate).format("DD-MMM-YYYY"),
            moment(file?.CreatedDate).format("HH:mm"),
            file?.Notes__c,
          ]
            .filter((x) => x !== null)
            .join(", "),
          name:
            [file?.Equipment__r?.Name, file?.Equipment__r?.Location_on_Site__c]
              .filter((x) => x !== null)
              .join("_") +
            "." +
            getExt(file?.Path__c),
        }))
    : [];

  // const Row = ({ index, style }) => {
  //   const file = IMAGES[index];
  //   return (
  //     <div
  //       style={{ display: "flex", height: 180, width: 300 }}
  //       onClick={() => setActive(file)}
  //     >
  //       {file.thumbnail.toLowerCase().endsWith(".mp4") ? (
  //         <video width="320" height="240" controls>
  //           <source src={file.thumbnail} type="video/mp4" />
  //         </video>
  //       ) : (
  //         <img
  //           src={file.thumbnail}
  //           style={{ marginRight: "auto", marginLeft: "auto" }}
  //         />
  //       )}
  //     </div>
  //   );
  // };

  // const innerElementType = ({ style, ...rest }) => (
  //   <div style={{ ...style }} {...rest} className="grid-photo" />
  // );
  // console.log({ active });
  const Cell = ({ columnIndex, rowIndex, style, isScrolling }) => {
    const fileIndex = rowIndex * columnCount + columnIndex;
    const file = IMAGES[fileIndex];

    return (
      <div style={style} onClick={() => setActive(fileIndex)}>
        <div style={{ position: "relative" }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              saveAs(file?.src, file?.name);
            }}
            style={{
              position: "absolute",
              top: 16,
              right: 16,
              width: 32,
              height: 32,
            }}
          >
            <img
              src="https://img.icons8.com/ios/50/000000/download--v1.png"
              width={24}
              height={24}
            />
          </button>

          {file?.thumbnail?.toLowerCase()?.endsWith(".mp4") ? (
            <video width="300" height="180" controls>
              <source src={encodeURI(file?.thumbnail)} type="video/mp4" />
            </video>
          ) : (
            <img
              src={encodeURI(file?.thumbnail)}
              height="180"
              width="300"
              loading="lazy"
              style={{
                filter: isScrolling ? "blur(7px)" : "none",
                cursor: "pointer",
              }}
            />
          )}
          <p style={{ fontWeight: 700, padding: 8 }}>{file?.caption}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <Header showButton={false} />

      <div style={{ padding: "60px 16px 0 16px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            overflowX: "auto",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#f3f2f2",
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 8,
            }}
          >
            {/* <PageHeader
              details={[]}
              icon={
                <Icon
                  assistiveText={{ label: "User" }}
                  category="standard"
                  name="account"
                />
              }
              label="Project"
              title={data?.project?.Name}
              info={`${data?.files?.totalSize} items • Updated 13 minutes ago`}
              truncate
              variant="object-home"
              style={{
                boxShadow: "none",
                border: "none",
              }}
            /> */}

            <div
              style={{ display: "flex", flexDirection: "column", padding: 16 }}
            >
              <h1
                style={{
                  fontSize: 28,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 8,
                }}
              >
                <span style={{ fontSize: 12, fontWeight: 700 }}>Project</span>
                <span style={{ lineHeight: "24px" }}>
                  {data?.project?.Name || "Loading project name ..."}
                </span>
              </h1>
              <p>{`${data?.files?.totalSize || 0} items • sorted by ${
                WORDS[sort]
              } ${WORDS[sortBy]}`}</p>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "auto",
                paddingRight: 16,
              }}
            >
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearch(searchText);
                  }}
                >
                  <input
                    name="search"
                    value={searchText}
                    placeholder="search..."
                    onChange={({ target }) => setSearchText(target.value)}
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: 4,
                      padding: 4,
                      margin: 4,
                      height: 35,
                      width: 200,
                    }}
                  />
                </form>
              </div>
              <div>
                <select
                  name="sort"
                  onChange={({ target }) => setSort(target.value)}
                  value={sort}
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 4,
                    padding: 4,
                    margin: 4,
                    height: 35,
                  }}
                >
                  <option value="Equipment__r.Name">Name</option>
                  <option value="Equipment__r.Location_on_Site__c">
                    Location
                  </option>
                  <option value="CreatedDate">Date</option>
                  {/* <option>Time</option> */}
                </select>
              </div>

              <div>
                <button
                  onClick={() =>
                    setSortBy((sortBy) => (sortBy === "DESC" ? "ASC" : "DESC"))
                  }
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 4,
                    padding: 4,
                    margin: 4,
                    height: 35,
                    width: 35,
                    outline: "none",
                    background: "#fff",
                  }}
                >
                  {sortBy === "ASC" ? (
                    <img src="https://img.icons8.com/ios/35/000000/generic-sorting-2.png" />
                  ) : (
                    <img src="https://img.icons8.com/ios/35/000000/generic-sorting.png" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              overflowX: "auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <div>
            <Gallery images={IMAGES} />
          </div> */}
            {/* <div>
            <List
              height={550}
              itemCount={IMAGES.length}
              itemSize={180}
              width={"100%"}
              innerElementType={innerElementType}
            >
              {Row}
          
            </List>
          </div> */}

            {loading ? (
              "Loading ..."
            ) : (
              <Grid
                columnCount={columnCount}
                columnWidth={308}
                height={550}
                rowCount={Math.ceil(IMAGES.length / columnCount)}
                rowHeight={240}
                width={columnCount * 308}
                // useIsScrolling
              >
                {Cell}
              </Grid>
            )}
            {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {new Array(pageCount).fill(0, 0, pageCount).map((_, i) => (
              <button
                key={i}
                onClick={() => setPage(i + 1)}
                style={{ margin: 4 }}
              >
                {i + 1}
              </button>
            ))}
          </div> */}
          </div>
        </div>
      </div>

      {active > -1 && (
        <div
          style={{
            display: "flex",
            position: "fixed",
            left: "50%",
            top: "50px",
            zIndex: 10000,
            transform: "translate(-50%, 0)",
            // width: "100%",
            // maxWidth: "900px",
            // justifyContent: "center",
            // alignItems: "center",
            flexDirection: "column",
            borderRadius: "8px",
            // backgroundColor: "#FFF",
            boxShadow:
              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
          }}
        >
          {/* <button
            style={{
              position: "absolute",
              left: 0,
              top: 24,
              height: "clac(100% - 24px)",
              width: "30px",
            }}
            disabled={active === 0}
            onClick={() => setActive((active) => (active > 1 ? active - 1 : 0))}
          >
            pre
          </button>

          <button
            style={{
              position: "absolute",
              right: 0,
              top: 24,
              height: "100%",
              width: "30px",
            }}
            disabled={active === IMAGES.length - 1}
            onClick={() =>
              setActive((active) =>
                active < IMAGES.length - 1 ? active + 1 : IMAGES.length - 1
              )
            }
          >
            Nxt
          </button> */}

          {/* <div style={{ position: "relative", border: "1px solid red" }}> */}
          <button
            style={{
              position: "absolute",
              top: -4,
              right: -4,
              backgroundColor: "#EEE",
              color: "red",
              width: 32,
              height: 32,
              borderRadius: 24,
              margin: "4 4 4 auto",
              border: "none",
              outline: "none",
              fontWeight: 900,
            }}
            onClick={() => setActive(-1)}
          >
            X
          </button>
          {/* </div> */}
          {IMAGES[active].src.toLowerCase().endsWith(".mp4") ? (
            <video width="600" height="480" controls>
              <source src={IMAGES[active].src} type="video/mp4" />
            </video>
          ) : (
            <img
              src={IMAGES[active].src}
              style={{ marginRight: "auto", marginLeft: "auto" }}
              height="500"
              width="auto"
              loading="lazy"
            />
          )}
          <p
            style={{
              fontWeight: 700,
              padding: 8,
              position: "absolute",
              bottom: 0,
              backgroundColor: "#00000077",
              width: "100%",
              color: "#fff",
            }}
          >
            {IMAGES[active].caption}
          </p>
        </div>
      )}
    </div>
  );
}
