import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { ModalContext } from "../ModalContext";
import { AddLocation } from "../locations/ModalAddLocation";

const LocationLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/companies/${props.company}/locations/${item.id}`}>
      {item.location_name || item.city || item.state}
    </Link>
  </DataTableCell>
);

LocationLink.displayName = DataTableCell.displayName;

const EquipmentCount = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item.equipments.length}</p>
  </DataTableCell>
);

EquipmentCount.displayName = DataTableCell.displayName;

const ContactCount = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item.contacts.length}</p>
  </DataTableCell>
);

ContactCount.displayName = DataTableCell.displayName;

const SINGLE = gql`
  query GetOpportunities($id: ID!) {
    company(id: $id) {
      locations {
        id
        location_name
        city
        state
        country
        location_code
        equipments {
          id
        }
        contacts {
          id
        }
      }
    }
  }
`;

export function LocationsList(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [items, setItems] = useState([]);
  const [input, setInput] = useState([]);
  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: {
      id: parseInt(props.company),
    },
  });

  const triggerModal = () =>
    handleModal(
      <AddLocation refetch={refetch} id={props.company} />,
      "Add Location"
    );
  const filterTable = (input) => {
    setInput(input);
    setItems(
      data?.company?.locations.filter((item) =>
        item.location_name.toLowerCase().includes(input.toLowerCase())
      )
    );
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="ContactCard"
        headerActions={
          <Button label="Add Location" onClick={() => triggerModal()} />
        }
        filter={
          data.company.locations.length > 0 && (
            <CardFilter onChange={(e) => filterTable(e.target.value)} />
          )
        }
        heading={`Locations (${data.company.locations.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          data.company.locations.length === 0 ? (
            <CardEmpty heading="No Locations">
              {/* <Button label="Add Location" onClick={() => triggerModal()} /> */}
            </CardEmpty>
          ) : null
        }
      >
        <DataTable
          fixedLayout
          items={
            items.length > 0 || input.length > 0
              ? items
              : data.company.locations
          }
          id="getContacts"
          on
        >
          <DataTableColumn label="Id" property="id"></DataTableColumn>
          <DataTableColumn label="Location Name" property="location_name">
            <LocationLink company={props.company} />
          </DataTableColumn>
          <DataTableColumn label="City" property="city"></DataTableColumn>

          <DataTableColumn label="State" property="state"></DataTableColumn>

          <DataTableColumn label="Country" property="country"></DataTableColumn>

          <DataTableColumn label="Equipment">
            <EquipmentCount />
          </DataTableColumn>
          <DataTableColumn label="Contacts">
            <ContactCount />
          </DataTableColumn>
        </DataTable>
      </Card>
    </IconSettings>
  );
}
