import { useState } from "react";

const getItem = (key) =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey
      ? JSON.parse(decodeURIComponent(storedValue))
      : total;
  }, "");

const setItem = (key, value, numberOfDays = 30) => {
  const now = new Date();
  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${JSON.stringify(
    value
  )};     expires=${now.toUTCString()}; path=/`;
};

export default function useCookie(key, value) {
  const getCookie = () => getItem(key) || value;
  const [cookie, setCookie] = useState(getCookie());
  const updateCookie = (value, numberOfDays) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie];
}
