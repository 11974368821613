import React, { useState, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Tabs,
  TabsPanel,
  DataTable,
  DataTableColumn,
  DataTableCell,
} from "@salesforce/design-system-react";
import Moment from "react-moment";
import { Storage } from "aws-amplify";

async function getFile(name) {
  const file = await Storage.get(`${name}`);
  return file;
}

const DATA_QUERY = gql`
  query DataQuery($id: ID!) {
    data(id: $id) {
      id
      pre_reads {
        id
        date_time
        coil_differential_pressure
        air_flow
        exit_air_temperature
        hertz
        outside_air_humidity
        outside_air_temperature
        breakthrough_achieved
        notes
      }
      post_reads {
        id
        date_time
        coil_differential_pressure
        air_flow
        exit_air_temperature
        hertz
        outside_air_humidity
        outside_air_temperature
        breakthrough_achieved
        notes
      }
      files {
        id
        path
        name
        notes
      }
    }
  }
`;
const UserCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item?.created_by?.user_name || ""}</p>
  </DataTableCell>
);
UserCell.displayName = DataTableCell.displayName;

const FilesCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item?.files?.length || 0}</p>
  </DataTableCell>
);
FilesCell.displayName = DataTableCell.displayName;

const DateCell = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Moment format="DD-MMM-YYYY hh:mm">{children}</Moment>
  </DataTableCell>
);
DateCell.displayName = DataTableCell.displayName;

function EquipmentData({ dataId }) {
  const { loading, error, data } = useQuery(DATA_QUERY, {
    variables: {
      id: dataId,
    },
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([]);
  }, [dataId]);

  useEffect(() => {
    (async () => {
      await data?.data?.files.forEach(async (file) => {
        const url = await getFile(file?.name);
        setFiles((files) => [...files, { ...file, url }]);
      });
    })();
  }, [data?.data?.files]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const { pre_reads, post_reads } = data?.data;

  return (
    <Tabs id="right" className="slds-var-p-around_small">
      <TabsPanel label="Pre-reads">
        <div style={{ overflow: "scroll", height: "340px" }}>
          <DataTable items={pre_reads} id="pre-reads-table">
            <DataTableColumn
              primaryColumn
              label="Date"
              key="date_time"
              property="date_time"
            >
              <DateCell />
            </DataTableColumn>

            <DataTableColumn
              primaryColumn
              label="Coil DP"
              key="coil_differential_pressure"
              property="coil_differential_pressure"
            />
            <DataTableColumn
              primaryColumn
              label="Air Flow"
              key="air_flow"
              property="air_flow"
            />
            <DataTableColumn
              primaryColumn
              label="Exit air temp."
              key="exit_air_temperature"
              property="exit_air_temperature"
            />
            <DataTableColumn
              primaryColumn
              label="Hertz"
              key="hertz"
              property="hertz"
            />
            <DataTableColumn
              primaryColumn
              label="Outside Air Humidity"
              key="outside_air_humidity"
              property="outside_air_humidity"
            />
            <DataTableColumn
              primaryColumn
              label="Outside Air Temperature"
              key="outside_air_temperature"
              property="outside_air_temperature"
            />
            <DataTableColumn
              primaryColumn
              label="Breakthrough Achieved"
              key="breakthrough_achieved"
              property="breakthrough_achieved"
            />
            <DataTableColumn
              primaryColumn
              label="Notes"
              key="notes"
              property="notes"
            />
          </DataTable>
        </div>
      </TabsPanel>
      <TabsPanel label="Post-reads">
        <div style={{ overflow: "scroll" }}>
          <DataTable items={post_reads} id="pre-reads-table">
            <DataTableColumn
              primaryColumn
              label="Date"
              key="date_time"
              property="date_time"
            >
              <DateCell />
            </DataTableColumn>

            <DataTableColumn
              primaryColumn
              label="Coil DP"
              key="coil_differential_pressure"
              property="coil_differential_pressure"
            />
            <DataTableColumn
              primaryColumn
              label="Air Flow"
              key="air_flow"
              property="air_flow"
            />
            <DataTableColumn
              primaryColumn
              label="Exit air temp."
              key="exit_air_temperature"
              property="exit_air_temperature"
            />
            <DataTableColumn
              primaryColumn
              label="Hertz"
              key="hertz"
              property="hertz"
            />
            <DataTableColumn
              primaryColumn
              label="Outside Air Humidity"
              key="outside_air_humidity"
              property="outside_air_humidity"
            />
            <DataTableColumn
              primaryColumn
              label="Outside Air Temperature"
              key="outside_air_temperature"
              property="outside_air_temperature"
            />
            <DataTableColumn
              primaryColumn
              label="Breakthrough Achieved"
              key="breakthrough_achieved"
              property="breakthrough_achieved"
            />
            <DataTableColumn
              primaryColumn
              label="Notes"
              key="notes"
              property="notes"
            />
          </DataTable>
        </div>
      </TabsPanel>
      <TabsPanel label="Files">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {files?.map((file) => (
            <div
              key={file?.id}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
                margin: "16px",
              }}
            >
              <img
                src={`${file?.url}`}
                width="220px"
                style={{
                  borderRadius: "4px",
                }}
              />
              <div>{file?.name}</div>
            </div>
          ))}
        </div>
      </TabsPanel>
    </Tabs>
  );
}

export default EquipmentData;
