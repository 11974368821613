import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import {
  ButtonGroup,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import OpportunityForm from "../opportunities/ModalAddOpportunity";
import { ModalContext } from "../ModalContext";

import {
  Link,
  useHistory,
  BrowserRouter as Router,
  Route,
  useLocation,
} from "react-router-dom";

export function OpportunitiesList(props) {
  const { handleModal } = React.useContext(ModalContext);

  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    if (props.opportunities) {
      setOpportunities(props.opportunities);
    }
  }, [props.opportunities]);

  const triggerOpportunityModal = () =>
    handleModal(
      <OpportunityForm location={props.id} refetch={props?.refetch} />,
      "Add Opportunity"
    );

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="OpportunityCard"
        headerActions={
          // <Button
          //   label="Add Opportunity"
          //   onClick={() => triggerOpportunityModal()}
          // />
          opportunities.length > 0 && (
            <CardFilter
              onChange={() => {
                console.log("Change");
              }}
            />
          )
        }
        // filter={
        //   opportunities.length > 0 && (
        //     <CardFilter
        //       onChange={() => {
        //         console.log("Change");
        //       }}
        //     />
        //   )
        // }
        heading={`Opportunities (${opportunities.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          opportunities.length === 0 ? (
            <CardEmpty heading="No Opportunities">
              {/* <Button
                label="Add Opportunity"
                onClick={() => triggerOpportunityModal()}
              /> */}
            </CardEmpty>
          ) : null
        }
      >
        <ul>
          {opportunities.map((opportunity) => {
            return (
              <li style={{ padding: "20px", marginBottom: "20px" }}>
                <Link
                  to={`/companies/${props.company}/opportunities/${opportunity.id}`}
                >
                  <h2 style={{ fontSize: "20px", color: "#000000" }}>
                    {opportunity.name}{" "}
                  </h2>
                  {/* <p>
                    Expected start date:{" "}
                    <Moment
                      date={opportunity.start_date}
                      format={"DD-MMM-YYYY"}
                    />{" "}
                  </p> */}
                  <p>Current stage: {opportunity.stage}</p>
                </Link>
              </li>
            );
          })}
        </ul>
      </Card>
    </IconSettings>
  );
}
