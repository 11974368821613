import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import _ from "lodash";
import {
  PageHeader,
  Icon,
  BrandBand,
  PageHeaderControl,
  ButtonGroup,
  Button,
  Input,
  Checkbox,
  ButtonStateful,
  ButtonIcon,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import Board from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import { states } from "../../utilities/states-list";
import OpportunityForm from "./ModalAddOpportunity";
import { ModalContext } from "../ModalContext";
import { useTable, usePagination } from "react-table";
// import OPPORTUNITIES from "../../graphql/queries/projects";
import { COMPANY_USERS } from "../../graphql/queries/companies";
import { UserSettingsContext } from "../../contexts";

const sortable = [
  "id",
  "name",
  "created_at",
  "start_date",
  "kickoff_call",
  "estimated_duration",
  "total_amount",
];

const stages = [
  "Warm Prospect",
  "Meeting",
  "Demo Performed",
  "Proposal Sent",
  "Won",
  "Closed Lost",
];

const boardStages = [
  "Warm Prospect",
  "Meeting",
  "Demo Performed",
  "Proposal Sent",
  "Won",
];

const OPPORTUNITIES = gql`
  query OpportunitiesBy(
    $where: OpportunityFilter
    $skip: Int
    $limit: Int
    $sort: Sort
  ) {
    opportunitiesBy(where: $where, skip: $skip, limit: $limit, orderBy: $sort) {
      opportunities {
        id
        name
        total_amount
        currency
        stage
        created_at
        proposals {
          id
          proposal_items {
            id
            calculated_price
          }
          is_approved
        }
        created_by {
          id
          user_name
          first_name
          last_name
        }
        location {
          id
          location_name
          address
          city
          state
          equipments {
            id
          }
          company {
            id
            company_name
            country
          }
        }
        contacts {
          id
          contact_name
          email
          phone_number
        }
      }
      pagination {
        total
        limit
        skip
      }
    }
  }
`;

const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity($where: ProjectWhere!, $data: ProjectUpdate!) {
    updateProject(where: $where, data: $data) {
      id
    }
  }
`;

const COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      company_name
      locations {
        id
        location_name
      }
    }
  }
`;

export function OpportunitiesList({ match }) {
  const { handleModal } = React.useContext(ModalContext);

  const userSettings = useContext(UserSettingsContext);

  const [filterByCountry, setCountryFilter] = useState(
    userSettings?.filters?.country || "ALL"
  );
  const [filterByUser, setUserFilter] = useState(
    userSettings?.filters?.created_by || 0
  );

  const { data: allUsersData, refetch: refetchUsers } = useQuery(COMPANY_USERS);
  const users = allUsersData?.companies?.map((co) => co.created_by);
  const createdByData = new Set(users);

  const [updateOpportunity, { oppUpdate }] = useMutation(UPDATE_OPPORTUNITY);
  const { loading, error, data, refetch, fetchMore } = useQuery(OPPORTUNITIES, {
    variables: {
      where: {},
      orderBy: { by: "created_at", type: "desc" },
    },
  });

  const [initialBoard, setInitialBoard] = useState();
  const { data: companiesData } = useQuery(COMPANIES);

  const dataColumns = React.useMemo(
    () => [
      {
        Header: "Date created",
        id: "created_at",
        accessor: (rowData, i) => (
          <Moment format="DD-MMM-YYYY">{rowData.created_at}</Moment>
        ),
      },
      {
        Header: "Opportunity name",
        accessor: (rowData, i) => (
          <Link
            to={`companies/${rowData?.location?.company?.id}/opportunities/${rowData?.id}`}
          >
            {rowData?.name}
          </Link>
        ),
        id: "name",
      },
      {
        Header: "Company",
        id: "company_name",
        accessor: (rowData, i) => rowData?.location?.company?.company_name,
      },
      {
        Header: "Created by",
        id: "created_by",
        accessor: (row, i) =>
          row?.created_by.first_name !== ""
            ? `${row?.created_by.first_name} ${row?.created_by.last_name}`
            : row?.created_by.user_name,
      },
      {
        Header: "Stage",
        id: "stage",
        accessor: "stage",
      },
      // {
      //   Header: "Address",
      //   id: "address",
      //   accessor: (rowData, i) => rowData?.location?.address,
      // },
      {
        Header: "Location",
        id: "location",
        accessor: (rowData, i) => rowData?.location?.location_name,
      },
      // {
      //   Header: "Country",
      //   id: "country",
      //   accessor: (rowData, i) => (
      //     <p style={{ textTransform: "capitalize" }}>
      //       {rowData?.location?.company?.country}
      //     </p>
      //   ),
      // },
      {
        Header: "Equipment",
        id: "equipments",
        accessor: (rowData, i) => rowData.location?.equipments?.length,
      },
      // {
      //   Header: "AHUs",
      //   id: "ahus",
      //   accessor: (rowData, i) => rowData.equipments.length,
      // },
      {
        Header: "State",
        id: "state",
        accessor: (rowData, i) =>
          rowData?.location?.state || rowData?.location?.company?.state,
      },
      // {
      //   Header: "Estimated duration",
      //   id: "estimated_duration",
      //   accessor: (rowData, i) => rowData?.estimated_duration,
      // },
      {
        Header: "Currency",
        id: "currency",
        accessor: "currency",
      },
      {
        Header: "Total amount",
        id: "total_amount",
        accessor: (row, i) => row.total_amount,
      },
      // {
      //   Header: "Vizient Member",
      //   id: "vizient_member",
      //   accessor: (rowData, i) => (
      //     <Checkbox
      //       assistiveText={{
      //         label: "Default",
      //       }}
      //       id="checkbox-example"
      //       onChange={(e) => {
      //         console.log("onChange ", e.target);
      //       }}
      //     />
      //   ),
      // },

      // {
      //   Header: "Email",
      //   id: "email",
      //   accessor: (row, i) =>
      //     row?.contacts?.find((contact) => contact.is_primary === true)
      //       ?.email || row?.contacts[0]?.email,
      // },
      // {
      //   Header: "Phone",
      //   id: "phone",
      //   accessor: (row, i) =>
      //     row?.contacts?.find((contact) => contact.is_primary)?.phone_number ||
      //     row?.contacts[0]?.phone_number,
      // },
      // {
      //   Header: "Start date",
      //   id: "start_date",
      //   accessor: (row, i) => (
      //     <Moment format="DD-MMM-YYYY">{row.start_date}</Moment>
      //   ),
      // },
      // {
      //   Header: "Kick-off call",
      //   id: "kickoff_call",
      //   accessor: (row, i) => (
      //     <Moment format="DD-MMM-YYYY">{row.kickoff_call}</Moment>
      //   ),
      // },
    ],
    []
  );

  const [rows, setRows] = useState([]);
  const [updateRows, setUpdateRows] = useState([]);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({ by: "created_at", type: "desc" });
  const [view, setView] = useState(true);
  const itemsData = useMemo(() => rows.map((item) => ({ ...item })), [rows]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns: dataColumns,
      data: itemsData,
      initialState: { pageIndex: data?.opportunitiesBy?.pagination?.skip || 0 },
      manualPagination: true,
      pageCount: data?.opportunitiesBy?.pagination?.total
        ? Math.ceil(data?.opportunitiesBy?.pagination?.total / 10)
        : 0,
    },
    usePagination
  );

  useEffect(() => {
    if (data) {
      setRows(data.opportunitiesBy?.opportunities);
    }
  }, [data]);

  useEffect(() => {
    const variables = {
      where: {
        search: search,
        country: filterByCountry,
        created_by: Number(filterByUser),
      },
      skip: pageIndex * pageSize,
      limit: pageSize,
      sort: sort,
    };

    if (variables.where.country === "ALL") {
      delete variables.where.country;
    } else {
      variables.skip = 0;
    }

    if (variables.where.created_by === 0) {
      delete variables.where.created_by;
    } else {
      variables.skip = 0;
    }

    if (!search || search === "") {
      delete variables.where.search;
    }

    if (!sort) {
      delete variables.sort;
    }

    fetchMore({
      variables,
    }).then((res) => {
      setRows(res?.data.opportunitiesBy.opportunities);
    });
  }, [pageIndex, pageSize, search, sort, filterByUser, filterByCountry]);

  const clickHandler = (column) => {
    let type, by;

    if (sortable.indexOf(column) > -1) {
      if (!sort || (sort && sort.by === "id") || sort.by !== column) {
        type = "desc";
        by = column;
      } else if (sort.type === "desc") {
        type = "asc";
        by = column;
      } else {
        type = "desc";
        by = "id";
      }

      setSort({ by, type });
    }
  };

  const onFieldChange = (rowId, field) => (e) => {
    let obj = {};
    obj[field] = e.target.value;
    updateOpportunity({
      variables: {
        where: { id: Number(rowId) },
        data: obj,
      },
      refetchQueries: [{ query: OPPORTUNITIES }],
    });
  };

  let board;
  useEffect(() => {
    setInitialBoard(board);
    if (data) {
      renderBoard(data?.opportunitiesBy?.opportunities);
    }
  }, [data]);

  const [searchValue, setSearchValue] = useState("");

  const searchOpportunity = (e) => {
    e.preventDefault();
    filterBoardByName(searchValue);
  };

  const actions = () => (
    <PageHeaderControl>
      {/* <ButtonGroup>
        <ButtonStateful
          assistiveText={{ icon: view ? "List View" : "Kanban View" }}
          aria-pressed={view}
          icon={<ButtonIcon name="list" />}
          onClick={() => setView(!view)}
          variant="icon-filled"
          active={view}
        />
      </ButtonGroup> */}
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <form onSubmit={searchOpportunity}>
          <Input
            placeholder="Search.."
            onChange={({ target }) => setSearchValue(target.value)}
            style={{ height: "40px !important" }}
          />
        </form>
      </ButtonGroup>

      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <Button
          variant="brand"
          disabled={loading}
          label={loading ? "Loading..." : "Search"}
          onClick={searchOpportunity}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  const toggleModal = () =>
    handleModal(
      <OpportunityForm refetch={refetch} companies={companiesData.companies} />,
      "Add Opportunity"
    );

  function renderBoard(arr) {
    let columns = [];
    boardStages.map((stage, i) => {
      columns.push({
        id: i,
        title: stage,
        cards: _.filter(arr, { stage: stage }),
      });
    });
    // Initialize board
    board = {
      columns: columns,
    };
    setInitialBoard(board);
  }

  function filterBoardByName(val) {
    setSearch(val);
    if (val == "") {
      renderBoard(data.opportunitiesBy.opportunities);
    } else {
      let arr = [];
      data.opportunitiesBy.opportunities.map((opportunity) => {
        if (opportunity.name.toLowerCase().includes(val.toLowerCase())) {
          arr.push(opportunity);
        }
      });
      renderBoard(arr);
    }
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <BrandBand
      id="brand-band-lightning-blue"
      className="slds-p-around_small"
      theme="lightning-blue"
    >
      <PageHeader
        icon={
          <Icon
            assistiveText={{ label: "User" }}
            category="standard"
            name="opportunity"
          />
        }
        info={`${data.opportunitiesBy.pagination.total} opportunities`}
        label="OPPORTUNITIES"
        title={`Opportunities`}
        variant="object-home"
        truncate
        onRenderActions={actions}
        // onRenderControls={}
      />
      {!view ? (
        <div className="slds-box slds-stages slds-p-vertical_none ">
          <div className="slds-grid slds-p-horizontal_none slds-gutters">
            {initialBoard && (
              <Board
                allowAddColumn={false}
                disableColumnDrag={true}
                onCardDragEnd={(card, source, destination) => {
                  let index =
                    initialBoard.columns[source.fromColumnId].cards.indexOf(
                      card
                    );
                  initialBoard.columns[source.fromColumnId].cards.splice(
                    index,
                    1
                  );

                  let stage = initialBoard.columns[destination.toColumnId];
                  updateOpportunity({
                    variables: {
                      where: { id: parseInt(card.id) },
                      data: { stage: stage.title },
                    },
                  });
                  let column = initialBoard.columns.find((el) => {
                    return el.title === stage.title;
                  });

                  column.cards.push(card);
                }}
                renderColumnHeader={({ title, cards }) => (
                  <div className="stage-header">
                    <h2>{title}</h2>
                    {
                      <h4>
                        $
                        {cards.reduce(function (total, item) {
                          if (item.proposals.length > 0) {
                            item.proposals
                              .slice(-1)[0]
                              .proposal_items.reduce((t, m) => {
                                total = total + m.calculated_price;
                              }, 0);
                            return total;
                          } else {
                            return 0;
                          }
                        }, 0)}
                      </h4>
                    }
                  </div>
                )}
                renderCard={(
                  {
                    id,
                    name,
                    description,
                    location,
                    proposals,
                    created_by,
                    start_date,
                  },
                  { removeCard, dragging }
                ) => (
                  <Card
                    id={id}
                    created_by={created_by}
                    proposals={proposals}
                    location={location}
                    start_date={start_date}
                    title={name}
                    company={description}
                    dragging={dragging}
                  >
                    <button type="button" onClick={removeCard}>
                      Remove Card
                    </button>
                  </Card>
                )}
              >
                {initialBoard}
              </Board>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#fff",
            overflowX: "auto",
          }}
        >
          <table {...getTableProps()} className="bba-data-table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        onClick={() => clickHandler(column.id)}
                        style={
                          sortable.indexOf(column.id) > -1
                            ? { cursor: "pointer" }
                            : {}
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            maxWidth: "100px",
                            minWidth: "50px",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            {column.render("Header")}
                          </div>
                          {sort && (
                            <div style={{ width: "24px", margin: "auto" }}>
                              {sort.by === column.id ? (
                                sort.type === "desc" ? (
                                  <img
                                    src="icons/custom/chevron-down.svg"
                                    width="16"
                                  />
                                ) : sort.type === "asc" ? (
                                  <img
                                    src="icons/custom/chevron-up.svg"
                                    width="16"
                                  />
                                ) : (
                                  ""
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>

                        {column.id === "created_by" && (
                          <div style={{ marginTop: "4px" }}>
                            <select
                              value={userSettings?.filters?.created_by}
                              name="filter-created-by"
                              onChange={({ target }) => {
                                setUserFilter(target.value);
                                return userSettings?.updateCookie({
                                  ...userSettings,
                                  filters: {
                                    ...userSettings?.filters,
                                    created_by: Number(target.value),
                                  },
                                });
                              }}
                            >
                              <option value={0}>all</option>
                              {Array.from(createdByData)?.map((user) => (
                                <option key={user?.id} value={user?.id}>
                                  {user?.first_name
                                    ? `${user?.first_name} ${user?.last_name}`
                                    : user?.user_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}

                        {column.id === "country" && (
                          <div style={{ marginTop: "4px" }}>
                            <select
                              value={userSettings?.filters?.country}
                              onChange={({ target }) => {
                                setCountryFilter(target.value);
                                return userSettings?.updateCookie({
                                  ...userSettings,
                                  filters: {
                                    ...userSettings?.filters,
                                    country: target.value,
                                  },
                                });
                              }}
                            >
                              <option value={"ALL"}>all</option>
                              <option value="united states">
                                United States
                              </option>
                              <option value="australia">Australia</option>
                            </select>
                          </div>
                        )}
                      </th>
                    ))
                  }
                </tr>
              ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
              {
                // Loop over the table rows
                page?.map((row) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })
              }
              <tr>
                {loading ? (
                  // Use our custom loading state to show a loading indicator
                  <td colSpan="10000">Loading...</td>
                ) : (
                  <td colSpan="10000">
                    Showing {page.length} of ~
                    {data?.companiesBy?.pagination?.total} results
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>{" "}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {"<"}
            </button>{" "}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {">"}
            </button>{" "}
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </button>{" "}
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions?.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </BrandBand>
  );
}

function Stage(props) {
  const title = props.title;
  return (
    <div className="slds-col slds-p-horizontal_none stage">
      <h2 className="stage__title">{title}</h2>
      <h4 className="stage__total">$100</h4>
      <div className="slds-box">
        <Card
          title="Hey"
          company="Whoa"
          stage="Qualified"
          date_closed="10/17/2020"
        />
      </div>
    </div>
  );
}

function Card(props) {
  const title = props.title;
  const company = props.location.company.id;
  const created_by = props.created_by;
  let value = 0;

  if (props.proposals.length > 0) {
    const arr = props.proposals.slice(-1)[0];
    value = arr.proposal_items.reduce((t, m) => {
      return (t = t + m.calculated_price);
    }, 0);
  }

  const start_date = props.start_date;
  return (
    <div className="slds-box card">
      <Link to={`/companies/${company}/opportunities/${props.id}`}>
        {title}
      </Link>
      <p>
        Created by:{" "}
        {created_by.first_name !== ""
          ? `${created_by.first_name} ${created_by.last_name}`
          : created_by.user_name}
      </p>
      <p>${value}</p>
      <p>
        Expected start date: <Moment date={start_date} format="DD-MMM-YYYY" />
      </p>
    </div>
  );
}
