import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { Button } from "@salesforce/design-system-react";
import { useHistory } from "react-router";

const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity($where: ProjectWhere!, $data: ProjectUpdate!) {
    updateProject(where: $where, data: $data) {
      id
      stage
    }
  }
`;

const stages = [
  "Ready to be Scheduled",
  "Scheduled",
  "In Progress",
  "Blocked",
  "Ready to Invoice",
];

const PROJECT_REPORT_MUTATION = gql`
  mutation GenerateReport($projectId: String, $reportId: String, $data: JSON!) {
    projectGDocsReport(data: $data, projectId: $projectId, reportId: $reportId)
  }
`;

export function JourneyStages(props) {
  const history = useHistory();

  const [opportunity, setOpportunity] = useState(props.opportunity);
  const [updateOpportunity, { oppUpdate }] = useMutation(UPDATE_OPPORTUNITY);
  const [currentStage, setCurrentStage] = useState(0);
  useEffect(() => {
    if (props.opportunity) {
      setOpportunity(props.opportunity);
      setCurrentStage(stages.indexOf(props.opportunity.stage));
    }
  }, [props]);

  function updateStage(stage) {
    updateOpportunity({
      variables: {
        where: { id: parseInt(opportunity.id) },
        data: { stage: stage },
      },
    });
    setCurrentStage(stages.indexOf(stage));
  }

  const generateReport = () =>
    history.push(`/projects/${props?.opportunity?.id}/report`);

  const [generateDocReport, { data, loading, error }] = useMutation(
    PROJECT_REPORT_MUTATION
  );

  const generateDoc = () => {
    //console.log({ reportData });
    generateDocReport({
      variables: {
        data: {},
        projectId: opportunity?.id,
        reportId: opportunity?.report_id,
      },
    });
  };

  const isCompleted = props.opportunity?.equipments
    ?.map((x) => x?.status === "COMPLETED")
    .reduce((res, x) => res & x, true);

  return (
    <div className="slds-grid slds-wrap slds-m-top_medium">
      <div className="slds-col slds-col slds-size_3-of-3">
        <div className="slds-path slds-box" style={{ position: "relative" }}>
          <div className="slds-grid slds-path__track">
            <div className="slds-grid slds-path__scroller-container">
              <div className="slds-path__scroller" role="application">
                <div className="slds-path__scroller_inner">
                  <ul
                    className="slds-path__nav"
                    role="listbox"
                    aria-orientation="horizontal"
                  >
                    {stages.map((stage, i) => {
                      return (
                        <li
                          className={`slds-path__item ${
                            currentStage > i
                              ? "slds-is-complete"
                              : "slds-is-incomplete"
                          } ${currentStage === i ? "slds-is-active" : ""}`}
                          role="presentation"
                          onClick={() => updateStage(stage)}
                        >
                          <a
                            aria-selected="false"
                            className="slds-path__link"
                            id="path-6"
                            role="option"
                            tabindex="-1"
                          >
                            <span className="slds-path__stage">
                              <span className="slds-assistive-text">
                                Stage Complete
                              </span>
                            </span>
                            <span className="slds-path__title">{stage}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="slds-grid slds-path__action">
              {/* <span className="slds-path__stage-name">Stage: Unqualified</span> */}

              {/* <Button onClick={generateReport}>Generate Report</Button> */}
              <Button onClick={generateDoc} disabled={loading}>
                {loading ? "wait..." : "Generate new Report"}
              </Button>
              {(data || opportunity?.report_id) && (
                <Button
                  onClick={() =>
                    window
                      .open(
                        `https://docs.google.com/document/d/${
                          data?.projectGDocsReport || opportunity?.report_id
                        }/edit`,
                        "_blank"
                      )
                      .focus()
                  }
                >
                  View Report
                </Button>
              )}
            </div>
          </div>
          {isCompleted && (
            <div
              style={{
                padding: "4px 8px",
                position: "absolute",
                top: -12,
                right: "50%",
                transform: "translate(50%, 0)",
                backgroundColor: "red",
                color: "white",
                fontSize: 10,
                borderRadius: 4,
                fontWeight: "bold",
              }}
            >
              Project is Not Finished
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
