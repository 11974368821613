import React, { useState } from "react";
import {
  Combobox,
  comboboxFilterAndLimit,
  Icon,
  IconSettings,
} from "@salesforce/design-system-react";

export function LocationCombobox(props) {
  const [inputValue, setInputValue] = useState("");
  const [selection, setSelection] = useState([]);

  const options = props.options.map((item) => ({
    label: item.location_name,
    id: item.id,
  }));
  const setSelect = (data) => {
    setSelection(data);
    setInputValue("");
  };

  return (
    <IconSettings iconPath="/icons">
      <Combobox
        id="combobox-inline-single"
        selection={selection}
        value={selection.length > 0 ? selection.label : inputValue}
        variant="inline-listbox"
        name="combobox"
        required
        labels={{ placeholder: "Search Locations" }}
        singleInputDisabled={props.readOnly}
        events={{
          onChange: (event, { value }) => setInputValue(value),
          onRequestRemoveSelectedOption: (event, data) => {
            setSelect(data.selection);
          },
          onSelect: (event, data) => {
            if (props.select) {
              props.select(data.selection[0]);
            }
            setSelect(data.selection);
          },
        }}
        options={comboboxFilterAndLimit({
          inputValue,
          selection,
          options,
        })}
        propTypes={{
          readOnly: true,
        }}
      />
    </IconSettings>
  );
}
