import React, { useState, useEffect } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";

import moment from "moment";
import {
  PageHeader,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  BrandBand,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import { JourneyStages } from "../projects/JourneyStages";
import { EquipmentList } from "../locations/EquipmentList";
import _ from "lodash";

// import { Storage } from "aws-amplify";
import ConfirmDelete from "../modals/ConfirmDelete";
import DELETE_EQUIPMENT from "../../graphql/mutations/deleteEquipment";

// css
import "./../global.css";

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteProject(where: { id: $id }) {
      id
    }
  }
`;

// async function getFile(name) {
//   const file = await Storage.get(`${name}`);
//   return file;
// }

const PROJECT_TYPES = [
  { id: "WALKTHROUGH", label: "Walkthrough" },
  { id: "DEMO", label: "Demo" },
  { id: "PAID_PROJECT", label: "Paid project" },
];

const SINGLE = gql`
  query GetProjectByID($id: ID!) {
    project(id: $id) {
      id
      report_id
      project_name
      estimated_duration
      stage
      is_opportunity
      total_amount
      currency
      start_date
      close_date
      kickoff_call
      project_type
      created_at
      updated_at
      description
      project_id
      contacts {
        id
        email
        phone_number
        contact_name
        company_title
        is_primary
      }
      created_by {
        id
        user_name
        first_name
        last_name
      }
      # notes {
      #   id
      #   created_by {
      #     id
      #     user_name
      #     first_name
      #     last_name
      #   }
      #   message
      #   created_at
      #   labels
      #   category
      # }
      proposals {
        id
        is_approved
        discount
        is_covid_pricing
        is_after_hours_pricing
        proposal_items {
          id
          cfm
          name
          building
          calculated_price

          equipment {
            id
          }
        }
      }
      assigned_technicians {
        id
        first_name
        last_name
        email
        role
        phone_number
        status
      }
      location {
        id
        location_name
        city
        state
        # equipments {
        #   id
        #   cfm
        #   name
        #   building
        #   calculated_price
        #   name
        #   location_on_site
        #   equipment_type {
        #     id
        #     type
        #   }
        # }
        company {
          id
          company_name
          country
        }
        contacts {
          id
          role
          contact {
            id
            email
            phone_number
            contact_name
          }
        }
      }
      equipments {
        id
        cfm
        lps
        name
        size
        building
        calculated_price
        status
        location_on_site

        # notes {
        #   id
        #   message
        # }
        equipment_type {
          id
          type
        }
      }
      attachments {
        id
        name
        notes
      }
    }
  }
`;

const CREATE_FILE = gql`
  mutation CreateFile($fileName: String!, $projectId: Int!, $userId: Int!) {
    createFile(
      input: {
        name: $fileName
        project_id: $projectId
        created_by: $userId
        notes: ""
        path: ""
      }
    ) {
      id
    }
  }
`;

const DELETE_ATTACHMENT = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(where: { id: $id }) {
      id
    }
  }
`;

export function ProjectDetail({ match }) {
  const [selection, setSelection] = useState([]);
  const [project, setProject] = useState(null);

  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: { id: match.params.projectId },
  });

  const [isOpen, setOpen] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(DELETE);

  // delete equipment mutation fire
  const [deleteEquipment] = useMutation(DELETE_EQUIPMENT);

  useEffect(() => {
    setSelection(
      PROJECT_TYPES.filter((type) => type.id === data?.project?.project_type)
    );
  }, [data]);

  useEffect(() => {
    if (data) {
      setProject(data?.project);
    }
  }, [data, project]);

  // remove single equipment from list
  const onSingleEquipmentRemove = async (id) => {
    try {
      const res = await deleteEquipment({
        variables: { id: id },
      });
      refetch();
    } catch (error) {
      console.log(" ==== ERROR ==== ", error.response);
    }
  };

  const [files, setFiles] = useState([]);

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser().then((user) => setAuthUser(user));
  // }, []);

  // useEffect(() => {
  //   (async () => {
  //     await data?.project?.attachments?.forEach(async (file) => {
  //       const awsFile = await getFile(file?.name);
  //       setFiles((files) => [...files, { ...file, url: awsFile }]);
  //     });
  //   })();
  // }, [data?.project?.attachments]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const opportunity = project;

  const details = [
    {
      label: "Price",
      content: <div></div>,
      truncate: true,
    },
    {
      label: "Created at",
      content: <p>{moment(opportunity?.created_at).format("DD-MMM-YYYY")}</p>,
    },
    {
      label: "Created by",
      content: (
        <a href="javascript:void(0);">{`${opportunity?.created_by.first_name} ${opportunity?.created_by.last_name}`}</a>
      ),
    },
    {
      label: "Expected start date",
      content: <p>{moment(opportunity?.start_date).format("DD-MMM-YYYY")}</p>,
      truncate: true,
    },
    {
      label: "Company",
      content: (
        <Link to={`/companies/${opportunity?.location?.company?.id}`}>
          {opportunity?.location?.company?.company_name || ""}
        </Link>
      ),
      truncate: true,
    },
    {
      label: "Location",
      content: (
        <Link
          to={`/companies/${opportunity?.location?.company?.id}/locations/${opportunity?.location?.id}`}
        >
          {opportunity?.location?.location_name || ""}
        </Link>
      ),
      truncate: true,
    },
    {
      label: "Region",
      content: <p>{opportunity?.location?.state}</p>,
      truncate: true,
    },
  ];

  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          details={details}
          icon={
            <Icon
              assistiveText={{ label: "User" }}
              category="standard"
              name="account"
            />
          }
          label="Project"
          // onRenderActions={actions}
          title={opportunity?.project_name}
          variant="record-home"
        />

        <JourneyStages opportunity={opportunity} />

        <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
          <div className="slds-col slds-col">
            <Tabs id="left" className="slds-var-p-around_small">
              {/* <TabsPanel label="Details">
                <ProjectForm
                  project={opportunity}
                  company={opportunity?.location?.company}
                  refetch={refetch}
                />
              </TabsPanel> */}

              <TabsPanel label="Equipment">
                {opportunity && (
                  <EquipmentList
                    equipment={opportunity?.equipments}
                    projectId={opportunity?.id}
                    refetch={refetch}
                    onDeleteEquipment={onSingleEquipmentRemove}
                    country={opportunity?.location?.company?.country}
                  />
                )}
              </TabsPanel>

              {/* Confirmed by Kostas, no need to proposals in the project */}
              {/* <TabsPanel label="Proposals">
                <ProposalTab
                  proposals={opportunity?.proposals}
                  equipment={opportunity?.location?.equipments}
                  refetch={refetch}
                  opportunityId={opportunity?.id}
                />
              </TabsPanel> */}

              {/* <TabsPanel label="Contacts">
                <ContactsTab
                  contacts={opportunity?.contacts}
                  id={opportunity?.id}
                  refetch={refetch}
                />
              </TabsPanel> */}

              {/* <TabsPanel label="Technicians">
                <TechniciansTab
                  technicians={opportunity?.assigned_technicians}
                  refetch={refetch}
                  project={opportunity?.id}
                />
              </TabsPanel> */}

              {/* <TabsPanel label="Attachments">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <input
                        type="file"
                        accept="image/*, .pdf, video/*"
                        onChange={(evt) => onChange(evt)}
                      />
                    </div>
                    <div>
                      {loadingFile && <div>Loading file...</div>}
                      {uploadingFile && <div>Uploading file...</div>}
                    </div>
                  </div>

                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {files?.map((file) => (
                      <div
                        key={file}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px",
                          margin: "16px",
                        }}
                      >
                        <a href={file?.url} target="_blank">
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "4px",
                              backgroundImage: `url(${file?.url}), url(/file.png)`,
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              border: "1px solid grey",
                              height: "220px",
                              width: "180px",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                marginTop: "auto",
                                backgroundColor: "#000000aa",
                                padding: "8px",
                                color: "#fff",
                                textDecoration: "none",
                              }}
                            >
                              {file?.notes || file?.name}
                            </div>
                          </div>
                        </a>
                        <button
                          onClick={() => onRemoveAttachment(file.id)}
                          className="btn-image-remove"
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </TabsPanel>
             */}
            </Tabs>
          </div>
          {/* <div className="slds-col slds-col slds-size_1-of-3">
            <Tabs id="right" className="slds-var-p-around_small">
              <TabsPanel label="Notes">
                <NotesTab
                  opportunity={opportunity?.id}
                  notes={_.filter(
                    opportunity?.notes,
                    (n) => n.labels.length === 0
                  )}
                  refetch={refetch}
                />
              </TabsPanel>
              <TabsPanel label="Change Request">
                <ChangeRequestTab
                  opportunity={opportunity?.id}
                  notes={_.filter(
                    opportunity?.notes,
                    (n) => n.labels.length > 0
                  )}
                  refetch={refetch}
                />
              </TabsPanel>
            </Tabs>
          </div> */}
        </div>
        <ConfirmDelete
          isOpen={isOpen}
          toggleOpen={setOpen}
          action={deleteMutation}
          variables={{ id: Number(opportunity?.id) }}
          loading={deleting}
          url={`/opportunities`}
          message={`${opportunity?.project_name} Project and all related Proposals, Equipment and Data will be permanently deleted and you won't be able to recover them.  `}
        />
      </BrandBand>
    </IconSettings>
  );
}
