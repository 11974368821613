import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "@salesforce/design-system-react";
// import { states } from "../../utilities/states-list";
import { ModalContext } from "../ModalContext";
import { useMutation } from "@apollo/client";
import { Auth } from "aws-amplify";
import USERS from "../../graphql/queries/users";
import ADD_USER from "../../graphql/mutations/createUser";
// import DELETE_USER from "../../graphql/mutations/deleteUser";
import { countries } from "../../utilities/states-list";
import moment from "moment";

const roles = ["Technician", "User", "Project Manager"];
const departments = ["Admins", "Sales", "Technical", "Projects Management"];

export function AddUserForm(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [addUser, { error }] = useMutation(ADD_USER);

  // const [deleteUser] = useMutation(DELETE_USER);
  const { register, handleSubmit, control } = useForm();
  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  async function onSubmit(data, event) {
    let createdUserId;

    data.date_of_birth = data.date_of_birth.valueAsNumber;
    data.hiring_date = data.hiring_date.valueAsNumber;
    data.role = data?.role?.replace(" ", "_").toUpperCase();
    data.department = data?.department?.replace(" ", "_")?.toUpperCase();

    try {
      await addUser({
        variables: { input: data },
        refetchQueries: [{ query: USERS }],
      }).then(async (res) => {
        createdUserId = res.data.createUser.id;

        await Auth.signUp({
          username: data.user_name,
          password: "password", // TODO! fix this
          attributes: {
            email: data.email,
            "custom:userId": createdUserId,
          },
        });

        props.refetch();
        handleModal();
      });
    } catch (error) {
      // if (createdUserId) {
      //   deleteUser({ variables: { id: createdUserId } });
      // }
      // should probably set something about a user creation error in the modal at this point
      console.log("Error creating admin: ", error);
    }
  }

  const currentDate = moment().format("Y-MM-DD");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="first_name">
              First Name *
              <Input
                name="first_name"
                type="text"
                ref={register}
                required={true}
              />
            </label>
          }
          className="slds-col"
          control={control}
          name="first_name"
          defaultValue={""}
        />
        <Controller
          as={
            <label htmlFor="last_name">
              Last Name *
              <Input name="last_name" type="text" ref={register} required />
            </label>
          }
          className="slds-col"
          control={control}
          name="last_name"
          defaultValue={""}
        />
      </section>
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="user_name">
              Username *
              <Input name="user_name" type="text" ref={register} required />
            </label>
          }
          className="slds-col"
          control={control}
          name="user_name"
          defaultValue={""}
        />
        <Controller
          as={
            <label htmlFor="date_of_birth">
              Date of Birth
              <Input
                name="date_of_birth"
                type="date"
                ref={register}
                maxValue={currentDate}
              />
            </label>
          }
          className="slds-col"
          control={control}
          name="date_of_birth"
          defaultValue={""}
        />
      </section>
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="phone_number">
              Phone Number
              <Input name="phone_number" type="tel" ref={register} />
            </label>
          }
          className="slds-col"
          control={control}
          name="phone_number"
          defaultValue={""}
        />
        <Controller
          as={
            <label htmlFor="email">
              Email *
              <Input name="email" type="email" ref={register} required />
            </label>
          }
          className="slds-col"
          control={control}
          name="email"
          defaultValue={""}
        />
      </section>
      <Controller
        as={
          <label htmlFor="address">
            Address
            <Input name="address" type="text" ref={register} />
          </label>
        }
        control={control}
        name="address"
        defaultValue={""}
      />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="city">
              City
              <Input name="city" type="text" ref={register} />
            </label>
          }
          className="slds-col"
          control={control}
          name="city"
          defaultValue={""}
        />
        <Controller
          as={
            <label htmlFor="postal_code">
              Postal Code
              <Input name="postal_code" type="text" ref={register} />
            </label>
          }
          className="slds-col slds-size_1-of-2"
          control={control}
          name="postal_code"
          defaultValue={""}
        />
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="country">
              Country *<br />
              <select
                name="country"
                ref={register}
                onChange={({ target }) =>
                  setSelectedStates(countries[target.value])
                }
              >
                <option value={"united states"} selected>
                  United states
                </option>
                <option value={"australia"}>Australia</option>
              </select>
            </label>
          }
          control={control}
          name="country"
          defaultValue={"united states"}
        />
        <br />
        <Controller
          as={
            <label htmlFor="state">
              State
              <br />
              <select name="state" ref={register}>
                {selectedStates.map((state) => {
                  return (
                    <option key={state.State} value={state.State}>
                      {state.State}
                    </option>
                  );
                })}
              </select>
            </label>
          }
          control={control}
          name="state"
          defaultValue={"Texas"}
        />
        <br />
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <div className="slds-col">
          <Controller
            as={
              <label>
                Department *
                <select
                  style={{ marginLeft: "18px" }}
                  name="department"
                  ref={register}
                >
                  {departments.map((department, i) => {
                    return (
                      <option className="slds-col" key={i} value={department}>
                        {department}
                      </option>
                    );
                  })}
                </select>
              </label>
            }
            control={control}
            name="department"
            defaultValue={"Department"}
          />
        </div>
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <div className="slds-col">
          <Controller
            as={
              <label>
                Role
                <select
                  style={{ marginLeft: "18px" }}
                  name="role"
                  ref={register}
                >
                  {roles.map((role, i) => {
                    return (
                      <option key={i} value={role}>
                        {role}
                      </option>
                    );
                  })}
                </select>
              </label>
            }
            control={control}
            name="role"
            defaultValue={"Technician"}
          />
        </div>
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <div>
              <label>Gender *</label>
              <label className="slds-col" for="male">
                Male
              </label>
              <input
                type="radio"
                id="male"
                name="gender"
                value="MALE"
                checked
              />
              <label className="slds-col" for="female">
                Female
              </label>
              <input type="radio" id="female" name="gender" value="FEMALE" />
            </div>
          }
          className="slds-col"
          control={control}
          name="gender"
          defaultValue={"MALE"}
        />
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <div>
              <label>Status *</label>
              <label className="slds-col" for="active">
                Active
              </label>
              <input name="status" type="radio" value="active" checked />
              <label className="slds-col" for="inactive">
                Inactive
              </label>
              <input name="status" type="radio" value="inactive" />
            </div>
          }
          className="slds-col"
          control={control}
          name="status"
          defaultValue={"active"}
        />
      </section>
      <br />
      <section className="slds-grid slds-gutters">
        <Controller
          as={
            <label htmlFor="hiring_date">
              Hiring Date
              <Input name="hiring_date" type="date" ref={register} />
            </label>
          }
          className="slds-col"
          control={control}
          name="hiring_date"
          defaultValue={""}
        />
      </section>
      <br />
      {/* <Controller
        as={
          <label htmlFor="password">
            Password
            <Input
              onChange={(e) => handlePassword(e.target.value)}
              name="password"
              type="password"
              ref={register}
              required
            />
          </label>
        }
        className="slds-col"
        control={control}
        name="password"
        defaultValue={""}
      />
      <br />
      <Controller
        as={
          <label htmlFor="confirmPassword" style={{'padding-right': '324px'}}>
            Confirm Password {passwordMatch() ? '' : <span style={{'color': 'red'}}>Passwords do not match</span>}
            <Input
              onChange={(e) => handleConfirmPassword(e.target.value)}
              name="password"
              type="password"
              ref={register}
              required
            />
          </label>
        }
        className="slds-col"
        control={control}
        name="password"
        defaultValue={""}
      />
      <br /> */}
      <Input
        type="submit"
        value="Save"
        // disabled={!passwordMatch()}
      />

      {error && (
        <p style={{ color: "red", fontSize: "11px", margin: "4px" }}>
          {error.message}
        </p>
      )}
    </form>
  );
}
