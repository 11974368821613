import React from "react";
import { Button, Modal } from "@salesforce/design-system-react";
import { useHistory } from "react-router-dom";

export default function ConfirmDelete({
  isOpen,
  toggleOpen,
  action,
  variables,
  loading,
  url,
  message,
}) {
  const history = useHistory();

  return (
    <Modal
      footer={[
        <Button onClick={() => toggleOpen(false)} label="Cancel" />,
        <Button
          key="promptBtn"
          variant="destructive"
          label={loading ? "Deleting..." : "Confirm"}
          onClick={() => {
            action({ variables }).then(() => {
              if (url) {
                window.location.replace(url);
              } else {
                window.history.back();
              }
              toggleOpen(false);
            });
          }}
        />,
      ]}
      isOpen={isOpen}
      onRequestClose={() => toggleOpen(false)}
      prompt="error"
      size="medium"
      title={<span>Delete Confirmation</span>}
    >
      <div className="slds-m-around_medium text-formatting">{message}</div>
    </Modal>
  );
}
