import React from "react";
import { Document, StyleSheet, Font } from "@react-pdf/renderer";
import { ServiceSummary } from "./ServiceSummary";
import { InfoPage } from "./InfoPage";
import CoverPage from "./CoverPage";
import { PDFViewer } from "@react-pdf/renderer";
import SINGLE, { ProjectByID } from "../../graphql/queries/single-proposal";
import { useQuery } from "@apollo/client";
import { BackPage } from "./BackPage";
import HelveticaNeueLight from "./fonts/Helvetica-Neue-Light.ttf";
import HelveticaNeueBold from "./fonts/Helvetica-Neue-bold.ttf";

// Register font
Font.register({
  family: "Helvetica Neue",
  fonts: [
    {
      format: "truetype",
      src: HelveticaNeueLight,
      fontWeight: "light",
    },
    {
      format: "truetype",
      src: HelveticaNeueBold,
      fontWeight: "bold",
    },
  ],
});

export function ProposalDetail({ props, match }) {
  const id = match.params?.opportunityId || match.params?.projectId;

  const proposalId = match.params.proposalId;

  const QUERY = match.params?.opportunityId ? SINGLE : ProjectByID;

  const { loading, error, data } = useQuery(QUERY, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const res = data?.opportunity
    ? { ...data?.opportunity }
    : { ...data?.project };

  if (res && res?.project_name) {
    res["name"] = res?.project_name;
  }

  const currency =
    data?.opportunity?.location?.company?.country === "australia"
      ? "AUD"
      : "USD";

  const pageSize =
    data?.opportunity?.location?.company?.country?.toLowerCase() !== "australia"
      ? "LETTER"
      : "A4";

  return (
    <PDFViewer width="100%" height="1000" className="app">
      <Document>
        <CoverPage
          data={res}
          proposal={proposalId}
          pageNumber={1}
          currency={currency}
          pageSize={pageSize}
        />
        <ServiceSummary
          data={res}
          proposal={proposalId}
          pageNumber={2}
          currency={currency}
          pageSize={pageSize}
        />
        <InfoPage
          data={res}
          proposal={proposalId}
          pageNumber={3}
          pageSize={pageSize}
        />
        <BackPage
          data={res}
          proposal={proposalId}
          pageNumber={4}
          pageSize={pageSize}
        />
      </Document>
    </PDFViewer>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    position: "relative",
    flexDirection: "column",
  },
});
