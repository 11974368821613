import React, { useState } from "react";
import { Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import TableRows from "./TableRows";
import TotalRow from "./TotalRow";
import { ProposalPageHeader } from "./ProposalPageHeader";
import _ from "lodash";
import moment from "moment";

export function ServiceSummary({
  data,
  proposal: proposalId,
  pageNumber,
  currency,
  pageSize,
}) {
  const proposal = data.proposals.find((i) => i.id == proposalId);
  let temp = [...proposal.proposal_items];

  let arr = [];
  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  });

  let items = temp
    ?.map((x) => x.name)
    ?.sort(collator.compare)
    ?.map((x) => temp?.find((y) => y.name === x));

  items
    .sort((a, b) => {
      if (a.building < b.building) return -1;
      else return 1;
    })
    .map((item, i) => {
      let index = Math.floor(i / 18);

      if (arr[index]) {
        arr[index].push(item);
      } else {
        arr[index] = [item];
      }
    });
  const [pages, setPages] = useState(arr);

  let beforeDisTotal = items.reduce((a, c) => {
    return a + c.calculated_price;
  }, 0);

  let subtotal =
    Math.round((beforeDisTotal - beforeDisTotal * proposal.discount) * 100) /
    100;

  let total =
    Math.round(
      ((Number(proposal.other_costs) || 0) +
        beforeDisTotal -
        beforeDisTotal * proposal.discount) *
        100
    ) / 100;

  return (
    <>
      {pages.map((page, i) => {
        return (
          <Page size={pageSize} style={styles.page} key={"page-no-" + i}>
            <ProposalPageHeader
              project={data}
              date={moment(data.start_date).format("DD-MMM-YYYY")}
            />
            <View style={{ display: "flex", flex: 1, paddingHorizontal: 10 }}>
              {i == 0 && (
                <>
                  <Text
                    style={{
                      marginBottom: 5,
                      color: "#189DD8",
                      textTransform: "uppercase",
                      fontSize: 20,
                    }}
                  >
                    Pricing
                  </Text>
                  <Text
                    style={{ color: "#454545", fontSize: 10, marginBottom: 12 }}
                  >
                    {`${items.length} units:`}
                  </Text>
                </>
              )}

              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <Text style={styles.cell}>Building</Text>
                  <Text style={styles.cell}>Location</Text>
                  <Text style={styles.cell}>Unit Name</Text>
                  <Text style={styles.cell}>Size</Text>
                  <Text style={styles.cell}>
                    {data?.location?.company?.country === "australia"
                      ? "LPS"
                      : "CFM"}
                  </Text>
                  <Text style={styles.cell}>Price</Text>
                  {/* <Text style={styles.cell}>Extended</Text> */}
                </View>
                <TableRows
                  items={page}
                  country={data?.location?.company?.country}
                  discount={proposal?.discount}
                />
                {i == pages.length - 1 && (
                  <>
                    <TotalRow
                      value={subtotal}
                      label={"Sub-Total"}
                      currency={""}
                      main={false}
                    />

                    <TotalRow
                      value={proposal?.other_costs}
                      label={proposal?.other_costs_label || "Additional Costs"}
                      currency={""}
                      main={false}
                    />

                    {currency === "AUD" && (
                      <>
                        <TotalRow
                          value={total}
                          label={`Total (ex GST)`}
                          main={false}
                          currency={""}
                        />

                        <TotalRow
                          value={total * 0.1}
                          label={`GST`}
                          main={false}
                          currency={""}
                        />
                      </>
                    )}

                    <TotalRow
                      value={currency === "AUD" ? total + total * 0.1 : total}
                      label={`Total ${currency === "AUD" ? "(inc GST)" : ""}`}
                      main={true}
                      currency={currency}
                    />
                  </>
                )}
              </View>
            </View>
            {i == pages.length - 1 && (
              <View
                style={{
                  marginTop: "auto",
                  marginHorizontal: 10,
                  paddingBottom: 20,
                  marginBottom: 20,
                  fontSize: 10,
                  borderBottomColor: "#323232",
                  borderBottomWidth: 0.6,
                  color: "#565656",
                }}
              >
                <Text>
                  *Pricing includes the cost of service, formulation, technician
                  costs, and equipment. Pricing does not include any applicable
                  sales tax. This pricing is for service during the normal
                  business hours; for nights and weekends, additional charges
                  will apply.
                </Text>
              </View>
            )}
            <Text style={styles.pageNumber}>{pageNumber}</Text>
          </Page>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica Neue",
    position: "relative",
    flexDirection: "column",
  },
  title_container: {
    flexDirection: "row",
    padding: 12,
  },
  title: {
    textTransform: "uppercase",
    color: "#189DD8",
  },
  table: {},
  disclaimer: {
    left: 60,
    right: 60,
    position: "absolute",
    bottom: 80,
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: "row",
    color: "#189DD8",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    textTransform: "uppercase",
    alignItems: "center",
  },
  cell: {
    backgroundColor: "#189DD8",
    color: "#ffffff",
    width: "16.65%",
    fontWeight: "bold",
    padding: 6,
  },
  pageNumber: {
    position: "absolute",
    right: 15,
    bottom: 15,
    fontSize: 10,
    backgroundColor: "#ffffff",
    color: "#6c6c6c",
  },
});
