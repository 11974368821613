import { combineReducers } from "redux";

import locationReducer from "./location/locationReducer";
import opportunityReducer from "./opportunity/opportunityReducer";
import contactsReducer from "./contacts/contactsReducer";
import companiesReducer from "./companies/companiesReducer";
import userReducer from "./user/userReducer";

const rootReducer = combineReducers({
  location: locationReducer,
  opportunity: opportunityReducer,
  company: companiesReducer,
  contacts: contactsReducer,
  user: userReducer,
});

export default rootReducer;
