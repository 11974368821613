import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useContext } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ModalContext } from '../ModalContext'

const READ_NOTE = gql`
mutation ReadNote($id: ID!){
  updateNote(where: {
    id:$id
  }, data: {
    unread:false
  }){
    id
  }
}
`


export default function ViewNote(props) {

    const [updateNote] = useMutation(READ_NOTE) 
    const { handleModal } = useContext(ModalContext)
    
    useEffect(() => {
        updateNote({
            variables: {
                id: props?.data?.id
            }
        }).then(() => props?.refetch())
    }, [])
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <p>
                {props?.data?.comment}
            </p>
            
            <div style={{display: 'flex', flex: 1, marginTop: '16px', paddingTop: '8px', borderTop: '1px solid #dddbda', fontSize: '11px'}} onClick={() => handleModal()}>
                <div style={{ flex: 1 }} >Project: <br /><Link  to={`/projects/${props?.data?.equipment?.project?.id}`}><strong>{props?.data?.equipment?.project?.project_name}</strong></Link></div>
                <div style={{flex: 1}}>Equipment: <br /><Link  to={`/equipment/${props?.data?.equipment?.id}`}><strong>{props?.data?.equipment?.system_name}</strong></Link></div>
                <div style={{flex: 1}}>By: <Link  to={`/users/${props?.data?.user?.id}?r=${Math.random()}`}><strong>{props?.data?.user?.user_name}</strong></Link>, <br /><strong>{moment(props?.data?.timePosted)?.fromNow()}</strong></div>
            </div>
        </div>
    )
}