import React from "react";
import { Page, Image, View, Text, StyleSheet, Link } from "@react-pdf/renderer";
import { ProposalFooter } from "./ProposalFooter";
import { ProposalHeader } from "./ProposalHeader";
import moment from "moment";
import bg from "../../pr_bg.png";
import hashtag from "./images/PROPOSAL_NUMBER.png";
import address from "./images/PROJECT_ADDRESS.png";
import totalImg from "./images/PROJECT_TOTAL.png";
import serviceDate from "./images/SERVICE_DATE.png";
import projectDescription from "./images/PROJECT_DESCRIPTION.png";
let dollarUS = Intl.NumberFormat("en-US", {
  style: "decimal",
});
const CoverPage = ({ data, proposal: proposalId, currency, pageSize }) => {
  const proposal = data.proposals.find((prop) => prop.id == proposalId);
  const items = proposal.proposal_items;

  let contact = data?.contacts?.find((x) => x.is_primary === true);

  if (!contact && Array.isArray(data?.contacts) && data?.contacts?.length > 0) {
    contact = data?.contacts[0];
  } else {
    contact = data?.location?.company?.contacts?.find(
      (x) => x.is_primary === true
    );

    if (
      !contact &&
      Array.isArray(data?.location?.company?.contacts) &&
      data?.location?.company?.contacts?.length > 0
    ) {
      contact = data?.location?.company?.contacts[0];
    }
  }

  let beforeDisTotal = items.reduce((a, c) => {
    return a + c.calculated_price;
  }, 0);

  let total = dollarUS.format(
    Math.round(
      ((Number(proposal.other_costs) || 0) +
        beforeDisTotal -
        beforeDisTotal * proposal.discount) *
        100
    ) / 100
  );

  const country = data.location.country || data.location.company.country;
  return (
    <Page size={pageSize} style={styles.page}>
      <ProposalHeader
        project={data}
        date={moment(proposal.created_at).format("DD-MMM-YYYY")}
      />
      <View style={styles.imageBox}>
        <Image
          style={{ height: 335, width: "auto", objectFit: "fill" }}
          src={bg}
        />
        <View style={styles.imageText}>
          <Text style={{ fontWeight: "light", fontFamily: "Helvetica Neue" }}>
            PROPOSAL
          </Text>
        </View>
      </View>
      <View style={{ padding: 12 }}>
        <View style={styles.coverBox}>
          <View style={styles.leftPart}>
            <View style={styles.leftTop}>
              <View style={styles.preparedFor}>
                <Text style={styles.title}>Prepared For:</Text>
                <Text style={styles.textLabel}>
                  {data.location.company.company_name}
                </Text>
                <Text style={styles.textLabel}>{contact?.contact_name} </Text>
                <Link src={`mailto:${contact?.email}`}>
                  <Text style={styles.textLabel}>{contact?.email}</Text>
                </Link>
                <Text style={styles.textLabel}>{contact?.phone_number}</Text>
              </View>
              <View style={styles.preparedBy}>
                <Text style={styles.title}>Prepared By:</Text>
                <Text style={styles.textLabel}>Blue Box Air, LLC</Text>
                <Text style={styles.textLabel}>
                  {proposal.created_by.first_name}{" "}
                  {proposal.created_by.last_name}
                </Text>
                <Link src={`mailto:${proposal.created_by.email}`}>
                  <Text style={styles.textLabel}>
                    {proposal.created_by.email}
                  </Text>
                </Link>

                <Text style={styles.textLabel}>
                  {proposal.created_by.phone_number}
                </Text>
              </View>
            </View>
            <View style={styles.leftBottom}>
              <Text style={styles.title}>Service Summary</Text>
              <Text style={styles.content}>
                Blue Box is a patented process designed to clean any size HVAC
                coil and bring systems back to peak efficiency. Our service
                requires NO DOWNTIME and can be scheduled during normal business
                hours.
              </Text>
              <Text style={[styles.content, { marginBottom: 0 }]}>
                We use an anti-microbial enzyme that is applied with low
                pressure, has no odor, is pH
              </Text>
              <Text style={styles.content}>
                neutral, non-toxic, non-corrosive and safe for down drain
                disposal. We keep the system on while the enzyme is applied in a
                foam phase and use the air flow to migrate the enzymes through
                any depth of coil. We push out the dirt and grime, while also
                breaking down bacteria, fungus, and bio-film deposits that may
                be occurring deep within the coils. Blue Box cleans and
                disinfects coils in an innovative way that directly improves
                energy efficiency and indoor air quality.
              </Text>

              <Text style={styles.content}>
                You will be leading the charge in sustainable coil cleaning by
                implementing the world’s “Best Practice” to keep a building
                running clean and efficient.
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "flex-start",
                marginTop: "auto",
              }}
            >
              {[
                "Coil Disinfection",
                "Coil Restoration",
                "Energy Management",
                "Improved IAQ",
                "CO2 Reduction",
              ].map((txt, i) => (
                <View
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    fontFamily: "Helvetica Neue",
                  }}
                >
                  <Image
                    src={`/icons/coil-${i + 1}.png`}
                    style={{ width: 32 }}
                    cache={true}
                  />
                  <Text
                    style={{
                      fontSize: 8,
                      width: 75,
                      textAlign: "center",
                      marginTop: 4,
                      color: "#189dd8",
                    }}
                  >
                    {txt}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.rightPart}>
            <View style={styles.info}>
              <View style={styles.infoRow}>
                <Image style={styles.infoRowImage} src={hashtag} />
                <Text style={styles.infoHeader}>Proposal Number</Text>
                <Text style={{ color: "#454545", fontWeight: "light" }}>
                  {/* {data.project_name} {data.id} */}
                  {`BB-${moment(proposal.created_at).format(
                    "YYYYMMDD"
                  )}-${moment(proposal.created_at).format("HHMMSS")}`}
                </Text>
              </View>
              <View style={styles.infoRow}>
                <Image style={styles.infoRowImage} src={projectDescription} />
                <Text style={styles.infoHeader}>Project Description</Text>
                <Text style={{ color: "#454545", paddingLeft: "16px" }}>
                  {proposal.description}
                </Text>
              </View>
              <View style={styles.infoRow}>
                <Image style={styles.infoRowImage} src={address} />
                <Text style={styles.infoHeader}>Project Site</Text>
                <View>
                  <Text style={styles.infoRowContent}>
                    {data.location.location_name ||
                      data.location.company.company_name}
                  </Text>

                  <Text
                    style={{
                      ...styles.infoRowContent,
                      textTransform: "capitalize",
                    }}
                  >
                    {data.location.address}
                    {", "}
                    {data.location.city}
                    {", "}
                    {data.location.state}
                    {", "}
                    <Text
                      style={{
                        textTransform:
                          country?.length <= 3 ? "uppercase" : "capitalize",
                      }}
                    >
                      {country}
                    </Text>
                  </Text>
                </View>
              </View>
              {/* <View style={styles.infoRow}>
                <Image style={styles.infoRowImage} src={serviceDate} />
                <Text style={styles.infoHeader}>Service Date(s)</Text>
                <View style={{ flexDirection: "row" }}>
                  <Text style={{ color: "#454545", fontWeight: "light" }}>
                    {moment(data.start_date).format("DD-MMM-YYYY")}
                  </Text>
                </View>
              </View> */}
              <View style={[styles.infoRow, { marginBottom: 0 }]}>
                <Image style={styles.infoRowImage} src={totalImg} />
                <Text style={styles.infoHeader}>Project Total</Text>
                <Text style={{ color: "#454545", fontWeight: "light" }}>
                  ${total} {currency}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <ProposalFooter data={data} proposal={proposal} />
    </Page>
  );
};

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica Neue",
    position: "relative",
    flexDirection: "column",
  },
  prepText: {
    marginBottom: 6,
  },
  prepContent: {
    textAlign: "center",
    fontSize: 14,
    marginBottom: 12,
  },
  prepared: {
    flexDirection: "column",
    backgroundColor: "#f2f2f2",
    flex: 2,
    padding: 20,
    alignContent: "flex-start",
    flexFlow: "column",
  },
  info: {
    flexDirection: "column",
    flex: 1,
    paddingLeft: 4,
  },
  infoHeader: {
    textTransform: "uppercase",
    marginBottom: 2,
    fontSize: 9,
    fontWeight: "bold",
    color: "#4c4c4c",
  },
  infoRow: {
    fontSize: 10,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  infoRowImage: {
    width: 26,
    height: "auto",
    marginBottom: 5,
  },
  infoRowContent: {
    marginVertical: 2,
    textAlign: "center",
    color: "#454545",
  },

  title_container: {
    flexDirection: "row",
  },
  title: {
    textTransform: "uppercase",
    color: "#189DD8",
    fontSize: 20,
    marginBottom: 8,
  },
  coverBox: {
    flexDirection: "row",
  },
  prepared_by: {
    marginLeft: 60,
    flex: 1,
  },
  prepared_for: {
    flex: "1 1 auto",
  },
  imageBox: {
    position: "relative",
  },
  imageText: {
    fontSize: 62,
    color: "#f4f4f4",
    position: "absolute",
    top: "0%",
    left: "0%",
    width: "100%",
    height: "100%",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  textLabel: {
    fontSize: 12,
    marginBottom: 2,
    color: "#454545",
  },
  leftPart: {
    flex: 0.7,
  },
  rightPart: {
    flex: 0.3,
    borderLeftColor: "#323232",
    borderLeftWidth: 0.6,
    marginLeft: 3,
  },
  leftTop: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#c2c2c2",
    borderBottomWidth: 1,
    paddingBottom: 4,
  },
  leftBottom: {
    paddingVertical: 3,
    paddingRight: 2,
  },
  preparedFor: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  preparedBy: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    borderLeftWidth: 0.6,
    borderLeftColor: "#323232",
    paddingLeft: 12,
  },
  content: {
    fontSize: 10,
    color: "#454545",
    marginBottom: 10,
    fontFamily: "Helvetica Neue",
    fontWeight: "light",
  },
  services: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
    paddingHorizontal: 20,
  },
  serviceIconText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  serviceImage: {
    height: 34,
    width: 34,
  },
  serviceText: {
    fontSize: 10,
    marginTop: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#189DD8",
    fontWeight: 400,
  },
});

export default CoverPage;
