import React from "react";
import { View, Text, StyleSheet, Image, Link } from "@react-pdf/renderer";

export function Footer({}) {
  return (
    <View style={styles.footer}>
      <Image
        source="/report-footer.png"
        style={{ height: 50, marginRight: "auto" }}
        cache={false}
      />
      <Link src="https://blueboxair.com">
        <Text style={{ fontSize: 10, color: "#595959" }}>blueboxair.com</Text>
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    flex: 1,
    height: 12,
    flexDirection: "row",
    display: "flex",
    marginTop: "auto",
    marginRight: 16,
    justifyContent: "flex-end",
    alignItems: "center",
  },
});
