import { gql } from '@apollo/client';

const CONTACTS = gql`
  query Contacts($where: ContactFilter, $skip: Int, $limit: Int, $orderBy: Sort){
    contactsBy(where: $where, skip: $skip, limit: $limit, orderBy: $orderBy) {
      contacts {
        id
        company_title
        email
        phone_number
        contact_name
        company {
          id
          company_name
        }
      }
      pagination {
        total 
        skip
        limit
      }
    }
  }
`;

export default CONTACTS;
