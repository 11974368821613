import React, { useState, useEffect } from "react";
import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button } from "@salesforce/design-system-react";

const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity(
    $where: OpportunityWhere!
    $data: OpportunityUpdate!
  ) {
    updateOpportunity(where: $where, data: $data) {
      id
    }
  }
`;

const CREATE_PROJECT = gql`
  mutation CreateProject($opportunityId: ID!) {
    createProject(opportunityId: $opportunityId) {
      id
    }
  }
`;

const stages = [
  "Qualification",
  "Need Analysis",
  "Proposal",
  "Negotiation",
  "Closed Won",
];

export function JourneyStages(props) {
  const [opportunity, setOpportunity] = useState(props.opportunity);
  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY);
  const [createProject, { data, loading, error }] = useMutation(CREATE_PROJECT);

  const history = useHistory();

  const [currentStage, setCurrentStage] = useState(0);

  useEffect(() => {
    if (props.opportunity) {
      setOpportunity(props.opportunity);
      setCurrentStage(stages.indexOf(props.opportunity.stage));
    }
  }, [props.opportunity]);

  function updateStage(stage) {
    updateOpportunity({
      variables: {
        where: { id: parseInt(opportunity.id) },
        data: { stage: stage },
      },
    }).then(() => props?.refetch());
    setCurrentStage(stages.indexOf(stage));
  }

  function closeOpportunity() {
    createProject({
      variables: {
        opportunityId: opportunity?.id,
      },
    }).then(() => props?.refetch());
  }

  function handleNav(path) {
    history.push(path);
  }

  return (
    <div className="slds-grid slds-wrap slds-m-top_medium">
      <div className="slds-col slds-col slds-size_3-of-3">
        <div className="slds-path slds-box">
          <div className="slds-grid slds-path__track">
            <div className="slds-grid slds-path__scroller-container">
              <div className="slds-path__scroller" role="application">
                <div className="slds-path__scroller_inner">
                  <ul
                    className="slds-path__nav"
                    role="listbox"
                    aria-orientation="horizontal"
                  >
                    {stages.map((stage, i) => {
                      return (
                        <li
                          className={`slds-path__item ${
                            currentStage > i
                              ? "slds-is-complete"
                              : "slds-is-incomplete"
                          } ${currentStage === i ? "slds-is-active" : ""}`}
                          role="presentation"
                          onClick={() => updateStage(stage)}
                        >
                          <a
                            aria-selected="false"
                            className="slds-path__link"
                            id="path-6"
                            role="option"
                            tabindex="-1"
                          >
                            <span className="slds-path__stage">
                              <span className="slds-assistive-text">
                                Stage Complete
                              </span>
                            </span>
                            <span className="slds-path__title">{stage}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="slds-grid slds-path__action">
              {/* <span className="slds-path__stage-name">Stage: Unqualified</span> */}
              {opportunity?.stage === "Closed Won" && (
                <Button
                  onClick={
                    opportunity?.project?.id
                      ? () =>
                          history.push(`/projects/${opportunity?.project?.id}`)
                      : closeOpportunity
                  }
                  disabled={loading}
                >
                  {loading
                    ? "Loading..."
                    : opportunity?.project?.id
                    ? "View Project"
                    : "Create Project"}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
