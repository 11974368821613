import { GET_CONTACTS } from "./contactsTypes";

const initialState = {
  contacts: [],
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      const contacts = [...state.contacts, action.payload];
      return {
        ...state,
        contacts,
      };
    default:
      return state;
  }
};

export default contactReducer;
