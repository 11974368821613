import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
let decimal = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const TotalRow = ({ label, value, main, currency }) => {
  let extra = {};
  if (main) {
    extra = {
      color: "#189DD8",
      fontWeight: "bold",
      textTransform: "uppercase",
    };
  }
  return (
    <View style={styles.row} wrap={false}>
      <Text
        style={{
          width: "83%",
          borderBottomWidth: 1,
          borderColor: "#c2c2c2",
          borderLeftWidth: 1,
          padding: 6,

          textAlign: "right",
          fontSize: 10,
          ...extra,
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          width: "17%",
          borderBottomWidth: 1,
          borderColor: "#c2c2c2",
          borderLeftWidth: 1,
          borderRightWidth: 1,
          padding: 6,
          textAlign: "center",
          fontSize: 10,
          ...extra,
        }}
      >
        {decimal.format(Number(value))} {currency}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    fontFamily: "Helvetica Neue",
    flexDirection: "row",
    fontSize: 11,
  },
});
export default TotalRow;
