import React from "react";
import ReactDOM from "react-dom";
import Root from "./components/Root";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
Sentry.init({
  dsn: "https://8fd1723a3b364216999902e9f8c5703e@o1082482.ingest.sentry.io/6091032",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
