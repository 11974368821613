import { gql } from "@apollo/client";

const OPPORTUNITIES = gql`
  query ProjectsBy(
    $where: ProjectFilter
    $skip: Int
    $limit: Int
    $sort: Sort
  ) {
    projectsBy(where: $where, skip: $skip, limit: $limit, orderBy: $sort) {
      projects {
        id
        project_name
        start_date
        total_amount
        currency
        stage
        estimated_duration
        kickoff_call
        is_opportunity
        created_at
        equipments {
          id
        }
        proposals {
          id
          proposal_items {
            id
            calculated_price
          }
          is_approved
        }
        created_by {
          id
          user_name
          first_name
          last_name
        }
        location {
          id
          location_name
          address
          city
          state
          equipments {
            id
          }
          company {
            id
            company_name
            country
          }
        }
        contacts {
          id
          contact_name
          email
          phone_number
        }
      }
      pagination {
        total
        limit
        skip
      }
    }
  }
`;

export default OPPORTUNITIES;
