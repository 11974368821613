import React from "react";
import { View, Text, Image, StyleSheet, Link } from "@react-pdf/renderer";
import logo from "./images/logo-rectangle.png";

export function ProposalHeader({ project, date }) {
  return (
    <View style={styles.header}>
      <View>
        <Link src="https://blueboxair.com">
          <Image style={styles.logo} src={logo} />
        </Link>
      </View>
      <View style={{ flex: 1, alignItems: "flex-end" }}>
        <Text
          style={[styles.headerText, { fontWeight: "bold", color: "#454545" }]}
        >
          BLUE BOX AIR, LLC
        </Text>
        <View style={styles.headerLine}></View>
        <Text
          style={[styles.headerText, { fontWeight: "light", color: "#6c6c6c" }]}
        >
          Proposal date: {date}
        </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  header: {
    fontFamily: "Helvetica Neue",
    width: "100%",
    flexDirection: "row",
    display: "flex",
    padding: 8,
    alignItems: "center",
  },
  headerText: {
    fontSize: 10,
    marginBottom: 5,
    textTransform: "uppercase",
  },
  logo: {
    width: 120,
    marginTop: -5,
  },
  headerLine: {
    height: "3px",
    width: "100%",
    backgroundColor: "#189DD8",
    marginBottom: 5,
  },
});
