import React, {useState,useEffect} from 'react';
import {
 DataTable,
  DataTableColumn,
  DataTableCell,
  ButtonGroup,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter
 } 
from '@salesforce/design-system-react'; 
import { Link } from "react-router-dom";

const ProjectLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/projects/${item.id}`}>{children}</Link>
  </DataTableCell>
);
ProjectLink.displayName = DataTableCell.displayName;

export function ProjectsList(props) {

  const [projects, setProjects] = useState([])

  useEffect(() => {
    if (props.projects) {
      let arr = [];
      
      props.projects.map(obj => {
        if(!obj.is_opportunity) {
          arr.push(obj);
        }
      })
      setProjects(arr)
    }
  },[props.projects])

	return (
		<IconSettings iconPath="/icons">
      <Card
        id="ProjectCard"
        // headerActions={
        //  (
        //     <Button
        //       label="Add Project"
        //       onClick={console.log('Open modal')}
        //     />
        //   )
        // }
        filter={
         (projects.length > 0) && (
            <CardFilter onChange={() => {console.log('Change')}} />
          )
        }
        heading={`Projects (${projects.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          projects.length === 0 ? (
            <CardEmpty heading="No Projects">
              <p>Create a project by first creating an opportunity and setting amounts.</p>
            </CardEmpty>
          ) : null
        }
      >
  			<DataTable
          fixedLayout
          items={projects}
          id="getProject"
          on
        >
          <DataTableColumn
            label="Id"
            property="id"
          >

          </DataTableColumn>
          <DataTableColumn
            label="Project name"
            property="project_name"
          >
            <ProjectLink />
          </DataTableColumn>
          <DataTableColumn
            label="Stage"
            property="stage"
          >

          </DataTableColumn>
          <DataTableColumn
            label="Start date"
            property="start_date"
          >

          </DataTableColumn>
          <DataTableColumn
            label="Kickoff call"
            property="kickoff_call"
          >

          </DataTableColumn>

        </DataTable>
      </Card>
		</IconSettings>
	)
}