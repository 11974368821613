import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { useQuery, gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { ModalContext } from "../ModalContext";
import { AddLocation } from "../locations/ModalAddLocation";
import { AddContactToLocation } from "./ModalAddContactToLocation";

const LocationLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/companies/${props.company}/locations/${item.id}`}>
      {children}
    </Link>
  </DataTableCell>
);

LocationLink.displayName = DataTableCell.displayName;

const EquipmentCount = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <p>{item.equipments.length}</p>
  </DataTableCell>
);

EquipmentCount.displayName = DataTableCell.displayName;

const SINGLE = gql`
  query locationContact($id: Int!) {
    locationContactByContactId(contact_id: $id) {
      id
      location {
          id
          location_name
          address
          city
          state
          equipments {
            id
          }
      }
    }
  }
`;

export function ContactLocationsList(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [items, setItems] = useState([]);
  const [input, setInput] = useState([]);
  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: {
      id: parseInt(props.contact),
    },
  });

  const triggerModal = () =>{
    handleModal(
      <AddContactToLocation refetch={refetch} contact={props.contact} id={props.company} />,
      "Assign Location"
    );
  }
    
  const filterTable = (input) => {
    setInput(input);
    setItems(
      data.locationContactByContactId.filter((item) =>
        item.location_name.toLowerCase().includes(input.toLowerCase())
      )
    );
  };

  useEffect(() => {
    if (data) {
      if (data.locationContactByContactId.length > 0) {
        let locations = data.locationContactByContactId.map(item => item.location)
        .filter((value, index, self) => self.indexOf(value) === index)

        setItems(locations)
      }
    }
  },[data])

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return (
    <IconSettings iconPath="/icons">
      <Card
        id="ContactCard"
        headerActions={
          <Button label="Assign Location" onClick={() => triggerModal()} />
        }
        filter={
          data.locationContactByContactId.length > 0 && (
            <CardFilter onChange={(e) => filterTable(e.target.value)} />
          )
        }
        heading={`Assigned Locations (${data.locationContactByContactId.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          data.locationContactByContactId.length === 0 ? (
            <CardEmpty heading="No Locations">
              <Button label="Assign Location" onClick={() => triggerModal()} />
            </CardEmpty>
          ) : null
        }
      >
        <DataTable
          fixedLayout
          items={
            items.length > 0 || input.length > 0
              ? items
              : []
          }
          id="getContacts"
          on
        >
          <DataTableColumn label="Id" property="id"></DataTableColumn>
          <DataTableColumn label="Location Name" property="location_name">
            <LocationLink company={props.company} />
          </DataTableColumn>
          <DataTableColumn label="City" property="city"></DataTableColumn>

          <DataTableColumn label="State" property="state"></DataTableColumn>

          <DataTableColumn label="Equipment count">
            <EquipmentCount />
          </DataTableColumn>
        </DataTable>
      </Card>
    </IconSettings>
  );
}
