import React, { useState, useEffect } from 'react';
import { useMutation,gql } from '@apollo/client';
import { Button, Textarea } from '@salesforce/design-system-react';
import { useForm, Controller } from 'react-hook-form';
import CREATE_NOTE from '../../graphql/mutations/createNote';
import Moment from 'react-moment';
import { Auth } from 'aws-amplify';

const REMOVE_NOTE = gql`
    mutation RemoveNote($where: NoteWhere!) {
      deleteNote(where: $where) {
        id
      }
    }
  `;

export function NotesTab(props) {
  const [notes, setNotes] = useState([]);
  const [createNote] = useMutation(CREATE_NOTE);
  const [removeNote] = useMutation(REMOVE_NOTE);
  const { control, handleSubmit } = useForm();
  
  useEffect(() => {
    if (props.notes) {
      setNotes(props.notes)
    }
  },[props])

  async function onSubmit(data) {
    const user = await Auth.currentAuthenticatedUser();
    
    data['contact_id'] = parseInt(props.contact);
    data['created_by'] = 92;

    await createNote({ variables: { data } }).then(() => props.refetch());
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={
            <Textarea
              label="Message"
              name="message"
            />
          }
          control={control}
          name="message"
          defaultValue=""
        />
        <br />
        <Button type="submit">Save note</Button>
      </form>
      {
        (notes.length > 0) &&
          notes.map(note => {
            let date = note.created_at;
            return (
              <div className="note">
                <p>Message: {note.message}</p>
                <ul>
                  <li>Created <Moment date={date} fromNow /></li>
                  <li>by {note?.created_by?.user_name}</li>
                </ul>
                <a onClick={() => removeNote({variables: {where: {id: parseInt(note.id)}}}).then(() => props.refetch())}>Remove note</a>
              </div>
            )
          })
      }
    </div>
  )
}
