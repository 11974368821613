import _ from 'lodash';

export const searchFilter = (arr, property, input) => arr.filter(item => item[property].toLowerCase().includes(input.toLowerCase()));

export const handleSort = (data, items) => {
  let iteratees;

  switch (data.property) {
    case 'company':
      iteratees = item => item.company ? item.company.company_name.toLowerCase() : '';
      break;

    case 'id':
      iteratees = item => parseInt(item[data.property]);
      break;

    case 'phone_number':
      iteratees = item => parseInt(item[data.property]);
      break;

    case 'created_at':
      iteratees = item => parseInt(item[data.property]);
      break;
      
    default:
      iteratees = item => item[data.property].toLowerCase();
  }
  
  return  _.orderBy(items, iteratees, [data.sortDirection]);
};

export default {
  searchFilter,
  handleSort
};
