import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Input,
  Combobox,
  comboboxFilterAndLimit,
  IconSettings,
  Icon,
} from "@salesforce/design-system-react";
import { industries } from "../../utilities/industries-list";
import { countries } from "../../utilities/states-list";
import { gql, useMutation } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import { Auth } from "aws-amplify";

const COMPANIES = gql`
  query GetCompanies {
    companies {
      id
      company_name
      city
      state
      website
      category
      contacts {
        id
      }
      locations {
        id
      }
      created_at
    }
  }
`;

const ADD_COMPANY = gql`
  mutation CreateCompany($input: CompanyInput!) {
    createCompany(input: $input) {
      company_name
      state
      city
      category
      created_by {
        id
      }
    }
  }
`;

export function CompanyForm(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [addCompany, { error }] = useMutation(ADD_COMPANY);
  const { register, handleSubmit, control, errors } = useForm();
  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  const [inputValue, setInputValue] = useState("");
  const [selection, setSelection] = useState([]);

  async function onSubmit(data) {
    const user = await Auth.currentAuthenticatedUser();

    data.created_by = Number(user?.attributes["custom:userId"]) || 1;
    data.category = selection.map((x) => x.label).join(", ");

    addCompany({
      variables: { input: data },
    }).then(() => {
      //props.refetchUsers();
      return props.doRefresh(!props?.refresh);
    });

    handleModal();
  }

  return (
    <IconSettings iconPath="/icons">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={
            <label htmlFor="companyName">
              Company Name *
              <Input name="company_name" type="text" ref={register} />
            </label>
          }
          control={control}
          name="company_name"
          defaultValue={""}
          rules={{ required: true }}
        />
        {errors?.company_name?.type === "required" && (
          <p style={{ fontSize: "11px", color: "red" }}>Required input</p>
        )}
        <br />
        <Controller
          as={
            <label htmlFor="country">
              Country *<br />
              <select
                name="country"
                ref={register}
                onChange={({ target }) =>
                  setSelectedStates(countries[target.value])
                }
              >
                <option value={"united states"} selected>
                  United states
                </option>
                <option value={"australia"}>Australia</option>
              </select>
            </label>
          }
          control={control}
          name="country"
          defaultValue={"united states"}
        />
        <br />
        <Controller
          as={
            <label htmlFor="state">
              State *<br />
              <select name="state" ref={register}>
                {selectedStates.map((state) => {
                  return (
                    <option key={state.State} value={state.State}>
                      {state.State}
                    </option>
                  );
                })}
              </select>
            </label>
          }
          control={control}
          name="state"
          defaultValue={"Texas"}
        />
        <br />

        <Controller
          as={
            <label htmlFor="city">
              City
              <Input name="city" type="text" ref={register} />
            </label>
          }
          control={control}
          name="city"
          defaultValue={""}
        />
        <br />

        <Controller
          as={
            <Combobox
              name="categories"
              id="combobox-base"
              events={{
                onChange: (event, { value }) => {
                  setInputValue(value);
                },
                onRequestRemoveSelectedOption: (event, data) => {
                  setInputValue("");
                  setSelection(data.selection);
                },
                onSelect: (event, data) => {
                  setInputValue("");
                  setSelection(data.selection);
                },
              }}
              labels={{
                label: "Categories *",
                placeholder: "Search industries",
              }}
              multiple
              options={comboboxFilterAndLimit({
                inputValue: inputValue,
                limit: 10,
                options: industries.map((x) => ({
                  id: x.name,
                  label: x.name,
                  type: "industry",
                })),
                selection: selection,
              })}
              selection={selection}
              value={inputValue}
            />
          }
          control={control}
          name="categories"
          rules={{ required: selection.length === 0 }}
        />
        <p style={{ fontSize: "10px", color: "red" }}>
          {selection.length === 0 && "Required input"}
        </p>
        <br />

        <Controller
          as={
            <label htmlFor="website">
              Company Website
              <Input
                name="website"
                type="text"
                ref={register}
                placeholder="https://www.example.com"
              />
            </label>
          }
          control={control}
          name="website"
          defaultValue={""}
          rules={{
            pattern:
              "https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)",
          }}
        />

        <br />
        {/*     
      
      
      <Controller
        as={
          <label htmlFor="phone_number">
            Phone Number
            <Input name="phone_number" type="text" ref={register} />
          </label>
        }
        control={control}
        name="phone_number"
        defaultValue={""}
      /> */}

        <Input type="submit" value="Save" />
      </form>
    </IconSettings>
  );
}
