import React from "react";

export const userSettingsInitState = {
  user: {
    id: null,
  },
  filters: {
    country: "ALL",
    created_by: 0,
  },
};

export const UserSettingsContext = React.createContext(userSettingsInitState);
