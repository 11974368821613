import { gql } from "@apollo/client";

const SINGLE = gql`
  query GetOpportunityByID($id: ID!) {
    opportunity(id: $id) {
      id
      name
      description
      contacts {
        contact_name
        phone_number
        email
        is_primary
      }
      created_by {
        id
        user_name
        first_name
        last_name
        phone_number
        email
      }
      created_at
      # start_date
      location {
        id
        location_name
        address
        city
        state
        company {
          id
          company_name
          city
          state
          website
          country
          contacts {
            contact_name
            phone_number
            email
            is_primary
          }
          created_by {
            first_name
            last_name
            phone_number
            email
          }
        }
      }
      proposals {
        id
        description
        created_by {
          id
          email
          first_name
          last_name
          phone_number
        }
        proposal_items {
          id
          calculated_price
          location_on_site
          cfm
          lps
          building
          name
          size
        }
        discount
        other_costs
        other_costs_label
      }
    }
  }
`;

export const ProjectByID = gql`
  query GetProjectByID($id: ID!) {
    project(id: $id) {
      id
      project_name
      created_by {
        id
        user_name
        first_name
        last_name
        phone_number
        email
      }
      created_at
      # start_date
      location {
        id
        address
        city
        state
        company {
          id
          company_name
          city
          state
          website
          created_by {
            first_name
            last_name
            phone_number
            email
          }
        }
      }
      proposals {
        id
        proposal_items {
          id
          calculated_price
          cfm
          building
          name
          size
        }
        discount
      }
    }
  }
`;

export default SINGLE;
