import React from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./ModalContext";
import { Modal } from "@salesforce/design-system-react";
import "./global.css";

const ModalContainer = () => {
  let { modalContent, modalHeading, handleModal, modal } = React.useContext(
    ModalContext
  );
  const closeModal = () => {
    handleModal(false);
  };

  if (modal) {
    return ReactDOM.createPortal(
      <Modal
        ariaHideApp={false}
        isOpen={modal}
        heading={
          <div className="header-custom-container">
            <span></span>
            <span>{modalHeading}</span>
            <button onClick={closeModal} className="btn-close">
              X
            </button>
          </div>
        }
      >
        <section className="slds-p-around_large">{modalContent}</section>
      </Modal>,
      document.querySelector("#modal-root")
    );
  } else return null;
};

export default ModalContainer;
