import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  DataTableRowActions,
  Dropdown,
  CardFilter,
} from "@salesforce/design-system-react";
import { AddProposal } from "./ModalAddProposal";
import CurrencyFormat from "react-currency-format";
import { ModalContext } from "../ModalContext";
import Moment from "react-moment";
import { gql, useMutation } from "@apollo/client";
import { Link, BrowserRouter as Router } from "react-router-dom";

const UPDATE_PROPOSAL = gql`
  mutation UpdateProposal($where: ProposalWhere!, $data: ProposalUpdate!) {
    updateProposal(where: $where, data: $data) {
      id
    }
  }
`;

const REMOVE_PROPOSAL = gql`
  mutation DeleteProposal($id: ID!) {
    deleteProposal(where: { id: $id }) {
      id
    }
  }
`;

const BooleanTableCell = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <p>{item.is_approved == true ? "Yes" : "No"}</p>
  </DataTableCell>
);

BooleanTableCell.displayName = DataTableCell.displayName;

const Value = ({ children, item, ...props }) => {
  const total = item.proposal_items.reduce((total, item) => {
    return item.calculated_price + total;
  }, 0);
  const totalWithDiscount =
    Math.round(
      ((Number(item.other_costs) || 0) + total - total * item.discount) * 100
    ) / 100;

  return (
    <DataTableCell title={children} {...props}>
      <div>
        {totalWithDiscount}{" "}
        <span style={{ paddingLeft: 4 }}>{props?.currency}</span>
      </div>
    </DataTableCell>
  );
};

Value.displayName = DataTableCell.displayName;

const DateLink = ({ children, item, opp, ...props }) => (
  <DataTableCell title={children} {...props}>
    <div>
      <Link to={`/opportunities/${opp}/proposal/${item.id}`}>
        <Moment format="DD-MMM-YYYY">{children}</Moment>
      </Link>
    </div>
  </DataTableCell>
);

DateLink.displayName = DataTableCell.displayName;

export function ProposalTab(props, match) {
  const { handleModal } = React.useContext(ModalContext);

  const [proposals, setProposals] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [oppId, setOppId] = useState(0);
  const [updateProposal] = useMutation(UPDATE_PROPOSAL);
  const [removeProposal] = useMutation(REMOVE_PROPOSAL);

  const handleAction = (e, d) => {
    // TODO
    if (d.label == "Approve") {
      updateProposal({
        variables: {
          where: {
            id: e.id,
          },
          data: {
            is_approved: true,
          },
        },
      }).then(() => {
        props.refetch();
      });
    } else if (d.label == "Edit") {
      console.log("TODO: Open modal and change values");
      triggerModal();
    } else if (d.label == "Remove") {
      onSinglePropasalRemove(e.id);
    }
  };

  // remove single Proposal from list
  const onSinglePropasalRemove = async (id) => {
    try {
      const res = await removeProposal({
        variables: { id: id },
      });
      props.refetch();
    } catch (error) {
      console.log(" ==== ERROR ==== ", error);
    }
  };

  useEffect(() => {
    if (props.proposals) {
      let sortedProposals = [...props.proposals];

      sortedProposals.sort((a, b) => {
        const x1 = new Date(a.created_at).getTime();
        const x2 = new Date(b.created_at).getTime();
        return x2 - x1;
      });
      setProposals(sortedProposals);
    }
    if (props.equipment) {
      setEquipment(props.equipment);
    }
    if (props.opportunityId) {
      setOppId(props.opportunityId);
    }
  }, [props]);

  const triggerModal = () =>
    handleModal(
      <AddProposal
        equipment={equipment}
        opportunityId={props?.opportunityId}
        refetch={props?.refetch}
        currency={props?.currency}
      />,
      "New Proposal"
    );

  return (
    <div>
      <Card
        id="ProposalCard"
        headerActions={
          <Button label="New Proposal" onClick={() => triggerModal()} />
        }
        // filter={
        //   proposals.length > 0 && (
        //     <CardFilter
        //       onChange={() => {
        //         console.log("Change");
        //       }}
        //     />
        //   )
        // }
        heading={`Proposal Versions (${proposals.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          proposals.length === 0 ? (
            <CardEmpty heading="No Proposals">
              <Button label="New Proposal" onClick={() => triggerModal()} />
            </CardEmpty>
          ) : null
        }
      >
        <DataTable fixedLayout items={proposals} id="proposals">
          <DataTableColumn label="Id" property="id"></DataTableColumn>

          <DataTableColumn label="Created at" property="created_at">
            <DateLink opp={oppId} />
          </DataTableColumn>

          <DataTableColumn label="Value" property="calculated_price">
            <Value currency={props?.currency} />
          </DataTableColumn>

          <DataTableColumn label="Is Approved" property="is_approved">
            <BooleanTableCell />
          </DataTableColumn>
          <DataTableRowActions
            options={[
              {
                id: 0,
                label: "Edit",
                value: "1",
              },
              {
                id: 1,
                label: "Approve",
                value: "2",
              },
              {
                id: 2,
                label: "Remove",
                value: "3",
              },
            ]}
            onAction={handleAction}
            dropdown={<Dropdown length="7" />}
          />
        </DataTable>
      </Card>
    </div>
  );
}
