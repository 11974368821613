import React from "react";
import { View, Text, Image, StyleSheet, Link } from "@react-pdf/renderer";
import logo from "./images/logo-sqaure.png";

export function BackPageFooter() {
  return (
    <View style={styles.footer}>
      <View
        style={{
          flexDirection: "row",
          marginHorizontal: 12,
          borderTopWidth: 0.6,
          paddingTop: 6,
          marginBottom: 12,
        }}
      >
        <View>
          <Link src="https://blueboxair.com">
            <Image style={styles.logo} src={logo} cache={true} />
          </Link>
        </View>
        <View style={{ flexDirection: "column", marginLeft: 8 }}>
          <View style={{ marginBottom: 8 }}>
            <Text style={{ fontSize: 8, color: "#454545" }}>
              Blue Box™ delivers a unique model for ensuring facilities to
              experience the safest air possible within their normal day to day
              operations. With its patented process for how to treat and
              disinfect any type of facility’s HVAC system, Blue Box™ eliminates
              a critical weak point in all building operations. Our innovation
              is how to penetrate through any depth of the heat transfer coil to
              ensure near-perfect surface area coverage. Our process involves
              one of the most comprehensive solutions for how to disinfect these
              hard to reach places so that a building’s air supply is both
              efficient and safe. For further information, visit
              www.blueboxair.com
            </Text>
          </View>
          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <View>
              <Text
                style={{ fontSize: 10, color: "#189DD8", fontWeight: "bold" }}
              >
                INNOVATING AIR: A NEW LEVEL OF CLEAN.
              </Text>
            </View>
            <View>
              <Text style={{ fontSize: 9, color: "#6c6c6c" }}>
                © Blue Box Air, LLC. All rights reserved. March 2021
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    fontFamily: "Helvetica Neue",
    width: "100%",
    backgroundColor: "#ffffff",
    flexDirection: "row",
    left: 0,
    marginTop: "auto",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    right: 0,
    paddingHorizontal: 20,
  },
  logo: {
    width: 48,
  },
});
