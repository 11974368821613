export const getUnitSize = (cfm) => {
  if (!cfm) return "";
  switch (true) {
    case cfm >= 100000:
      return "XXL";
    case cfm >= 50000:
      return "XL";
    case cfm >= 25000:
      return "L";
    case cfm >= 10000:
      return "M";
    case cfm >= 5000:
      return "S";
    case cfm >= 0:
      return "XS";
    default:
      return "";
  }
};

export const getCalculatedPrice = (cfm) => {
  if (!cfm) return null;
  if (cfm <= 10000) {
    return 500;
  } else if (cfm >= 10001 && cfm < 30000) {
    return 1000;
  } else if (cfm >= 30000) {
    return 1500;
  }
  return null;
};

export const getLps = (cfm) => Math.floor(Number(cfm) * 0.47194745 * 100) / 100;
export const getCfm = (lps) => lps / 0.47194745;
