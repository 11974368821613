import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Input } from "@salesforce/design-system-react";
import { ModalContext } from "../ModalContext";
import { countries } from "../../utilities/states-list";
import { gql, useMutation } from "@apollo/client";

const ADD_LOCATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(input: $input) {
      location_name
      address
      city
      state
      location_code
      operating_days_week
      operating_hours_day
      electricity_cost
      recirculated_air
      id
    }
  }
`;

export function AddLocation(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  const [addLocation, { error }] = useMutation(ADD_LOCATION);

  const { register, handleSubmit, control, errors } = useForm();

  const onSubmit = (data) => {
    data.company_id = parseInt(props.id);
    data.address = `${data.address1} | ${data.address2}`;

    delete data.address1;
    delete data.address2;

    if (data?.electricity_cost) {
      data["electricity_cost"] = Number(data?.electricity_cost);
    } else {
      delete data?.electricity_cost;
    }

    if (data?.operating_hours_day) {
      data["operating_hours_day"] = Number(data?.operating_hours_day);
    } else {
      delete data?.operating_hours_day;
    }

    if (data?.operating_days_week) {
      data["operating_days_week"] = Number(data?.operating_days_week);
    } else {
      delete data?.operating_days_week;
    }

    if (data?.recirculated_air) {
      data["recirculated_air"] = Number(data?.recirculated_air);
    } else {
      delete data?.recirculated_air;
    }

    addLocation({
      variables: { input: data },
    }).then(({ data }) => {
      props.refetch();
    });

    handleModal();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <label htmlFor="locationName">
            Name of location *
            <Input name="location_name" type="text" ref={register} />
          </label>
        }
        rules={{ required: true }}
        control={control}
        name="location_name"
        defaultValue={""}
      />
      <p style={{ fontSize: "10px", color: "red" }}>
        {errors.location_name && "Required input"}
      </p>
      <div style={{ height: "8px", width: "100%" }} />
      <Controller
        as={
          <label htmlFor="address1">
            Address Line1 *
            <Input
              name="address1"
              type="text"
              ref={register}
              placeholder="Line 1"
            />
          </label>
        }
        control={control}
        name="address1"
        defaultValue={""}
        rules={{ required: true }}
      />
      <p style={{ fontSize: "10px", color: "red" }}>
        {errors.address1 && "Required input"}
      </p>
      <div style={{ height: "4px", width: "100%" }} />
      <Controller
        as={
          <label htmlFor="address2">
            Address Line 2
            <Input
              name="address2"
              type="text"
              ref={register}
              placeholder="Line 2"
            />
          </label>
        }
        control={control}
        name="address2"
        defaultValue={""}
      />
      <div style={{ height: "8px", width: "100%" }} />
      <Controller
        as={
          <label htmlFor="city">
            City
            <Input name="city" type="text" ref={register} />
          </label>
        }
        control={control}
        name="city"
        defaultValue={""}
      />
      <div style={{ height: "8px", width: "100%" }} />
      <Controller
        as={
          <label htmlFor="country">
            Country
            <br />
            <select
              name="country"
              ref={register}
              onChange={({ target }) =>
                setSelectedStates(countries[target.value])
              }
            >
              <option value={"united states"} selected>
                United states
              </option>
              <option value={"australia"}>Australia</option>
            </select>
          </label>
        }
        control={control}
        name="country"
        defaultValue={"united states"}
      />
      <div style={{ height: "8px", width: "100%" }} />
      <Controller
        as={
          <label htmlFor="state">
            State
            <br />
            <select name="state" ref={register}>
              {selectedStates.map((state) => {
                return (
                  <option key={state.State} value={state.State}>
                    {state.State}
                  </option>
                );
              })}
            </select>
          </label>
        }
        control={control}
        name="state"
        defaultValue={"Texas"}
      />
      <div style={{ height: "8px", width: "100%" }} />

      <Controller
        as={
          <label htmlFor="location_code">
            ZIP Code
            <Input name="location_code" type="text" ref={register} />
          </label>
        }
        control={control}
        name="location_code"
        defaultValue={""}
      />

      <div style={{ height: "8px", width: "100%" }} />

      <Controller
        as={
          <label htmlFor="operating_hours_day">
            Operating hours/day
            <Input name="operating_hours_day" type="text" ref={register} />
          </label>
        }
        control={control}
        name="operating_hours_day"
        defaultValue={""}
      />

      <div style={{ height: "8px", width: "100%" }} />

      <Controller
        as={
          <label htmlFor="operating_days_week">
            Operating days/week
            <Input name="operating_days_week" type="text" ref={register} />
          </label>
        }
        control={control}
        name="operating_days_week"
        defaultValue={""}
      />

      <div style={{ height: "8px", width: "100%" }} />

      <Controller
        as={
          <label htmlFor="electricity_cost">
            Electricity cost
            <Input name="electricity_cost" type="text" ref={register} />
          </label>
        }
        control={control}
        name="electricity_cost"
        defaultValue={""}
      />

      <div style={{ height: "8px", width: "100%" }} />

      <Controller
        as={
          <label htmlFor="recirculated_air">
            Recirculated air
            <Input name="recirculated_air" type="text" ref={register} />
          </label>
        }
        control={control}
        name="recirculated_air"
        defaultValue={""}
      />
      <div style={{ height: "8px", width: "100%" }} />
      <Input type="submit" value="Save" />
    </form>
  );
}
