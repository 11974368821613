import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { Header2 } from "./Header2";

import {
  formatNumbers,
  getAvgPressureDropByBuilding,
  getReductionPressureDropPercent,
} from "./functions";

var collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

export default function EquipmentList({
  buildings,
  ahu,
  fanEnergySaving,
  pressureAvg,
  pressureUnit,
}) {
  return (
    <View style={styles.container}>
      <Header2 />
      <View
        style={{
          flex: 1,
          width: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {buildings?.map((building) => (
          <View key={building}>
            <Text
              style={{
                fontSize: 24,
                color: "#189dd8",
                marginTop: 24,
                textTransform: "uppercase",
                fontFamily: "HelveticaNeue",
              }}
            >
              {building?.name} equipment
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "grey",
                marginBottom: 4,
                fontFamily: "HelveticaNeue",
              }}
            >
              Blue Box Air™ serviced {building?.units?.length || 0}{" "}
              {" equipment"}:
            </Text>
            <View style={styles.rowHead} wrap={false}>
              <View style={styles.flex1}>
                <Text style={styles.th}>LOCATION</Text>
              </View>
              <View style={styles.flex1}>
                <Text style={styles.th}>UNIT NAME</Text>
              </View>
              <View style={styles.flex1}>
                <Text style={{ ...styles.th, textTransform: "uppercase" }}>
                  {ahu}
                </Text>
              </View>
              <View style={styles.flex1}>
                <Text style={styles.th}>PRE-READ {`(${pressureUnit})`}</Text>
              </View>
              <View style={styles.flex1}>
                <Text style={styles.th}>POST-READ {`(${pressureUnit})`}</Text>
              </View>
              <View style={styles.flex1}>
                <Text style={styles.th}>% REDUCTION</Text>
              </View>
            </View>
            {building?.units
              ?.map((x) => x.name)
              ?.sort(collator.compare)
              ?.map((x) => building?.units?.find((y) => y.name === x))
              .map((unit) => (
                <View style={styles.row} wrap={false}>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>{unit?.location_on_site}</Text>
                  </View>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>{unit?.name}</Text>
                  </View>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>
                      {formatNumbers(unit[ahu])}
                    </Text>
                  </View>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>
                      {Number(unit?.pre_reads?.coil_differential_pressure)
                        ? formatNumbers(
                            Number(unit?.pre_reads?.coil_differential_pressure)
                          )
                        : ""}
                    </Text>
                  </View>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>
                      {Number(unit?.post_reads?.coil_differential_pressure)
                        ? formatNumbers(
                            Number(unit?.post_reads?.coil_differential_pressure)
                          )
                        : ""}
                    </Text>
                  </View>
                  <View style={styles.flex1}>
                    <Text style={styles.center}>
                      {formatNumbers(getReductionPressureDropPercent(unit))}%
                    </Text>
                  </View>
                </View>
              ))}
            <View style={styles.row}>
              <View
                style={{
                  display: "flex",
                  flex: 5,
                  borderWidth: 0.5,
                  borderStyle: "solid",
                  borderColor: "#333",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  paddingVertical: 4,
                }}
              >
                <Text
                  style={{
                    paddingTop: 2,
                    lineHeight: 1,
                    marginRight: 4,
                    fontSize: 10,
                    color: "#189dd8",
                    fontFamily: "HelveticaNeueBold",
                  }}
                >
                  AVERAGE PRESSURE DROP:
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flex: 1,
                  borderWidth: 0.5,
                  borderStyle: "solid",
                  borderColor: "#333",
                  paddingVertical: 4,
                }}
              >
                <Text
                  style={{
                    margin: "auto",
                    fontSize: 10,
                    color: "#189dd8",
                    fontFamily: "HelveticaNeueBold",
                    paddingTop: 2,
                    lineHeight: 1,
                  }}
                >
                  {formatNumbers(getAvgPressureDropByBuilding(building))}%
                </Text>
              </View>
            </View>
          </View>
        ))}

        <View wrap={false}>
          <Text style={styles.header}>PRESSURE DROP</Text>
          <Text style={styles.text}>
            After performing coil cleaning and penetrating through the depth of
            the coil:
          </Text>
          <Text style={[styles.text, { fontWeight: 900 }]}>
            - Average pressure drop across all AHUs dropped significantly by{" "}
            <Text
              style={{
                // fontSize: 12,
                color: "#189dd8",
                fontFamily: "HelveticaNeueBold",
              }}
            >
              {" "}
              {formatNumbers(pressureAvg || 0)}%
            </Text>
          </Text>
          <Text style={styles.text}>
            - This leads to annual fan energy savings of{" "}
            <Text
              style={{
                // fontSize: 12,
                color: "#189dd8",
                fontFamily: "HelveticaNeueBold",
              }}
            >
              {formatNumbers(fanEnergySaving || 0)} kWh.
            </Text>{" "}
          </Text>
          <Text style={styles.text}>
            - For all units, cleaning the coils removed the insulation layer and
            therefore increased the thermal cooling capacity.
          </Text>
        </View>
      </View>
      {/* <View style={{ height: 100, backgroundColor: "#eee" }}>
        <Text style={{ margin: "auto" }}>Chart here</Text>
      </View> */}
      <View
        style={{
          marginTop: "auto",
          position: "relative",
          marginTop: 16,
          height: 12,
        }}
      >
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "grey",
            zIndex: 10,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              paddingHorizontal: 2,
              color: "white",
            }}
          >
            2
          </Text>
        </View>
        <View style={{ backgroundColor: "grey", height: 1, marginTop: 5 }}>
          <Text></Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    display: "flex",
    flex: 1,
    padding: 16,
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowHead: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#189dd8",
    color: "#FFFFFF",
  },
  header: {
    color: "#189dd8",
    fontSize: 24,
    fontFamily: "HelveticaNeue",
    marginTop: 24,
  },
  text: {
    color: "#333333",
    fontSize: 10.5,
    paddingVertical: 4,
    fontFamily: "HelveticaNeue",
  },
  smallText: {
    color: "#333333",
    fontSize: 8,
    fontFamily: "HelveticaNeue",
  },
  flex1: {
    display: "flex",
    flex: 1,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: "#333",
  },
  center: {
    margin: "auto",
    fontSize: 10,
    paddingTop: 10,
    paddingBottom: 8,
    paddingHorizontal: 4,
    fontFamily: "HelveticaNeue",
    lineHeight: 1,
  },
  th: {
    margin: "auto",
    fontSize: 10,
    paddingTop: 10,
    paddingBottom: 8,
    paddingHorizontal: 4,
    fontFamily: "HelveticaNeueBold",
    lineHeight: 1,
  },
});
