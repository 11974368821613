import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import {
  PageHeader,
  PageHeaderControl,
  IconSettings,
  Icon,
  Tabs,
  TabsPanel,
  Button,
  ButtonGroup,
  BrandBand,
  Input,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import moment from "moment";
import { FlatfileButton } from "@flatfile/react";
import { NotesTab } from "./NotesTab";
import { ContactsList } from "../locations/ContactsList";
import { OpportunitiesList } from "./OpportunitiesList";
import { EquipmentList } from "./EquipmentList";
import { ProjectsList } from "./ProjectsList";
import OpportunityForm from "../opportunities/ModalAddOpportunity";
import { ModalContext } from "../ModalContext";
import { countries } from "../../utilities/states-list";
import { Auth } from "aws-amplify";
import ConfirmDelete from "../modals/ConfirmDelete";

const EQUIPMENT_TYPES_QUERY = gql`
  query equipmentTypesQuery {
    equipmentTypes {
      id
      type
    }
  }
`;

const SINGLE = gql`
  query locationById($location_id: ID!, $company_id: ID!) {
    location(id: $location_id) {
      id
      country
      city
      state
      address
      location_name
      location_code
      operating_days_week
      operating_hours_day
      electricity_cost
      recirculated_air
      contacts {
        id
        contact {
          id
          contact_name
          phone_number
          email
          company_title
          created_at
        }
      }
      equipments {
        id
        cfm
        lps
        name
        building
        calculated_price
        location_on_site
        size
        notes {
          id
          message
        }
        equipment_type {
          id
          type
        }
        data {
          id
          created_at
          files {
            id
          }
          pre_reads {
            id
          }
          post_reads {
            id
          }
        }
      }
      projects {
        id
        project_name
        stage
        total_amount
        start_date
        created_at
      }
      opportunities {
        id
        name
        stage
        total_amount
        created_at
      }
      notes {
        id
        message
        created_at
        created_by {
          id
          user_name
        }
      }
    }
    company(id: $company_id) {
      id
      company_name
      country
      contacts {
        id
        contact_name
      }
    }
  }
`;

const ADD_NOTE = gql`
  mutation addNote($input: NoteInput!) {
    createNote(input: $input) {
      id
    }
  }
`;

const LOCATION_CONTACTS = gql`
  query LocationContact($location_id: Int!) {
    locationContactByLocationId(location_id: $location_id) {
      is_primary
      location(location_id: $location_id) {
        location_name
      }
    }
  }
`;

const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: ID!, $input: LocationUpdate!) {
    updateLocation(where: { id: $id }, data: $input) {
      id
    }
  }
`;

const ADD_EQUIPMENT = gql`
  mutation AddEquipment($input: EquipmentInput!) {
    createEquipment(input: $input) {
      id
    }
  }
`;

const DELETE = gql`
  mutation Delete($id: ID!) {
    deleteLocation(where: { id: $id }) {
      id
    }
  }
`;

function LocationDetail({ match, ...props }) {
  const {
    loading: locationsContactsLoading,
    error: locationsContactsError,
    data: locationsContactsData,
  } = useQuery(LOCATION_CONTACTS, {
    variables: {
      location_id: parseInt(match.params.locationId),
    },
  });

  const [isOpen, setOpen] = useState(false);
  const [deleteMutation, { loading: deleting }] = useMutation(DELETE);

  const [addNote] = useMutation(ADD_NOTE);
  const [primaryContacts, setPrimaryContacts] = useState([]);
  const { loading, error, data, refetch } = useQuery(SINGLE, {
    variables: {
      location_id: parseInt(match.params.locationId),
      company_id: parseInt(match.params.companyId),
    },
  });

  console.log(data?.location);

  const { data: equipmentTypesData } = useQuery(EQUIPMENT_TYPES_QUERY);

  const [selectedStates, setSelectedStates] = useState(
    countries["united states"]
  );

  useEffect(() => {
    setSelectedStates(countries[data?.location?.country] || []);
  }, [data?.location]);

  useEffect(() => {
    if (locationsContactsData) {
      setPrimaryContacts(locationsContactsData.locationContactByLocationId);
    }
  }, [locationsContactsData]);

  const { handleModal } = React.useContext(ModalContext);
  const [updateLocation, { loading: updating, data: updated }] = useMutation(
    UPDATE_LOCATION
  );
  const [addEquipment] = useMutation(ADD_EQUIPMENT);

  const onSubmit = (data) => {
    data.address = `${data.address1} | ${data.address2}`;

    delete data.address1;
    delete data.address2;

    if (data?.electricity_cost) {
      data["electricity_cost"] = Number(data?.electricity_cost);
    } else {
      delete data?.electricity_cost;
    }

    if (data?.operating_hours_day) {
      data["operating_hours_day"] = Number(data?.operating_hours_day);
    } else {
      delete data?.operating_hours_day;
    }

    if (data?.operating_days_week) {
      data["operating_days_week"] = Number(data?.operating_days_week);
    } else {
      delete data?.operating_days_week;
    }

    if (data?.recirculated_air) {
      data["recirculated_air"] = Number(data?.recirculated_air);
    } else {
      delete data?.recirculated_air;
    }

    updateLocation({
      variables: { id: parseInt(match.params.locationId), input: data },
    }).then(() => refetch());
  };

  const { handleSubmit, control } = useForm();
  const date = new Date().getTime();
  const triggerOpportunityModal = () =>
    handleModal(
      <OpportunityForm location={match.params.locationId} refetch={refetch} />,
      "Add Opportunity"
    );

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const address = data.location.address?.split("|");

  const details = [
    {
      label: "Address",
      content: (
        <p>
          {address?.join(", ")}
          <br />
          {data.location.city}, {data.location.state}
        </p>
      ),
    },
    {
      label: "Company",
      content: (
        <Link to={`/companies/${data.company.id}`}>
          {data.company.company_name}
        </Link>
      ),
    },
    {
      label: "Primary contact",
      content: primaryContacts.map((primary) => (
        <Link
          to={`/contacts/${primary.contact.id}`}
          style={{ "padding-right": "5px" }}
        >
          {primary.contact.contact_name}
        </Link>
      )),
      truncate: true,
    },
    {
      label: "Equipment",
      content: data.location.equipments ? (
        <p>{data.location.equipments.length}</p>
      ) : (
        0
      ),
      truncate: true,
    },
    {
      label: "Created at",
      // TODO!! content: data.location.created_at,
      content: moment(date).format("DD-MMM-YYYY"),
      truncate: true,
    },
  ];

  const Actions = (props) => (
    <React.Fragment>
      <PageHeaderControl>
        <ButtonGroup variant="list" id="button-group-page-header-actions">
          <FlatfileButton
            licenseKey="e335c423-3909-4363-accf-88c0cd05947f"
            customer={{ userId: "12345" }}
            settings={{
              type: "Equipment",
              fields: [
                { label: "Unit Name", key: "name" },
                { label: "Building", key: "building" },
                { label: "Location on site", key: "location_on_site" },
                {
                  label:
                    data?.location?.country === "australia" ? "LPS" : "CFM",
                  key: data?.location?.country === "australia" ? "lps" : "cfm",
                },

                {
                  label: "Unit Type",
                  key: "equipment_type",
                  type: "select",
                  options: equipmentTypesData?.equipmentTypes.map((x) => ({
                    value: x.id,
                    label: x.type,
                  })),
                },
                { label: "Note", key: "note" },
              ],
            }}
            onData={async (results) => {
              let arr = [];
              const user = await Auth.currentAuthenticatedUser();
              results.data.map((obj) => {
                let newObj = {
                  name: obj.name,
                  building: obj.building,
                  cfm: obj?.cfm ? parseFloat(obj?.cfm) : null,
                  lps: obj?.lps ? parseFloat(obj?.lps) : null,
                  location_id: Number(props.match.params.locationId),
                  note: obj.note,
                  location_on_site: obj.location_on_site,
                  equipment_type_id: Number(obj.equipment_type),
                };

                arr.push(newObj);
              });

              await Promise.all(
                arr.map(async (data) => {
                  const { note, ...input } = data;
                  const res = await props.addEquipment({
                    variables: { input },
                  });

                  if (note) {
                    addNote({
                      variables: {
                        input: {
                          message: note,
                          created_by:
                            Number(user?.attributes["custom:userId"]) || 1,
                          equipment: Number(res.data.createEquipment.id),
                        },
                      },
                    });
                  }
                })
              ).then(() => props?.refetch());

              return "Your upload is complete!";
            }}
            render={(importer, launch) => {
              return <Button onClick={launch} label="Upload Equipment List" />;
            }}
            on
          ></FlatfileButton>
          <Button
            label="Add Opportunity"
            onClick={() => props.triggerOpportunityModal()}
          />
          <Button
            iconCategory="utility"
            iconName="delete"
            iconPosition="left"
            label="Delete"
            onClick={() => setOpen(true)}
          />
        </ButtonGroup>
      </PageHeaderControl>
    </React.Fragment>
  );

  return (
    <IconSettings iconPath="/icons">
      <BrandBand
        id="brand-band-lightning-blue"
        className="slds-p-around_small"
        theme="lightning-blue"
      >
        <PageHeader
          details={details}
          icon={
            <Icon
              assistiveText={{ label: "Location" }}
              category="standard"
              name="account"
            />
          }
          label="Location:"
          title={data.location.location_name}
          variant="record-home"
          info={"whatever"}
          onRenderActions={() =>
            Actions({
              addEquipment,
              triggerOpportunityModal,
              match,
              refetch,
            })
          }
        />

        <div className="slds-grid slds-wrap slds-m-top_medium slds-gutters">
          <div className="slds-col slds-col slds-size_3-of-5">
            <Tabs id="left" className="slds-var-p-around_small">
              <TabsPanel label="Details">
                {updated?.updateLocation?.id && (
                  <div
                    style={{
                      border: "1px solid #04844b",
                      padding: "4px 8px",
                      color: "#04844b",
                      borderRadius: "4px",
                    }}
                  >
                    Update successful!{" "}
                  </div>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    as={
                      <Input
                        type="text"
                        placeholder="name"
                        label="Location name"
                        name="location_name"
                      />
                    }
                    control={control}
                    name="location_name"
                    defaultValue={data.location.location_name}
                  />
                  <Controller
                    as={
                      <Input
                        type="text"
                        placeholder="Address line 1"
                        label="Address line1"
                        name="address1"
                      />
                    }
                    control={control}
                    name="address1"
                    defaultValue={address[0] || ""}
                  />
                  <Controller
                    as={
                      <Input
                        type="text"
                        placeholder="Address line 2"
                        label="Address line2"
                        name="address2"
                      />
                    }
                    control={control}
                    name="address2"
                    defaultValue={address[1] || ""}
                  />
                  <Controller
                    as={
                      <Input
                        type="text"
                        placeholder="name"
                        label="City"
                        name="city"
                      />
                    }
                    control={control}
                    name="city"
                    defaultValue={data.location.city}
                  />
                  <div style={{ height: "8px" }} />
                  <Controller
                    as={
                      <label htmlFor="country">
                        Country
                        <br />
                        <select
                          name="country"
                          onChange={({ target }) =>
                            setSelectedStates(countries[target.value])
                          }
                        >
                          <option
                            value={"united states"}
                            selected={data.location.country === "united states"}
                          >
                            United states
                          </option>
                          <option
                            value={"australia"}
                            selected={data.location.country === "australia"}
                          >
                            Australia
                          </option>
                        </select>
                      </label>
                    }
                    control={control}
                    name="country"
                    defaultValue={data.location.country || "united states"}
                  />
                  <div style={{ height: "8px" }} />
                  <Controller
                    as={
                      <label htmlFor="state">
                        State
                        <br />
                        <select name="state">
                          {selectedStates.map((state) => {
                            return (
                              <option
                                key={state.State}
                                value={state.State}
                                selected={data.location.state === state.State}
                              >
                                {state.State}
                              </option>
                            );
                          })}
                        </select>
                      </label>
                    }
                    control={control}
                    name="state"
                    defaultValue={data.location.state || ""}
                  />
                  <br /> <br />
                  <div className="slds-grid slds-grid_pull-padded slds-grid_vertical-align-center">
                    <div className="slds-col_padded">
                      {" "}
                      <Controller
                        as={
                          <Input
                            name="operating_hours_day"
                            label="Operating (hours/day)"
                            type="text"
                          />
                        }
                        control={control}
                        name="operating_hours_day"
                        defaultValue={data.location.operating_hours_day}
                      />
                    </div>
                    <div className="slds-col_padded">
                      <Controller
                        as={
                          <Input
                            name="operating_days_week"
                            label="Operating (days/week)"
                            type="text"
                          />
                        }
                        control={control}
                        name="operating_days_week"
                        defaultValue={data.location.operating_days_week}
                      />
                    </div>
                    <div className="slds-col_padded">
                      <Controller
                        as={
                          <Input
                            name="electricity_cost"
                            label="Electricity cost  (cents/kWh)"
                            type="text"
                          />
                        }
                        control={control}
                        name="electricity_cost"
                        defaultValue={data.location.electricity_cost}
                      />
                    </div>
                    <div className="slds-col_padded">
                      <Controller
                        as={
                          <Input
                            name="recirculated_air"
                            label="Recirculated air (%)"
                            type="text"
                          />
                        }
                        control={control}
                        name="recirculated_air"
                        defaultValue={data.location.recirculated_air}
                      />
                    </div>
                  </div>
                  <br />
                  <Button
                    type="submit"
                    disabled={updating}
                    label={updating ? "Save..." : "Save"}
                  />
                </form>
              </TabsPanel>
              <TabsPanel label="Contacts">
                <ContactsList
                  location={parseInt(match.params.locationId)}
                  company={match.params.companyId}
                />
              </TabsPanel>
              <TabsPanel label="Equipment">
                <EquipmentList
                  location={data.location.id}
                  country={data?.location?.country}
                  equipment={data?.location?.equipments}
                  refetch={refetch}
                />
              </TabsPanel>
              <TabsPanel label="Projects">
                <ProjectsList projects={data.location.projects} />
              </TabsPanel>
            </Tabs>
          </div>
          <div className="slds-col slds-col slds-size_2-of-5">
            <Tabs id="right" className="slds-var-p-around_small">
              <TabsPanel label="Opportunities">
                <OpportunitiesList
                  company={match.params.companyId}
                  opportunities={data?.location?.opportunities}
                  id={match.params.locationId}
                  refetch={refetch}
                />
              </TabsPanel>
              <TabsPanel label="Notes">
                <NotesTab
                  notes={data.location.notes}
                  location={data.location.id}
                  refetch={refetch}
                />
              </TabsPanel>
            </Tabs>
          </div>
        </div>
        <ConfirmDelete
          isOpen={isOpen}
          toggleOpen={setOpen}
          action={deleteMutation}
          variables={{ id: Number(data?.location?.id) }}
          loading={deleting}
          url={`/companies/${match.params.companyId}`}
          message={`${data?.location?.location_name} Location and all related Projects, Opportunities and Equipment will be permanently deleted and you won't be able to recover them. `}
        />
      </BrandBand>
    </IconSettings>
  );
}

export default LocationDetail;
