import React from "react";
import { View, Text, StyleSheet, Link } from "@react-pdf/renderer";

export function ProposalFooter({ data, proposal }) {
  return (
    <View style={styles.footer}>
      <View style={styles.line}>
        <View style={[styles.textBox, { alignItems: "flex-end" }]}>
          <Text style={styles.title}>Corporate Headquarters</Text>
          <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
            3927 Main Street, Suite 130, Dallas, TX 75226
          </Text>
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <Link src={`mailto:${data.location.company.created_by.email}`}>
              <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
                {data.location.company.created_by.email}{" "}
              </Text>
            </Link>
            <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
              {" "}
              | {data.location.company.created_by.phone_number}{" "}
            </Text>
          </View> */}
        </View>
        <View style={styles.verticalLine} />
        <View style={[styles.textBox, { alignItems: "flex-start" }]}>
          <Text style={styles.title}>Asia Pacific Headquarters</Text>
          <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
            1523 Dandenong Road, Oakleigh, Victoria, 3166, Australia
          </Text>
          {/* <View style={{ display: "flex", flexDirection: "row" }}>
            <Link src={`mailto:${proposal.created_by.email}`}>
              <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
                {proposal.created_by.email}{" "}
              </Text>
            </Link>
            <Text style={{ color: "#6c6c6c", fontSize: 10.5 }}>
              {" "}
              | {proposal.created_by.phone_number}{" "}
            </Text>
          </View> */}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  footer: {
    fontFamily: "Helvetica Neue",
    width: "100%",
    backgroundColor: "#ffffff",
    flexDirection: "row",
    left: 0,
    marginTop: "auto",
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    bottom: 0,
    right: 0,
  },
  line: {
    flex: 1,
    width: "100%",
    background: "#000000",
    // height: "100px",
    display: "flex",
    flexDirection: "row",
    paddingVertical: 10,
    marginHorizontal: 12,
    borderTopColor: "#323232",
    borderTopWidth: 0.6,
  },
  title: {
    fontSize: 11,
    // fontWeight: 900,
    display: "block",
    textTransform: "uppercase",
    marginBottom: 2,
    color: "#189DD8",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    fontSize: 10,
    flex: 0.5,
  },
  verticalLine: {
    width: 0.6,
    marginHorizontal: 8,
    backgroundColor: "#323232",
    height: 45,
    marginTop: -5,
  },
});
