import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { CompanyCombobox } from "../../features/CompanyCombobox";
import { LocationCombobox } from "../../features/LocationCombobox";
import { Button, Input } from "@salesforce/design-system-react";
import { gql, useMutation } from "@apollo/client";
import { ModalContext } from "../ModalContext";
import Auth from "@aws-amplify/auth";

const ADD_OPPORTUNITY = gql`
  mutation CreateOpportunity($input: OpportunityInput!) {
    createOpportunity(input: $input) {
      id
      name
    }
  }
`;

function OpportunityForm(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [company, setCompany] = useState(props.id || "");
  const [locationSelect, setLocation] = useState(props.location || "");
  const [totalTime, setTotalTime] = useState(null);
  const [addOpportunity, { error }] = useMutation(ADD_OPPORTUNITY);
  const { register, handleSubmit, errors, control } = useForm();

  const select = (item) => {
    setCompany(item);
    let loc = props.companies.find(({ id }) => id === item.id).locations;
    setLocations(loc);
  };

  const selectLocation = (item) => setLocation(item);

  const onSubmit = async (data) => {
    const user = await Auth.currentAuthenticatedUser();

    data["created_by"] = Number(user?.attributes["custom:userId"]) || 1;
    data["location_id"] = locationSelect
      ? parseInt(locationSelect.id)
      : parseInt(props.location);
    data["stage"] = "Warm Prospect";
    data["total_amount"] = parseInt(data.total_amount);

    //if (locations.length > 0 && companies.length > 0) {
    addOpportunity({
      variables: { input: data },
      // refetchQueries: [{ query: OPPORTUNITIES }],
    }).then(() => {
      //if(props?.refetch){
      return props?.refetch();
      //}
    });

    handleModal();
    //}
  };

  useEffect(() => {
    if (props && props.companies) {
      setCompanies(props?.companies);
    }
    if (props.locations) {
      setLocations(props.locations);
    }
    if (props.location) {
      setLocation({ id: props.location });
    }
  }, [props]);

  if (locations.length == 0 && companies.length == 0 && !props.location)
    return <div>Please add locations before adding an opportunity.</div>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <section className="slds-gutters ">
        <div class="slds-form-element__control">
          <Controller
            as={
              <label htmlFor="name">
                Name *
                <Input name="name" type="text" ref={register} />
              </label>
            }
            control={control}
            name="name"
            defaultValue={""}
            rules={{ required: true }}
            className="slds-col"
          />
          {errors?.name?.type === "required" && (
            <p style={{ fontSize: "11px", color: "red" }}>
              Your input is required
            </p>
          )}
        </div>

        <div class="slds-form-element__control">
          <Controller
            as={
              <label htmlFor="currency">
                Currency *
                <select name="currency" ref={register}>
                  <option
                    value="USD"
                    selected={props?.company?.country === "united states"}
                  >
                    USD
                  </option>
                  <option
                    value="AUD"
                    selected={props?.company?.country === "australia"}
                  >
                    AUD
                  </option>
                </select>
              </label>
            }
            className="slds-col"
            control={control}
            name="currency"
            defaultValue={
              props?.company?.country === "australia" ? "AUD" : "USD"
            }
            rules={{ required: true }}
          />
        </div>

        <br />

        <div class="slds-form-element__control">
          <Controller
            as={
              <label htmlFor="total_amount">
                Total amount *
                <Input
                  name="total_amount"
                  type="number"
                  ref={register}
                  // defaultValue={Number(0)}
                  // value={totalTime?.total_amount || null}
                  // onChange={({ target }) =>
                  //   setTotalTime({
                  //     ...totalTime,
                  //     total_amount: Number(target.value),
                  //   })
                  // }
                />
              </label>
            }
            className="slds-col"
            control={control}
            name="total_amount"
            defaultValue={null}
            rules={{ required: true }}
          />

          {errors?.total_amount?.type === "required" && (
            <p style={{ fontSize: "11px", color: "red" }}>
              Your input is required
            </p>
          )}
        </div>
        {companies.length > 0 && (
          <div class="slds-form-element__control">
            <label htmlFor="combobox" className="slds-col">
              Company *
              <CompanyCombobox
                options={companies}
                select={select}
                labels={{
                  label: "Company *",
                  placeholder: "Select a company...",
                }}
                errorText={"The selected company has no locations"}
              />
              {company && locations?.length === 0 && (
                <p style={{ fontSize: "11px", color: "red" }}>
                  The selected company has no locations
                </p>
              )}
            </label>
          </div>
        )}
        {!props.location && (
          <div class="slds-form-element__control">
            <label htmlFor="combobox" className="slds-col">
              Location *
              <LocationCombobox
                options={locations}
                select={selectLocation}
                required
                readOnly={!company && !props?.company}
              />
              {locations.length === 0 && (
                <p style={{ fontSize: "11px", color: "red" }}>
                  The selected locations
                </p>
              )}
            </label>
          </div>
        )}

        <br />
        <div class="slds-form-element__control" style={{ display: "flex" }}>
          <Button
            type="submit"
            label="Save"
            variant="brand"
            style={{ flex: 1 }}
          />
        </div>
      </section>
    </form>
  );
}

export default OpportunityForm;
