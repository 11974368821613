import { gql } from '@apollo/client';

const GET_CONTACT = gql`
  query GetContacts($id: ID!) {
    company(id: $id) {
      contacts {
        id
        company_title
        email
        phone_number
        contact_name
        company {
          company_name
        }
      }
    }
  }
`;

export default GET_CONTACT;
