import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  CardFilter,
} from "@salesforce/design-system-react";
import { Link } from "react-router-dom";
import searchFilter from "../../utilities/helpers";
import { ModalContext } from "../ModalContext";
import { AddContactForm } from "./ModalAddContactToLocation";
import { useQuery, gql, useMutation } from "@apollo/client";

const LOCATION_CONTACTS = gql`
  query LocationContact($location_id: Int!) {
    locationContactByLocationId(location_id: $location_id) {
      is_primary
      role
      contact {
        id
        contact_name
        email
        phone_number
        company {
          id
        }
      }
    }
  }
`;

const ContactLink = ({ children, item, ...props }) => (
  <DataTableCell title={children} {...props}>
    <Link to={`/contacts/${item.id}`}>{children}</Link>
  </DataTableCell>
);

ContactLink.displayName = DataTableCell.displayName;

export function ContactsList(props) {
  const { handleModal } = React.useContext(ModalContext);

  const { loading, error, data, refetch } = useQuery(LOCATION_CONTACTS, {
      variables: {
        location_id: parseInt(props.location),
      },
    });

  const [contacts, setContacts] = useState([]);
  const [items, setItems] = useState([]);
  const [input, setInput] = useState([]);

  useEffect(() => {
    if (data && data.locationContactByLocationId) {
      let arr = []
      data.locationContactByLocationId.map((contact) => {
        arr.push(contact.contact)
      })
      setContacts(arr);
    }
  }, [data]);

  const filterTable = (input) => {
    setInput(input);
    setItems(searchFilter(contacts, "contact_name", input));
  };

  if (loading) return <div>Loading ...</div>
  if(error) return <div>Error!</div>
  
  const triggerModal = () => handleModal(<AddContactForm id={props.company} location={props.location} refetch={refetch} />, "Add Contact to Location");
  return (
    <IconSettings iconPath="/icons">
      <Card
        id="ContactCard"
        headerActions={
          <Button label="Add Contacts" onClick={() => triggerModal()} />
        }
        filter={
          contacts.length > 0 && (
            <CardFilter onChange={(e) => filterTable(e.target.value)} />
          )
        }
        heading={`Contacts (${contacts.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          contacts.length === 0 ? (
            <CardEmpty heading="No Contacts">
              <Button label="Add Contact" onClick={() => triggerModal()} />
            </CardEmpty>
          ) : null
        }
      >
        <DataTable
          fixedLayout
          items={items.length > 0 || input.length > 0 ? items : contacts}
          id="getContacts"
          on
        >
          <DataTableColumn label="Id" property="id"></DataTableColumn>
          <DataTableColumn label="Contact name" property="contact_name">
            <ContactLink />
          </DataTableColumn>
          <DataTableColumn
            label="Company title"
            property="company_title"
          ></DataTableColumn>
          <DataTableColumn label="Email" property="email"></DataTableColumn>
          <DataTableColumn
            label="Phone number"
            property="phone_number"
          ></DataTableColumn>
        </DataTable>
      </Card>
    </IconSettings>
  );
}
