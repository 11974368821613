import React from "react";
import { View, Text, Image, StyleSheet, Link } from "@react-pdf/renderer";
import moment from "moment";

export function Header2({ date }) {
  return (
    <View style={styles.header2}>
      <View style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <View style={styles.row}>
          <Text
            style={{
              ...styles.left,
              fontSize: 12,
              fontFamily: "HelveticaNeueBold",
              color: "#595959",
            }}
          >
            BLUE BOX AIR, LLC
          </Text>
        </View>
        <View style={styles.blueSpacer}>
          <Text> </Text>
        </View>
        <View style={styles.row}>
          <Text
            style={{
              ...styles.left,
              fontSize: 10,
              color: "grey",
              fontFamily: "HelveticaNeue",
            }}
          >
            REPORT DATE: {moment().format("DD-MMM-YYYY")}
          </Text>
        </View>
      </View>
      <Link src="https://blueboxair.com">
        <Image
          source="/bba-logo-light.png"
          style={{ height: 48, marginLeft: 4 }}
          cache={true}
        />
      </Link>
    </View>
  );
}

const styles = StyleSheet.create({
  header2: {
    display: "flex",
    flexDirection: "row",

    height: 48,
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  blueSpacer: {
    backgroundColor: "#189dd8",
    width: "100%",
    height: 4,
  },
  left: {
    marginRight: "auto",
    marginVertical: "auto",
  },
  right: {
    marginLeft: "auto",
    marginVertical: "auto",
  },
});
