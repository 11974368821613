import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../Header";
import dayjs from "dayjs";

const WORDS = {
  ASC: "Ascending",
  DESC: "Descending",
  CreatedDate: "Date",
  "Equipment__r.Name": "Name",
  "Equipment__r.Location_on_Site__c": "Location",
};

export default function Notes() {
  const { projectId } = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");
  const [searchText, setSearchText] = useState("");

  const [sort, setSort] = useState("CreatedDate");
  const [sortBy, setSortBy] = useState("DESC");

  const [active, setActive] = useState(-1);
  const [columnCount, setColumnCount] = useState(4);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setColumnCount(Math.floor(window.innerWidth / 300));
    });
    setColumnCount(Math.floor(window.innerWidth / 300));
    return () => window.removeEventListener("resize");
  }, []);
  function getExt(url) {
    return url.split(".").pop();
  }
  useEffect(() => {
    setLoading(true);
    fetch(
      // `https://blueboxserver.herokuapp.com/public/project/${projectId}/photos`
      `https://api.blueboxair.com/public/project/${projectId}/notes?search=${search}&sort=${sort}&by=${sortBy}`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setData(res);
        // setPageCount(Math.ceil(res?.photos?.length / pageSize));
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [projectId, search, sort, sortBy]);

  return (
    <div className="App">
      <Header showButton={false} />

      <div style={{ padding: "60px 16px 0 16px" }}>
        <div
          style={{
            backgroundColor: "#fff",
            overflowX: "auto",
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            // alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#f3f2f2",
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 8,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", padding: 16 }}
            >
              <h1
                style={{
                  fontSize: 28,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 8,
                }}
              >
                <span style={{ fontSize: 12, fontWeight: 700 }}>Project</span>
                <span style={{ lineHeight: "24px" }}>
                  {data?.project?.Name || "Loading project name ..."}
                </span>
              </h1>
              <p>{`${data?.notes?.totalSize || 0} items • sorted by ${
                WORDS[sort]
              } ${WORDS[sortBy]}`}</p>
            </div>

            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: "auto",
                paddingRight: 16,
              }}
            >
              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSearch(searchText);
                  }}
                >
                  <input
                    name="search"
                    value={searchText}
                    placeholder="search..."
                    onChange={({ target }) => setSearchText(target.value)}
                    style={{
                      border: "1px solid #aaa",
                      borderRadius: 4,
                      padding: 4,
                      margin: 4,
                      height: 35,
                      width: 200,
                    }}
                  />
                </form>
              </div>
              <div>
                <select
                  name="sort"
                  onChange={({ target }) => setSort(target.value)}
                  value={sort}
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 4,
                    padding: 4,
                    margin: 4,
                    height: 35,
                  }}
                >
                  <option value="Equipment__r.Name">Name</option>
                  <option value="Equipment__r.Location_on_Site__c">
                    Location
                  </option>
                  <option value="CreatedDate">Date</option>
                
                </select>
              </div>

              <div>
                <button
                  onClick={() =>
                    setSortBy((sortBy) => (sortBy === "DESC" ? "ASC" : "DESC"))
                  }
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 4,
                    padding: 4,
                    margin: 4,
                    height: 35,
                    width: 35,
                    outline: "none",
                    background: "#fff",
                  }}
                >
                  {sortBy === "ASC" ? (
                    <img src="https://img.icons8.com/ios/35/000000/generic-sorting-2.png" />
                  ) : (
                    <img src="https://img.icons8.com/ios/35/000000/generic-sorting.png" />
                  )}
                </button>
              </div>
            </div> */}
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              overflowX: "auto",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          >
            {/* <div>
            <Gallery images={IMAGES} />
          </div> */}
            {/* <div>
            <List
              height={550}
              itemCount={IMAGES.length}
              itemSize={180}
              width={"100%"}
              innerElementType={innerElementType}
            >
              {Row}
          
            </List>
          </div> */}

            {loading
              ? "Loading ..."
              : data?.notes?.records?.map((note) => (
                  <div
                    key={note?.Id}
                    style={{
                      display: "flex",
                      minHeight: 50,
                      padding: 16,
                      margin: "8px 32px",
                      border: "1px solid grey",
                      borderRadius: 8,
                      flex: 1,
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        flex: 1,
                      }}
                    >
                      <span> {note?.Content__c}</span>
                      <span style={{ marginTop: "auto", fontSize: "10px" }}>
                        {/* {note?.AddedBy__r?.Email}
                      {" - "} */}
                        {dayjs(note?.CreatedDate).format("MMMM DD, YYYY")}
                      </span>
                    </div>

                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        flex: 1,
                        alignItems: "flex-end",
                      }}
                    >
                      <div>{note?.Equipment__r?.Name}</div>
                      <div>{note?.Equipment__r?.Location_on_Site__c}</div>
                      <div>{note?.Equipment__r?.Building__c}</div>
                    </div>
                  </div>
                ))}
            {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {new Array(pageCount).fill(0, 0, pageCount).map((_, i) => (
              <button
                key={i}
                onClick={() => setPage(i + 1)}
                style={{ margin: 4 }}
              >
                {i + 1}
              </button>
            ))}
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
