import React, { useEffect, useState } from "react";
import {
  GlobalHeader,
  GlobalHeaderNotifications,
  Popover,
  Toast,
  ToastContainer,
  Button,
  GlobalHeaderButton,
} from "@salesforce/design-system-react";
import { gql, useQuery, useSubscription } from "@apollo/client";
import moment from "moment";
import { FixedSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ModalContext } from "./ModalContext";
import ViewNote from "./modals/ViewNote";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import "./global.css";

const NOTIFICATIONS = gql`
  subscription onChangeRequest {
    changeRequested {
      id
      message
      created_by {
        id
        user_name
      }
      equipment {
        id
        name
        project {
          project_name
        }
      }
    }
  }
`;

const NOTES = gql`
  query ChangeRequests($skip: Int, $limit: Int) {
    notesByLabel(label: CHANGE_REQUEST, skip: $skip, limit: $limit) {
      notes {
        id
        message
        created_at
        unread
        created_by {
          id
          user_name
        }
        equipment {
          id
          name
          project {
            id
            project_name
          }
        }
      }
      pagination {
        skip
        total
        limit
        subtotal
      }
    }
  }
`;

const HeaderNotificationsCustomContent = (props) => {
  const { items, hasNextPage, loading, loadMore } = props;
  const { handleModal } = React.useContext(ModalContext);
  const count = hasNextPage ? items.length + 1 : items.length;
  const loadMoreResults = loading ? () => {} : loadMore;
  const isItemLoaded = (index) => !hasNextPage || index < items.length;

  const Row = ({ index, style }) => {
    const item = items[index] || null;
    return item ? (
      <li
        style={style}
        className={`slds-global-header__notification ${
          item?.unread ? "slds-global-header__notification_unread" : ""
        }`}
      >
        <div className="slds-media slds-has-flexi-truncate slds-p-around_x-small">
          <div className="slds-media__body">
            <div className="slds-grid slds-grid_align-spread">
              <a
                href="javascript:void(0);"
                className="slds-text-link_reset slds-has-flexi-truncate"
                onClick={() =>
                  handleModal(
                    <ViewNote refetch={props.refetch} data={item} />,
                    `${item.action} by ${item.name} `
                  )
                }
              >
                <h3
                  className="slds-truncate"
                  title={`${item.action} by ${item.name} `}
                >
                  {`${item.action} by `}
                  <strong>{item.name}</strong>
                </h3>
                <p
                  className="slds-truncate"
                  title={item.comment}
                  style={{ fontSize: "12px" }}
                >
                  {item.comment}
                </p>
                <p className="slds-m-top_x-small slds-text-color_weak">
                  {item.unread ? (
                    <abbr
                      className="slds-text-link slds-m-horizontal_xxx-small"
                      title="unread"
                    >
                      ●
                    </abbr>
                  ) : null}
                  {moment(item.timePosted)?.fromNow()}{" "}
                </p>
              </a>
            </div>
          </div>
        </div>
      </li>
    ) : null;
  };

  return (
    <ul
      id="header-notifications-custom-popover-content"
      style={{ maxHeight: "424px", height: "100%" }}
    >
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={count}
        loadMoreItems={loadMoreResults}
      >
        {({ onItemsRendered, ref }) => (
          <List
            height={400}
            itemCount={count}
            itemSize={80}
            width={300}
            onItemsRendered={onItemsRendered}
            ref={ref}
          >
            {Row}
          </List>
        )}
      </InfiniteLoader>
    </ul>
  );
};
HeaderNotificationsCustomContent.displayName =
  "HeaderNotificationsCustomContent";

export function Header(props) {
  // const { data } = useSubscription(NOTIFICATIONS);
  const data = null;
  const [closed, setClosed] = useState(false);
  const history = useHistory();

  // const {
  //   data: notesData,
  //   loading,
  //   subscribeToMore,
  //   fetchMore,
  //   refetch,
  // } = useQuery(NOTES, {
  //   variables: {
  //     skip: 0,
  //     limit: 10,
  //   },
  // });

  const [notes, setNotes] = useState([]);

  // useEffect(() => {
  //   setNotes(notesData);
  // });

  // useEffect(() => {
  //   subscribeToMore({
  //     document: NOTIFICATIONS,
  //     variables: {},
  //     updateQuery: (prev, { subscriptionData }) => {
  //       if (!subscriptionData.data) return prev;
  //       const newFeedItem = subscriptionData.data.changeRequested;
  //       setNotes((notes) => ({
  //         notesByLabel: {
  //           notes: [...notes?.notesByLabel?.notes, newFeedItem],
  //         },
  //       }));
  //     },
  //   });
  // }, [data]);

  // const loadMore = () => {
  //   return fetchMore({
  //     query: NOTES,
  //     notifyOnNetworkStatusChange: true,
  //     variables: {
  //       skip: notesData?.notesByLabel?.pagination?.skip + 10,
  //       limit: 10,
  //     },
  //     updateQuery: (previousResult, { fetchMoreResult }) => {
  //       const newNotes = fetchMoreResult.notesByLabel?.notes;
  //       const pagination = fetchMoreResult.notesByLabel?.pagination;
  //       return newNotes.length
  //         ? {
  //             notesByLabel: {
  //               __typename: previousResult.notesByLabel.__typename,
  //               notes: [...previousResult.notesByLabel.notes, ...newNotes],
  //               pagination,
  //             },
  //           }
  //         : previousResult;
  //     },
  //   });
  // };

  // const hasNextPage =
  //   notesData?.notesByLabel?.pagination?.total >
  //   notesData?.notesByLabel?.pagination?.skip;

  const changeRequested = data?.changeRequested;

  async function signOut() {
    try {
      // await Auth.signOut();
      await localStorage.setItem("accessToken", null);
      window.location.replace("/login");
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  }

  return (
    <>
      {changeRequested && !closed && (
        <ToastContainer>
          <Toast
            labels={{
              heading: `Change request by ${changeRequested?.created_by?.user_name}`,
              details: `project: ${changeRequested?.equipment?.project?.project_name}, Equipment: ${changeRequested?.equipment?.name}`,
            }}
            onRequestClose={() => {
              setClosed(true);
            }}
          />
        </ToastContainer>
      )}

      <GlobalHeader logoSrc="/bba-logo.png">
        {props?.showButton !== false && (
          <GlobalHeaderButton
            className="sign-out-btn"
            label="Sign Out"
            onClick={signOut}
          />
        )}
        {/* <GlobalHeaderNotifications
          notificationCount={notes?.notesByLabel?.pagination?.subtotal}
          popover={
            <Popover
              ariaLabelledby="header-notifications-custom-popover-content"
              body={
                <HeaderNotificationsCustomContent
                  items={notes?.notesByLabel?.notes?.map((note) => ({
                    action: "Change request",
                    avatar: null,
                    comment: note?.message,
                    id: note?.id,
                    name: note?.created_by?.user_name,
                    timePosted: note?.created_at,
                    unread: note?.unread,
                    equipment: note?.equipment,
                    user: note?.created_by,
                  }))}
                  hasNextPage={hasNextPage}
                  loadMore={loadMore}
                  loading={loading}
                  refetch={refetch}
                />
              }
              id="header-notifications-popover-id"
            />
          }
        /> */}
      </GlobalHeader>
    </>
  );
}
