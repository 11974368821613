import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Textarea } from '@salesforce/design-system-react';
import { useForm, Controller } from 'react-hook-form';
import Moment from 'react-moment';
import CREATE_NOTE from '../../graphql/mutations/createNote';
import { Auth } from 'aws-amplify';


export function NotesTab(props) {
  const [notes, setNotes] = useState([]);
  const [createNote] = useMutation(CREATE_NOTE);
  const { control, handleSubmit } = useForm();
  
  useEffect(() => {
    if (props.notes) {
      setNotes(props.notes)
    }
  },[props])

  async function onSubmit(data) {
    const user = await Auth.currentAuthenticatedUser();

    data['location_id'] = parseInt(props.location);
    data['created_by'] = parseInt(user.attributes['custom:userId']);

    await createNote({ variables: { data } }).then(() => props.refetch());
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          as={
            <Textarea
              label="Messages"
              name="message"
            />
          }
          control={control}
          name="message"
          defaultValue=""
        />
        <br />
        <Button type="submit">Save</Button>
      </form>
      {
        (notes.length > 0) &&
          notes.map(note => {
            let date = note.created_at;
            return (
              <div className="note">
                <p>Message: {note.message}</p>
                <ul>
                  <li>Created <Moment date={date} fromNow /></li>
                  <li>by {note.created_by.user_name}</li>
                </ul>
              </div>
            )
          })
      }
    </div>
  )
}
