import React, { useState, useEffect } from "react";
import {
  DataTable,
  DataTableColumn,
  DataTableCell,
  DataTableRowActions,
  Button,
  Card,
  CardEmpty,
  Icon,
  IconSettings,
  Dropdown,
  CardFilter,
} from "@salesforce/design-system-react";
import searchFilter from "../../utilities/helpers";
import AddEquipment from "../modals/AddEquipment";
import { ModalContext } from "../ModalContext";
import _ from "lodash";
import { Link } from "react-router-dom";

const DataCount = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <p>{item?.data?.length}</p>
  </DataTableCell>
);

DataCount.displayName = DataTableCell.displayName;

const UnitName = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <Link to={`/equipment/${item?.id}`}>{item?.name}</Link>
  </DataTableCell>
);

UnitName.displayName = DataTableCell.displayName;

const Notes = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <p>
      {item?.notes?.map((note) => (
        <li key={note?.id}>{note?.message}</li>
      ))}
    </p>
  </DataTableCell>
);

Notes.displayName = DataTableCell.displayName;

const Type = ({ children, item, ...props }) => (
  <DataTableCell {...props}>
    <p>{item?.equipment_type?.type}</p>
  </DataTableCell>
);

Type.displayName = DataTableCell.displayName;

export function EquipmentList(props) {
  const { handleModal } = React.useContext(ModalContext);
  const [equipment, setEquipment] = useState([]);
  const [items, setItems] = useState([]);
  const [input, setInput] = useState([]);

  useEffect(() => {
    setEquipment(_.orderBy(props.equipment, ["building"]));
  }, [props.equipment]);

  const handleSort = (data) =>
    setEquipment(
      _.orderBy(props.equipment, [data.property], [data.sortDirection])
    );
  const filterTable = (input) => {
    setInput(input);
    setItems(searchFilter(props.equipment, "name", input));
  };

  const handleRowAction = (e, i) => {
    if (i.value == "1") {
      handleModal(
        <AddEquipment
          country={props?.country}
          locationId={props?.location}
          projectId={props?.projectId}
          equipment={e}
          action="update"
          refetch={props?.refetch}
        />,
        "Edit Equipment"
      );
    } else {
      props.onDeleteEquipment(parseInt(e.id));
    }
  };

  return (
    <IconSettings iconPath="/icons">
      <Card
        id="EquipmentCard"
        //headerActions={
        // <Button
        //   label="Add Equipment"
        //   onClick={() =>
        //     handleModal(
        //       <AddEquipment
        //         country={props?.country}
        //         locationId={props?.location}
        //         projectId={props?.projectId}
        //         refetch={props?.refetch}
        //       />,
        //       "Add Equipment"
        //     )
        //   }
        // />
        //}
        // filter={
        //   equipment.length > 0 && (
        //     <CardFilter onChange={(e) => filterTable(e.target.value)} />
        //   )
        // }
        heading={`Equipment (${equipment.length})`}
        icon={<Icon category="standard" name="contact" size="small" />}
        empty={
          equipment.length === 0 ? (
            <CardEmpty heading="No Equipment">
              {" "}
              No Equipment available
              {/* <Button
                label="Add Equipment"
                onClick={() =>
                  handleModal(
                    <AddEquipment
                      country={props?.country}
                      locationId={props?.location}
                      refetch={props?.refetch}
                    />,
                    "Add Equipment"
                  )
                }
              /> */}
            </CardEmpty>
          ) : null
        }
      >
        <div style={{ overflowX: "auto", width: "100%" }}>
          <DataTable
            onSort={handleSort}
            items={items.length > 0 || input.length > 0 ? items : equipment}
            id="getEquipment"
          >
            <DataTableColumn label="Unit Name" property="name">
              <UnitName />
            </DataTableColumn>

            <DataTableColumn
              label={props?.country === "australia" ? "LPS" : "CFM"}
              property={props?.country === "australia" ? "lps" : "cfm"}
            ></DataTableColumn>

            <DataTableColumn
              label="Unit Size"
              property="size"
              sortable
            ></DataTableColumn>

            <DataTableColumn
              label="Price"
              property="calculated_price"
              sortable
            ></DataTableColumn>

            <DataTableColumn
              label="Building"
              property="building"
            ></DataTableColumn>

            <DataTableColumn
              label="Location on site"
              property="location_on_site"
            ></DataTableColumn>

            <DataTableColumn label="Data Count" property="data">
              <DataCount />
            </DataTableColumn>

            <DataTableColumn label="Notes" property="notes">
              <Notes />
            </DataTableColumn>

            <DataTableColumn label="Type" property="equipment_type">
              <Type />
            </DataTableColumn>

            <DataTableRowActions
              options={[
                {
                  id: 0,
                  label: "Edit equipment",
                  value: "1",
                },
                {
                  id: 1,
                  label: "Remove equipment",
                  value: "2",
                },
              ]}
              onAction={handleRowAction}
              dropdown={<Dropdown length="7" />}
            />
          </DataTable>
        </div>
      </Card>
    </IconSettings>
  );
}
