import React from "react";
import { View, StyleSheet, Image, Text, Link } from "@react-pdf/renderer";
import aSavings from "./icons/a_savings.png";
import cSavings from "./icons/c_savings.png";
import fSavings from "./icons/f_savings.png";
import co2Img from "./icons/co2.png";

import { Header2 } from "./Header2";

export default function Highlights({
  totalFanEnergySaving,
  co2,
  totalCoolingSaving,
  totalAnnualSaving,
  project,
  electricityCost,
  currency,
}) {
  let dollarUS = Intl.NumberFormat("en-US", {
    style: "decimal",
  });

  const totalAnnualSavingMoney = dollarUS.format(
    totalAnnualSaving * electricityCost > 1
      ? parseInt(totalAnnualSaving * electricityCost)
      : Math.round(totalAnnualSaving * electricityCost * 100) / 100
  );

  const totalCoolingSavingMoney = dollarUS.format(
    totalCoolingSaving * electricityCost > 1
      ? parseInt(totalCoolingSaving * electricityCost)
      : Math.round(totalCoolingSaving * electricityCost * 100) / 100
  );

  const totalFanEnergySavingMoney = dollarUS.format(
    totalFanEnergySaving * electricityCost > 1
      ? parseInt(totalFanEnergySaving * electricityCost)
      : Math.round(totalFanEnergySaving * electricityCost * 100) / 100
  );

  const co2SavingMonrey = dollarUS.format(
    co2 > 1 ? parseInt(co2) : Math.round(co2 * 100) / 100
  );

  return (
    <View style={styles.container}>
      <Header2 title={project?.project_name} />
      <View style={styles.row}>
        <View style={{ ...styles.col, flex: 1, padding: 16 }}>
          <Text style={styles.header}>HIGHLIGHTS</Text>
          <Text style={styles.text}>
            This was a successful project to demonstrate the Blue Box™
            technology and process. Our technicians were able to penetrate our
            bio-enzymes through all of the coils and improve air flow and
            temperature transfer. With our ability to change up the viscosity of
            the bio-enzymes, the technicians were able to break up the blockage
            areas located inside of the coils. It is recommended that all of the
            systems in the property should be serviced in order to see the
            greatest efficiency and air quality gains for the entire building
            which is what we accomplished here.
          </Text>
          <Text style={styles.header}>ADDITIONAL BENEFITS</Text>
          <Text style={styles.text}>
            - No Downtime for System During Cleaning
          </Text>
          <Text style={styles.text}>- Penetration of Foam</Text>
          <Text style={styles.text}>
            - Low Pressure Application Zero Risk of Damage
          </Text>
          <Text style={styles.text}>- COVID-19 Coil Disinfection</Text>
          <Text style={styles.text}>
            - Reduction in Load on Blower Motor and Components
          </Text>
          <Text style={styles.text}>- Improved Heat Transfer Across Coils</Text>
          <Text style={styles.text}>
            - Improving Comfort Conditions by Restoring Airflow to Original
            Design
          </Text>

          <Text style={styles.header}>CONCLUSION</Text>
          <Text style={styles.text}>
            With Blue Box™, it is now possible to keep the system running at
            peak efficiency. Doing so will reduce the load on the blower motor
            and greatly extend the life of its components. As there is no risk
            of damage to the coils with our low pressure application and pH
            neutral bio-enzymes, it is expected that their useful life will also
            be extended. The systems for an entire building, running at peak
            efficiency, will have a significant impact on the CapEx, OpEx and
            the indoor air quality of the building.
          </Text>

          <View
            style={{
              display: "flex",
              height: 50,
              backgroundColor: "#eeeeee",
              marginVertical: 8,
            }}
          >
            <Link
              style={{
                margin: "auto",
                fontSize: 12,
                color: "#189dd8",
                fontFamily: "HelveticaNeue",
              }}
              src={`https://app.blueboxair.com/projects/${project.id}/photos`}
            >
              {"CLICK "}
              <Link
                style={{ fontFamily: "HelveticaNeueBold" }}
                src={`https://app.blueboxair.com/projects/${project.id}/photos`}
              >
                HERE
              </Link>
              {" TO ACCESS ALL PHOTOS & VIDEOS."}
            </Link>
          </View>
        </View>
        <View
          style={{
            width: 1,
            height: "100%",
            backgroundColor: "grey",
          }}
        >
          <Text></Text>
        </View>
        <View
          style={{
            ...styles.col,
            width: 180,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 4,
            paddingRight: 4,
          }}
        >
          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={aSavings} style={{ width: 32 }} />
            <Text style={styles.stats}>TOTAL ANNUAL SAVINGS</Text>
            <Text style={styles.amount}>
              <Text style={styles.currency}>{`${currency} `}</Text>
              {totalAnnualSavingMoney}
            </Text>
            <Text style={styles.kwh}>
              {dollarUS.format(parseInt(totalAnnualSaving))}{" "}
              <Text style={{ ...styles.currency, fontSize: 12, marginLeft: 4 }}>
                kWh
              </Text>
            </Text>
          </View>
          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={cSavings} style={{ width: 32 }} />
            <Text style={styles.stats}>ANNUAL COOLING SAVINGS</Text>
            <Text style={styles.amount}>
              <Text style={styles.currency}>{`${currency} `}</Text>
              {totalCoolingSavingMoney}
            </Text>
            <Text style={styles.kwh}>
              {dollarUS.format(parseInt(totalCoolingSaving))}{" "}
              <Text style={{ ...styles.currency, fontSize: 12, marginLeft: 4 }}>
                kWh
              </Text>
            </Text>
          </View>

          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={fSavings} style={{ width: 32 }} />
            <Text style={styles.stats}>ANNUAL FAN SAVINGS</Text>
            <Text style={styles.amount}>
              <Text style={styles.currency}>{`${currency} `}</Text>
              {totalFanEnergySavingMoney}
            </Text>
            <Text style={styles.kwh}>
              {dollarUS.format(parseInt(totalFanEnergySaving))}{" "}
              <Text style={{ ...styles.currency, fontSize: 12, marginLeft: 4 }}>
                kWh
              </Text>
            </Text>
          </View>

          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={co2Img} style={{ width: 32 }} />
            <Text style={styles.stats}>ANNUAL CO2 EMISSIONS REMOVED</Text>
            <Text style={styles.amount}>
              {co2SavingMonrey}{" "}
              <Text style={{ ...styles.currency, marginLeft: 1 }}>
                metric tons
              </Text>
            </Text>
            <Text style={styles.kwh}>
              {dollarUS.format(parseInt(totalAnnualSaving))}
              <Text style={{ ...styles.currency, fontSize: 12, marginLeft: 4 }}>
                {" "}
                kWh
              </Text>
            </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          ...styles.row,
          marginBottom: 16,
          marginTop: "auto",
          borderTopWidth: 1,
          borderTopColor: "grey",
          borderTopStyle: "solid",
        }}
      >
        <Link src="https://blueboxair.com">
          <Image
            source="/bba-logo-light.png"
            style={{ height: 64, marginTop: 8, marginLeft: 8 }}
            cache={true}
          />
        </Link>
        <View style={{ flex: 1 }}>
          <Text
            style={{ padding: 8, fontSize: 8, fontFamily: "HelveticaNeue" }}
          >
            Blue Box™ delivers a unique model for ensuring facilities to
            experience the safest air possible within their normal day to day
            operations. With its patented process for how to treat and disinfect
            any type of facility’s HVAC system, Blue Box™ eliminates a critical
            weak point in all building operations. Our innovation is how to
            penetrate through any depth of the heat transfer coil to ensure
            near-perfect surface area coverage. Our process involves one of the
            most comprehensive solutions for how to disinfect these hard to
            reach places so that a building’s air supply is both efficient and
            safe. For further information, visit blueboxair.com
          </Text>
          <View style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 10,
                color: "#189dd8",
                paddingLeft: 8,
                fontWeight: "bold",
                fontFamily: "HelveticaNeueBold",
              }}
            >
              INNOVATING AIR: A NEW LEVEL OF CLEAN.
            </Text>
            <Text
              style={{
                fontSize: 8,
                color: "#333",
                marginLeft: "auto",
                paddingRight: 8,
                fontFamily: "HelveticaNeue",
              }}
            >
              © Blue Box Air, LLC. All rights reserved. March 2021
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    padding: 16,
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    color: "#189dd8",
    fontSize: 24,
    marginTop: 24,
    fontFamily: "HelveticaNeue",
  },
  text: {
    color: "#595959",
    fontSize: 10.5,
    paddingVertical: 4,
    lineHeight: 1.4,
    fontFamily: "HelveticaNeue",
  },
  smallText: {
    color: "#595959",
    fontSize: 8,
    fontFamily: "HelveticaNeue",
  },
  stats: {
    fontSize: 8,
    textTransform: "uppercase",
    paddingTop: 4,
    paddingBottom: 4,
    fontFamily: "HelveticaNeueBold",
    color: "#595959",
  },
  amount: {
    color: "#189dd8",
    fontSize: 24,
    fontFamily: "HelveticaNeueBold",
  },
  currency: {
    color: "#189dd8",
    fontSize: 16,
    fontFamily: "HelveticaNeue",
  },
  kwh: {
    color: "#189dd8",
    fontSize: 14,
    fontFamily: "HelveticaNeueBold",
  },
});
