import React from "react";
import { View, StyleSheet, Image, Text, Font } from "@react-pdf/renderer";
// import moment from "moment";
import { Header1 } from "./Header1";
// import hashtag from "./icons/PROPOSAL_NUMBER.png";
import address from "./icons/PROJECT_ADDRESS.png";
import totalImg from "./icons/PROJECT_TOTAL.png";
import desc from "./icons/PROJECT_DESCRIPTION.png";
import serviceDate from "./icons/SERVICE_DATE.png";
import { Footer } from "./Footer";

Font.register({
  family: "HelveticaNeue",
  src: "/fonts/HelveticaNeue-Light.otf",
});

Font.register({
  family: "HelveticaNeueBold",
  src: "/fonts/HelveticaNeue-Heavy.otf",
});

export default function Overview({
  project,
  total,
  location,
  serviceDates,
  currency,
}) {
  return (
    <View style={styles.container}>
      <Header1 />
      <View
        style={{
          height: 200,
          width: "100%",
          position: "relative",
          display: "flex",
          backgroundSize: "cover",
        }}
      >
        <Image
          style={{
            height: 200,
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
          }}
          src={"/bg.png"}
          height="200px"
          cache={true}
        />
        <Text
          style={{
            margin: "auto",
            color: "#ffffff",
            fontSize: 64,
            fontFamily: "HelveticaNeue",
          }}
        >
          PROJECT REPORT
        </Text>
      </View>

      <View
        style={{
          ...styles.row,
          flex: 1,
          borderBottomWidth: 1,
          borderBottomColor: "#595959",
          borderBottomStyle: "solid",
        }}
      >
        <View
          style={{
            ...styles.col,
            flex: 1,
            padding: 16,
            borderRightWidth: 1,
            borderRightColor: "#595959",
            borderRightStyle: "solid",
          }}
        >
          <Text style={styles.header}>THE CHALLENGE</Text>
          <Text style={styles.text}>
            All modern buildings worldwide are not operating at peak efficiency
            and are unhealthy because their coils have fouled up with dirt,
            grime and microbial growths from their day to day operations. This
            fouling reduces the air flow and heat transfer of the coils, which
            in turn greatly inhibits the overall efficiency of the building's
            HVAC system. This increases (in many environments to a very high
            degree) energy consumption costs and carbon emissions. It also
            reduces the useful life of the HVAC system and the buildings overall
            indoor air quality. The increase in energy consumption can be quite
            significant, especially when looking at the operating life span of a
            buildings HVAC system. For example, just a relatively small increase
            in air flow resistance can add an additional $2 million in added
            energy consumption costs*
          </Text>
          <Text style={styles.smallText}>
            {location?.company?.country === "australia"
              ? `*Assumes a 15 year operating total with an average $0.15 per kWh, 93,000 square metre building with a maintained increase of 62 Pa delta P across the coil where the design delta P for the coil is 124 Pa. Calculations are computed with ASHRAE's energy calculator.`
              : `*Assumes a 15 year operating total with an average $0.15 per Kwh, 1 million square foot building with a maintained increase of .25" In-Wg delta P across the coil where the design delta P for the coil is ,50" In-Wg. Calculations uses ASHRAE's energy calculator.`}
          </Text>

          <Text style={{ ...styles.header, marginTop: 32 }}>THE SOLUTION</Text>
          <Text style={styles.text}>
            Blue Box solves the coil cleanability problem and eliminates most of
            the operational challenges faced by these buildings. Blue Box's
            patented coil cleaning process makes it possible to penetrate
            enzymes through any depth of coil. These enzymes are injected into
            the coils in a foam phase and as they migrate through the coil, both
            the enzymes and the foam quickly breakdown all the dirt, grime, and
            biolm deposits that have formed deep within the coils. When the
            cleaning process is completed, air flow and heat transfer of the
            coils are fully restored. This greatly improves the overall energy
            efficiency and useful life of a buildings HVAC system while also
            greatly improving a buildings indoor air quality.
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: "auto",
            }}
          >
            {[
              "Coil Disinfection",
              "Coil Restoration",
              "Energy Management",
              "Improved IAQ",
              "CO2 Reduction",
            ].map((txt, i) => (
              <View
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  fontFamily: "HelveticaNeue",
                }}
              >
                <Image
                  src={`/icons/coil-${i + 1}.png`}
                  style={{ width: 32 }}
                  cache={true}
                />
                <Text
                  style={{
                    fontSize: 8,
                    width: 75,
                    textAlign: "center",
                    marginTop: 4,
                    color: "#189dd8",
                  }}
                >
                  {txt}
                </Text>
              </View>
            ))}
          </View>
        </View>

        <View style={{ ...styles.col, width: 160, padding: 16 }}>
          {/* <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={hashtag} style={{ width: 32 }} />
            <Text style={styles.stats}>Proposal Number</Text>
            <Text style={{ color: "#333", fontSize: 10 }}>
              {proposalNumber}
            </Text>
          </View> */}
          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: 32,
            }}
          >
            <Image source={desc} style={{ width: 32 }} />
            <Text style={styles.stats}>Project Description</Text>
            <Text
              style={{
                color: "#595959",
                fontSize: 10,
                marginBottom: 16,
                lineHeight: 1.2,
              }}
            >
              {project?.description}
            </Text>
          </View>

          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              marginVertical: 8,
            }}
          >
            <Image source={address} style={{ width: 32 }} />
            <Text style={styles.stats}>Project Address</Text>
            <Text
              style={{
                color: "#595959",
                fontSize: 10,
                marginBottom: 16,
                textTransform: "capitalize",
              }}
            >
              {`${location?.address?.replace(" | ", ",")}, ${location?.city}, ${
                location?.state
              }`}
            </Text>
          </View>

          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              marginVertical: 8,
            }}
          >
            <Image source={serviceDate} style={{ width: 32 }} />
            <Text style={styles.stats}>Service Date(s)</Text>
            <Text style={{ color: "#595959", fontSize: 10, marginBottom: 16 }}>
              {serviceDates}
            </Text>
          </View>

          <View
            style={{
              ...styles.col,
              alignItems: "center",
              justifyContent: "flex-start",
              margin: 8,
            }}
          >
            <Image source={totalImg} style={{ width: 32 }} />
            <Text style={styles.stats}>Project Total</Text>
            <Text style={{ color: "#595959", fontSize: 10, marginBottom: 16 }}>
              <Text style={{ fontSize: 8 }}>{`${currency} `}</Text>
              {total}
            </Text>
          </View>
        </View>
      </View>

      <Footer />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    display: "flex",
  },
  col: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    color: "#189dd8",
    fontSize: 24,
    marginTop: 16,
    fontFamily: "HelveticaNeue",
  },
  text: {
    color: "#595959",
    fontSize: 10.5,
    lineHeight: 1.4,
    fontFamily: "HelveticaNeue",
  },
  smallText: {
    color: "#595959",
    fontSize: 8,
    paddingTop: 4,
    lineHeight: 1.2,
    fontFamily: "HelveticaNeue",
  },
  stats: {
    fontSize: 10,
    textTransform: "uppercase",
    marginTop: 8,
    marginBottom: 4,
    fontFamily: "HelveticaNeueBold",
    color: "#595959",
  },
});
