import React, { useContext, useState } from "react";
import { UserSettingsContext } from "../../contexts";

export default function Login() {
  const [error, setError] = useState(null);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "400px",
          flexDirection: "column",
          backgroundColor: "#fff",
          marginTop: 32,
          padding: 24,
          borderRadius: 8,
        }}
      >
        <div style={{ display: "flex", flex: 1 }}>
          <img
            src="/bba-logo.jpg"
            alt="Blue Box Air"
            width="120px"
            height="118px"
            style={{ width: "120px", height: "118px" }}
          />
          <h1
            style={{
              margin: "auto",
              textTransform: "uppercase",
              fontSize: "24px",
            }}
          >
            User Login
          </h1>
        </div>
        <div style={{ display: "flex", flex: 1, margin: "16px 0" }}>
          <LoginForm />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            margin: "4px 0",
            backgroundColor: "#eee",
            borderRadius: "4px",
          }}
        >
          {error?.message && (
            <p style={{ fontSize: "12px", color: "red", padding: "4px" }}>
              {error.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
const format = (str) =>
  str?.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

function LoginForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const userSettings = useContext(UserSettingsContext);

  const authHandler = (e) => {
    e.preventDefault();
    setError(null);
    fetch("https://blueboxserver.herokuapp.com/login", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then((response) => response.json())
      .then(async (user) => {
        if (user && user?.error) {
          setError(user?.error);
        } else {
          await userSettings.updateCookie({
            ...userSettings,
            auth: user,
          });

          await localStorage.setItem("accessToken", user?.accessToken);

          if (user?.accessToken) {
            setTimeout(() => {
              window.location = "/";
            }, 2000);
          }
        }
      });
  };

  return (
    <form
      method="post"
      onSubmit={authHandler}
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          margin: "4px 0",
        }}
      >
        <label>Email</label>
        <input
          name="username"
          type="text"
          value={username}
          style={{ height: "32px" }}
          onChange={({ target }) => setUsername(target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          margin: "4px 0",
        }}
      >
        <label>Password</label>
        <input
          name="password"
          type="password"
          value={password || ""}
          style={{ height: "32px" }}
          onChange={({ target }) => setPassword(target.value)}
        />
      </div>

      <button style={{ margin: "4px 0" }} onClick={authHandler}>
        Sign in
      </button>

      {error && (
        <div style={{ fontSize: "12px", color: "red", padding: "4px" }}>
          {error}
        </div>
      )}
    </form>
  );
}
