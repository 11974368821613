import { gql } from '@apollo/client';

const COMPANIES = gql`
  query GetCompanies($skip: Int!, $limit: Int!, $where: CompanyFilter!, $orderBy: Sort) {
    companiesBy(skip: $skip, limit: $limit, where: $where, orderBy: $orderBy) {
      pagination {
        total
        skip
        limit
      }
      companies {
        id
      company_name
      city
      state
      website
      category
      updated_at
      contacts {
        id
      }
      locations {
        id
        location_name
        equipments {
          id
        }
        projects {
          id
          is_opportunity
          proposals {
            id
          }
        }
      }
      created_at
      created_by {
        id
        user_name
        first_name
        last_name
      }
      country
      }
    }
  }
`;

export const AlL_USERS = gql`
  query AllUsers {
    users {
      id
      first_name
      last_name
      user_name
    }
  }
`;

export const COMPANY_USERS = gql`
  query CompanyUsers {
    companies {
      created_by{
        id
        user_name
        first_name
        last_name
      }
    }
  }
`;

export default COMPANIES;
